/* eslint-disable */
// @ts-nocheck

import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  ExpectedError: { input: any; output: any; }
  GlobalID: { input: any; output: any; }
  JSON: { input: any; output: any; }
  UUID: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type AccountPartialInput = {
  address?: InputMaybe<AddressInput>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

/** Accreditation(id, uuid, name, code, created_at, updated_at) */
export type AccreditationFilter = {
  AND?: InputMaybe<AccreditationFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<AccreditationFilter>;
  OR?: InputMaybe<AccreditationFilter>;
  code?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** Accreditation(id, uuid, name, code, created_at, updated_at) */
export type AccreditationInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type AccreditationOrder = {
  code?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Accreditation(id, uuid, name, code, created_at, updated_at) */
export type AccreditationPartialInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Accreditation(id, uuid, name, code, created_at, updated_at) */
export type AccreditationType = Node & {
  __typename?: 'AccreditationType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type AccreditationTypeConnection = {
  __typename?: 'AccreditationTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<AccreditationTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type AccreditationTypeEdge = {
  __typename?: 'AccreditationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: AccreditationType;
};

export type AddressInput = {
  country?: InputMaybe<Scalars['String']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  latitude?: InputMaybe<Scalars['Float']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  raw: Scalars['String']['input'];
  route?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  stateCode?: InputMaybe<Scalars['String']['input']>;
  streetNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Address(id, street_number, route, locality, raw, formatted, latitude, longitude) */
export type AddressType = {
  __typename?: 'AddressType';
  formatted: Scalars['String']['output'];
  id: Scalars['GlobalID']['output'];
  latitude?: Maybe<Scalars['Float']['output']>;
  locality?: Maybe<LocalityType>;
  longitude?: Maybe<Scalars['Float']['output']>;
  raw: Scalars['String']['output'];
  route: Scalars['String']['output'];
  streetNumber: Scalars['String']['output'];
};

/** AdviceTemplate(id, uuid, created_at, updated_at, deleted_at, name, content) */
export type AdviceTemplateFilter = {
  AND?: InputMaybe<AdviceTemplateFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<AdviceTemplateFilter>;
  OR?: InputMaybe<AdviceTemplateFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** AdviceTemplate(id, uuid, created_at, updated_at, deleted_at, name, content) */
export type AdviceTemplateInput = {
  content: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
};

export type AdviceTemplateOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** AdviceTemplate(id, uuid, created_at, updated_at, deleted_at, name, content) */
export type AdviceTemplatePartialInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** AdviceTemplate(id, uuid, created_at, updated_at, deleted_at, name, content) */
export type AdviceTemplateType = BaseInterface & Node & {
  __typename?: 'AdviceTemplateType';
  content: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type AdviceTemplateTypeConnection = {
  __typename?: 'AdviceTemplateTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<AdviceTemplateTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type AdviceTemplateTypeEdge = {
  __typename?: 'AdviceTemplateTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: AdviceTemplateType;
};

export enum Airway {
  NonEffective = 'NON_EFFECTIVE',
  Obstructed = 'OBSTRUCTED',
  Patent = 'PATENT'
}

export type AppPermissions = {
  __typename?: 'AppPermissions';
  app: Scalars['String']['output'];
  groups: Array<PermissionGroup>;
  label: Scalars['String']['output'];
};

export enum Appearance {
  Anxious = 'ANXIOUS',
  Calm = 'CALM',
  Distressed = 'DISTRESSED',
  Exhausted = 'EXHAUSTED'
}

export enum AssignmentStatus {
  Assigned = 'ASSIGNED',
  Pending = 'PENDING',
  Unsuccessful = 'UNSUCCESSFUL'
}

export enum AxType {
  Ecg = 'ECG',
  Gcs = 'GCS',
  Mental = 'MENTAL',
  Neurovascular = 'NEUROVASCULAR',
  Other = 'OTHER',
  Pain = 'PAIN',
  Primary = 'PRIMARY',
  Respiratory = 'RESPIRATORY',
  Rosier = 'ROSIER',
  Spinal = 'SPINAL',
  Vitals = 'VITALS'
}

export type BaseInterface = {
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BaseReorderableInterface = {
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  order: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export type BoolBaseFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Boolean']['input']>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum Breathing {
  Abnormal = 'ABNORMAL',
  NotBreathing = 'NOT_BREATHING',
  Spontaneous = 'SPONTANEOUS'
}

export enum CapRefill {
  Absent = 'ABSENT',
  Delayed = 'DELAYED',
  Normal = 'NORMAL'
}

/** CaseAttachment(id, case, attachment, description) */
export type CaseAttachmentInput = {
  attachment: Scalars['Upload']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

/** CaseAttachment(id, case, attachment, description) */
export type CaseAttachmentPartialInput = {
  attachment?: InputMaybe<Scalars['Upload']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** CaseAttachment(id, case, attachment, description) */
export type CaseAttachmentType = Node & {
  __typename?: 'CaseAttachmentType';
  attachment: DjangoFileType;
  description: Scalars['String']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
};

/** CaseAttendant(id, case, user, role) */
export type CaseAttendantInput = {
  role?: InputMaybe<CaseAttendantRole>;
  user: NodeInput;
};

/** CaseAttendant(id, case, user, role) */
export type CaseAttendantPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  role?: InputMaybe<CaseAttendantRole>;
  user?: InputMaybe<NodeInput>;
};

export enum CaseAttendantRole {
  Administration = 'ADMINISTRATION',
  Assistant = 'ASSISTANT',
  ClinicalSupport = 'CLINICAL_SUPPORT',
  Commander = 'COMMANDER',
  CoAttendant = 'CO_ATTENDANT',
  Observer = 'OBSERVER',
  Other = 'OTHER',
  Supervisor = 'SUPERVISOR',
  Transport = 'TRANSPORT',
  Triage = 'TRIAGE'
}

/** CaseAttendant(id, case, user, role) */
export type CaseAttendantType = Node & {
  __typename?: 'CaseAttendantType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  role: CaseAttendantRole;
  user: UserType;
};

/** CaseAudit(id, uuid, created_at, updated_at, deleted_at, case, user, notes, outcome) */
export type CaseAuditInput = {
  case: NodeInput;
  notes?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<CaseAuditOutcome>;
};

export type CaseAuditOrder = {
  createdAt?: InputMaybe<Ordering>;
  outcome?: InputMaybe<Ordering>;
};

export enum CaseAuditOutcome {
  Approved = 'APPROVED',
  Returned = 'RETURNED'
}

/** CaseAudit(id, uuid, created_at, updated_at, deleted_at, case, user, notes, outcome) */
export type CaseAuditType = BaseInterface & Node & {
  __typename?: 'CaseAuditType';
  case: CaseType;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  outcome: CaseAuditOutcome;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

/** CaseAx(id, case, attendant, time, ax_type, ax_primary, ax_vitals, ax_respiratory, ax_neurovascular, ax_gcs, ax_ecg, ax_rosier, ax_pain, ax_spinal, ax_mental, ax_custom) */
export type CaseAxInput = {
  attendant: NodeInput;
  axCustom?: InputMaybe<CaseCustomAxInput>;
  axEcg?: InputMaybe<CaseEcgAxInput>;
  axGcs?: InputMaybe<CaseGcsAxInput>;
  axMental?: InputMaybe<CaseMentalAxInput>;
  axNeurovascular?: InputMaybe<CaseNeurovascularAxInput>;
  axPain?: InputMaybe<CasePainAxInput>;
  axPrimary?: InputMaybe<CasePrimaryAxInput>;
  axRespiratory?: InputMaybe<CaseRespiratoryAxInput>;
  axRosier?: InputMaybe<CaseRosierAxInput>;
  axSpinal?: InputMaybe<CaseSpinalAxInput>;
  axType: AxType;
  axVitals?: InputMaybe<CaseVitalsAxInput>;
  time: Scalars['DateTime']['input'];
};

/** CaseAx(id, case, attendant, time, ax_type, ax_primary, ax_vitals, ax_respiratory, ax_neurovascular, ax_gcs, ax_ecg, ax_rosier, ax_pain, ax_spinal, ax_mental, ax_custom) */
export type CaseAxPartialInput = {
  attendant?: InputMaybe<NodeInput>;
  axCustom?: InputMaybe<CaseCustomAxPartialInput>;
  axEcg?: InputMaybe<CaseEcgAxPartialInput>;
  axGcs?: InputMaybe<CaseGcsAxPartialInput>;
  axMental?: InputMaybe<CaseMentalAxPartialInput>;
  axNeurovascular?: InputMaybe<CaseNeurovascularAxPartialInput>;
  axPain?: InputMaybe<CasePainAxPartialInput>;
  axPrimary?: InputMaybe<CasePrimaryAxPartialInput>;
  axRespiratory?: InputMaybe<CaseRespiratoryAxPartialInput>;
  axRosier?: InputMaybe<CaseRosierAxPartialInput>;
  axSpinal?: InputMaybe<CaseSpinalAxPartialInput>;
  axType?: InputMaybe<AxType>;
  axVitals?: InputMaybe<CaseVitalsAxPartialInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
};

/** CaseAx(id, case, attendant, time, ax_type, ax_primary, ax_vitals, ax_respiratory, ax_neurovascular, ax_gcs, ax_ecg, ax_rosier, ax_pain, ax_spinal, ax_mental, ax_custom) */
export type CaseAxType = Node & {
  __typename?: 'CaseAxType';
  attendant: Node;
  axCustom?: Maybe<CaseCustomAxType>;
  axEcg?: Maybe<CaseEcgAxType>;
  axGcs?: Maybe<CaseGcsAxType>;
  axMental?: Maybe<CaseMentalAxType>;
  axNeurovascular?: Maybe<CaseNeurovascularAxType>;
  axPain?: Maybe<CasePainAxType>;
  axPrimary?: Maybe<CasePrimaryAxType>;
  axRespiratory?: Maybe<CaseRespiratoryAxType>;
  axRosier?: Maybe<CaseRosierAxType>;
  axSpinal?: Maybe<CaseSpinalAxType>;
  axType: AxType;
  axVitals?: Maybe<CaseVitalsAxType>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  time: Scalars['DateTime']['output'];
};

/** CaseCategory(id, uuid, created_at, updated_at, deleted_at, name, priority) */
export type CaseCategoryFilter = {
  AND?: InputMaybe<CaseCategoryFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<CaseCategoryFilter>;
  OR?: InputMaybe<CaseCategoryFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  priority?: InputMaybe<CasePriorityFilterLookup>;
};

/** CaseCategory(id, uuid, created_at, updated_at, deleted_at, name, priority) */
export type CaseCategoryInput = {
  name: Scalars['String']['input'];
  priority?: InputMaybe<CasePriority>;
};

export type CaseCategoryOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  priority?: InputMaybe<Ordering>;
};

/** CaseCategory(id, uuid, created_at, updated_at, deleted_at, name, priority) */
export type CaseCategoryPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<CasePriority>;
};

/** CaseCategorySet(id, uuid, created_at, updated_at, deleted_at, name, triage_enabled) */
export type CaseCategorySetFilter = {
  AND?: InputMaybe<CaseCategorySetFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<CaseCategorySetFilter>;
  OR?: InputMaybe<CaseCategorySetFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  triageEnabled?: InputMaybe<BoolBaseFilterLookup>;
};

/** CaseCategorySet(id, uuid, created_at, updated_at, deleted_at, name, triage_enabled) */
export type CaseCategorySetInput = {
  categories?: InputMaybe<NodeInputPartialListInput>;
  name: Scalars['String']['input'];
  triageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaseCategorySetOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** CaseCategorySet(id, uuid, created_at, updated_at, deleted_at, name, triage_enabled) */
export type CaseCategorySetPartialInput = {
  categories?: InputMaybe<NodeInputPartialListInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  triageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};

/** CaseCategorySet(id, uuid, created_at, updated_at, deleted_at, name, triage_enabled) */
export type CaseCategorySetType = BaseInterface & Node & {
  __typename?: 'CaseCategorySetType';
  categories: Array<CaseCategoryType>;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  triageEnabled: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** CaseCategorySet(id, uuid, created_at, updated_at, deleted_at, name, triage_enabled) */
export type CaseCategorySetTypeCategoriesArgs = {
  filters?: InputMaybe<CaseCategoryFilter>;
  order?: InputMaybe<CaseCategoryOrder>;
};

/** A connection to a list of items. */
export type CaseCategorySetTypeConnection = {
  __typename?: 'CaseCategorySetTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<CaseCategorySetTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type CaseCategorySetTypeEdge = {
  __typename?: 'CaseCategorySetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: CaseCategorySetType;
};

/** CaseCategory(id, uuid, created_at, updated_at, deleted_at, name, priority) */
export type CaseCategoryType = BaseInterface & Node & {
  __typename?: 'CaseCategoryType';
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  priority: CasePriority;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type CaseCategoryTypeConnection = {
  __typename?: 'CaseCategoryTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<CaseCategoryTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type CaseCategoryTypeEdge = {
  __typename?: 'CaseCategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: CaseCategoryType;
};

/** CaseConsumable(id, case, consumable, resource, quantity) */
export type CaseConsumableInput = {
  consumable: NodeInput;
  quantity: Scalars['Int']['input'];
  resource?: InputMaybe<NodeInput>;
};

/** CaseConsumable(id, case, consumable, resource, quantity) */
export type CaseConsumablePartialInput = {
  consumable?: InputMaybe<NodeInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  resource?: InputMaybe<NodeInput>;
};

/** CaseConsumable(id, case, consumable, resource, quantity) */
export type CaseConsumableType = Node & {
  __typename?: 'CaseConsumableType';
  consumable: ConsumableType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  quantity: Scalars['Int']['output'];
  resource?: Maybe<ResourceType>;
  totalPrice: Scalars['Decimal']['output'];
};

/** CaseCustomAx(id, description) */
export type CaseCustomAxInput = {
  description: Scalars['String']['input'];
};

/** CaseCustomAx(id, description) */
export type CaseCustomAxPartialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** CaseCustomAx(id, description) */
export type CaseCustomAxType = Node & {
  __typename?: 'CaseCustomAxType';
  description: Scalars['String']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
};

/** CaseECGAx(id, regularity, rate, rhythm, photo) */
export type CaseEcgAxInput = {
  photo?: InputMaybe<Scalars['Upload']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  regularity?: InputMaybe<PulseRegularity>;
  rhythm?: InputMaybe<Scalars['String']['input']>;
};

/** CaseECGAx(id, regularity, rate, rhythm, photo) */
export type CaseEcgAxPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  photo?: InputMaybe<Scalars['Upload']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  regularity?: InputMaybe<PulseRegularity>;
  rhythm?: InputMaybe<Scalars['String']['input']>;
};

/** CaseECGAx(id, regularity, rate, rhythm, photo) */
export type CaseEcgAxType = Node & {
  __typename?: 'CaseECGAxType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  photo?: Maybe<DjangoFileType>;
  rate?: Maybe<Scalars['String']['output']>;
  regularity?: Maybe<PulseRegularity>;
  rhythm?: Maybe<Scalars['String']['output']>;
};

/** Case(id, uuid, created_at, updated_at, deleted_at, category, patient, date_time_commenced, date_time_concluded, legacy_id, case_id, event, field_case, attendant, linked_case, chief_complaint, case_narrative, advice_given, dx, outcome, summary, signature, archived, audited, pcr) */
export type CaseFilter = {
  AND?: InputMaybe<CaseFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<CaseFilter>;
  OR?: InputMaybe<CaseFilter>;
  archived?: InputMaybe<BoolBaseFilterLookup>;
  attendant?: InputMaybe<UserFilter>;
  audited?: InputMaybe<BoolBaseFilterLookup>;
  caseId?: InputMaybe<StrFilterLookup>;
  category?: InputMaybe<CaseCategoryFilter>;
  event?: InputMaybe<EventFilter>;
  fieldCase?: InputMaybe<BoolBaseFilterLookup>;
  id?: InputMaybe<GlobalIdFilterLookup>;
};

/** CaseGCSAx(id, eye, verbal, motor, total) */
export type CaseGcsAxInput = {
  eye?: InputMaybe<GcsEyeResponse>;
  motor?: InputMaybe<GcsMotorResponse>;
  total: Scalars['Int']['input'];
  verbal?: InputMaybe<GcsVerbalResponse>;
};

/** CaseGCSAx(id, eye, verbal, motor, total) */
export type CaseGcsAxPartialInput = {
  eye?: InputMaybe<GcsEyeResponse>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  motor?: InputMaybe<GcsMotorResponse>;
  total?: InputMaybe<Scalars['Int']['input']>;
  verbal?: InputMaybe<GcsVerbalResponse>;
};

/** CaseGCSAx(id, eye, verbal, motor, total) */
export type CaseGcsAxType = Node & {
  __typename?: 'CaseGCSAxType';
  eye?: Maybe<GcsEyeResponse>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  motor?: Maybe<GcsMotorResponse>;
  total: Scalars['Int']['output'];
  verbal?: Maybe<GcsVerbalResponse>;
};

/** Case(id, uuid, created_at, updated_at, deleted_at, category, patient, date_time_commenced, date_time_concluded, legacy_id, case_id, event, field_case, attendant, linked_case, chief_complaint, case_narrative, advice_given, dx, outcome, summary, signature, archived, audited, pcr) */
export type CaseInput = {
  adviceGiven?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  attachments: Array<CaseAttachmentInput>;
  attendant: NodeInput;
  caseNarrative?: InputMaybe<Scalars['String']['input']>;
  category: NodeInput;
  chiefComplaint?: InputMaybe<Scalars['String']['input']>;
  coAttendants: Array<CaseAttendantInput>;
  consumables: Array<CaseConsumableInput>;
  dateTimeCommenced?: InputMaybe<Scalars['DateTime']['input']>;
  dateTimeConcluded?: InputMaybe<Scalars['DateTime']['input']>;
  dx?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<NodeInput>;
  fieldCase?: InputMaybe<Scalars['Boolean']['input']>;
  outcome?: InputMaybe<Scalars['String']['input']>;
  patient: PatientInput;
  secondarySurveys: Array<CaseAxInput>;
  signature?: InputMaybe<Scalars['String']['input']>;
  sketches: Array<CaseSketchInput>;
  treatments: Array<CaseTreatmentInput>;
};

/** CaseMentalAx(id, behaviour, appearance, speech, eyes, motor_activity, affect, mood, memory, hallucinations, delusions, suicidality, homicidality) */
export type CaseMentalAxInput = {
  affect: MhAffect;
  appearance: MhAppearance;
  behaviour: MhBehaviour;
  delusions: MhDelusions;
  eyes: MhEyes;
  hallucinations: MhHallucinations;
  homicidality: MhHomicidality;
  memory: MhMemory;
  mood: MhMood;
  motorActivity: MhMotorActivity;
  speech: MhSpeech;
  suicidality: MhSuicidality;
};

/** CaseMentalAx(id, behaviour, appearance, speech, eyes, motor_activity, affect, mood, memory, hallucinations, delusions, suicidality, homicidality) */
export type CaseMentalAxPartialInput = {
  affect?: InputMaybe<MhAffect>;
  appearance?: InputMaybe<MhAppearance>;
  behaviour?: InputMaybe<MhBehaviour>;
  delusions?: InputMaybe<MhDelusions>;
  eyes?: InputMaybe<MhEyes>;
  hallucinations?: InputMaybe<MhHallucinations>;
  homicidality?: InputMaybe<MhHomicidality>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  memory?: InputMaybe<MhMemory>;
  mood?: InputMaybe<MhMood>;
  motorActivity?: InputMaybe<MhMotorActivity>;
  speech?: InputMaybe<MhSpeech>;
  suicidality?: InputMaybe<MhSuicidality>;
};

/** CaseMentalAx(id, behaviour, appearance, speech, eyes, motor_activity, affect, mood, memory, hallucinations, delusions, suicidality, homicidality) */
export type CaseMentalAxType = Node & {
  __typename?: 'CaseMentalAxType';
  affect: MhAffect;
  appearance: MhAppearance;
  behaviour: MhBehaviour;
  delusions: MhDelusions;
  eyes: MhEyes;
  hallucinations: MhHallucinations;
  homicidality: MhHomicidality;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  memory: MhMemory;
  mood: MhMood;
  motorActivity: MhMotorActivity;
  speech: MhSpeech;
  suicidality: MhSuicidality;
};

/** CaseNeurovascularAx(id, limb, peripheral_pulse, extremity_temp, sensation, cap_refill, movement) */
export type CaseNeurovascularAxInput = {
  capRefill: CapRefill;
  extremityTemp: ExtremityTemp;
  limb: Scalars['String']['input'];
  movement: Movement;
  peripheralPulse: PeripheralPulse;
  sensation: Sensation;
};

/** CaseNeurovascularAx(id, limb, peripheral_pulse, extremity_temp, sensation, cap_refill, movement) */
export type CaseNeurovascularAxPartialInput = {
  capRefill?: InputMaybe<CapRefill>;
  extremityTemp?: InputMaybe<ExtremityTemp>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  limb?: InputMaybe<Scalars['String']['input']>;
  movement?: InputMaybe<Movement>;
  peripheralPulse?: InputMaybe<PeripheralPulse>;
  sensation?: InputMaybe<Sensation>;
};

/** CaseNeurovascularAx(id, limb, peripheral_pulse, extremity_temp, sensation, cap_refill, movement) */
export type CaseNeurovascularAxType = Node & {
  __typename?: 'CaseNeurovascularAxType';
  capRefill: CapRefill;
  extremityTemp: ExtremityTemp;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  limb: Scalars['String']['output'];
  movement: Movement;
  peripheralPulse: PeripheralPulse;
  sensation: Sensation;
};

export type CaseOrder = {
  archived?: InputMaybe<Ordering>;
  audited?: InputMaybe<Ordering>;
  caseId?: InputMaybe<Ordering>;
  category?: InputMaybe<CaseCategoryOrder>;
  createdAt?: InputMaybe<Ordering>;
  dateTimeCommenced?: InputMaybe<Ordering>;
  dateTimeConcluded?: InputMaybe<Ordering>;
};

/** CasePainAx(id, onset, provocation, quality, radiation, severity, time) */
export type CasePainAxInput = {
  onset?: InputMaybe<Scalars['String']['input']>;
  provocation?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['String']['input']>;
  radiation?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

/** CasePainAx(id, onset, provocation, quality, radiation, severity, time) */
export type CasePainAxPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  onset?: InputMaybe<Scalars['String']['input']>;
  provocation?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<Scalars['String']['input']>;
  radiation?: InputMaybe<Scalars['String']['input']>;
  severity?: InputMaybe<Scalars['Int']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

/** CasePainAx(id, onset, provocation, quality, radiation, severity, time) */
export type CasePainAxType = Node & {
  __typename?: 'CasePainAxType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  onset?: Maybe<Scalars['String']['output']>;
  provocation?: Maybe<Scalars['String']['output']>;
  quality?: Maybe<Scalars['String']['output']>;
  radiation?: Maybe<Scalars['String']['output']>;
  severity?: Maybe<Scalars['Int']['output']>;
  time?: Maybe<Scalars['String']['output']>;
};

/** Case(id, uuid, created_at, updated_at, deleted_at, category, patient, date_time_commenced, date_time_concluded, legacy_id, case_id, event, field_case, attendant, linked_case, chief_complaint, case_narrative, advice_given, dx, outcome, summary, signature, archived, audited, pcr) */
export type CasePartialInput = {
  adviceGiven?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  attachments?: InputMaybe<Array<CaseAttachmentPartialInput>>;
  attendant?: InputMaybe<NodeInput>;
  caseNarrative?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<NodeInput>;
  chiefComplaint?: InputMaybe<Scalars['String']['input']>;
  coAttendants?: InputMaybe<Array<CaseAttendantPartialInput>>;
  consumables?: InputMaybe<Array<CaseConsumablePartialInput>>;
  dateTimeCommenced?: InputMaybe<Scalars['DateTime']['input']>;
  dateTimeConcluded?: InputMaybe<Scalars['DateTime']['input']>;
  dx?: InputMaybe<Scalars['String']['input']>;
  event?: InputMaybe<NodeInput>;
  fieldCase?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  outcome?: InputMaybe<Scalars['String']['input']>;
  patient?: InputMaybe<PatientPartialInput>;
  secondarySurveys?: InputMaybe<Array<CaseAxPartialInput>>;
  signature?: InputMaybe<Scalars['String']['input']>;
  sketches?: InputMaybe<Array<CaseSketchPartialInput>>;
  treatments?: InputMaybe<Array<CaseTreatmentPartialInput>>;
};

/** CasePatientPMHxAllergy(id, patient, allergen, reaction) */
export type CasePatientPmHxAllergyInput = {
  allergen: Scalars['String']['input'];
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  reaction?: InputMaybe<Scalars['String']['input']>;
};

/** CasePatientPMHxAllergy(id, patient, allergen, reaction) */
export type CasePatientPmHxAllergyPartialInput = {
  allergen?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  reaction?: InputMaybe<Scalars['String']['input']>;
};

/** CasePatientPMHxAllergy(id, patient, allergen, reaction) */
export type CasePatientPmHxAllergyType = Node & {
  __typename?: 'CasePatientPMHxAllergyType';
  allergen: Scalars['String']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  reaction?: Maybe<Scalars['String']['output']>;
};

/** CasePatientPMHxHistory(id, patient, name) */
export type CasePatientPmHxHistoryInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name: Scalars['String']['input'];
};

/** CasePatientPMHxHistory(id, patient, name) */
export type CasePatientPmHxHistoryPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** CasePatientPMHxHistory(id, patient, name) */
export type CasePatientPmHxHistoryType = Node & {
  __typename?: 'CasePatientPMHxHistoryType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
};

/** CasePatientPMHxMedication(id, patient, linked_drug, name) */
export type CasePatientPmHxMedicationInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  linkedDrug?: InputMaybe<NodeInput>;
  name: Scalars['String']['input'];
};

/** CasePatientPMHxMedication(id, patient, linked_drug, name) */
export type CasePatientPmHxMedicationPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  linkedDrug?: InputMaybe<NodeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** CasePatientPMHxMedication(id, patient, linked_drug, name) */
export type CasePatientPmHxMedicationType = Node & {
  __typename?: 'CasePatientPMHxMedicationType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  linkedDrug?: Maybe<DrugType>;
  name: Scalars['String']['output'];
};

/** CasePrimaryAx(id, dangers, listed_dangers, response, send_for_help, airway, breathing, circulation, defibrillation, haemorrhage) */
export type CasePrimaryAxInput = {
  airway?: InputMaybe<Airway>;
  breathing?: InputMaybe<Breathing>;
  circulation?: InputMaybe<Circulation>;
  dangers?: InputMaybe<Dangers>;
  defibrillation?: InputMaybe<Defibrillation>;
  haemorrhage?: InputMaybe<Haemorrhage>;
  listedDangers?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Response>;
  sendForHelp?: InputMaybe<SendForHelp>;
};

/** CasePrimaryAx(id, dangers, listed_dangers, response, send_for_help, airway, breathing, circulation, defibrillation, haemorrhage) */
export type CasePrimaryAxPartialInput = {
  airway?: InputMaybe<Airway>;
  breathing?: InputMaybe<Breathing>;
  circulation?: InputMaybe<Circulation>;
  dangers?: InputMaybe<Dangers>;
  defibrillation?: InputMaybe<Defibrillation>;
  haemorrhage?: InputMaybe<Haemorrhage>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  listedDangers?: InputMaybe<Scalars['String']['input']>;
  response?: InputMaybe<Response>;
  sendForHelp?: InputMaybe<SendForHelp>;
};

/** CasePrimaryAx(id, dangers, listed_dangers, response, send_for_help, airway, breathing, circulation, defibrillation, haemorrhage) */
export type CasePrimaryAxType = Node & {
  __typename?: 'CasePrimaryAxType';
  airway: Airway;
  breathing: Breathing;
  circulation: Circulation;
  dangers: Dangers;
  defibrillation: Defibrillation;
  haemorrhage: Haemorrhage;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  listedDangers?: Maybe<Scalars['String']['output']>;
  response: Response;
  sendForHelp: SendForHelp;
};

export enum CasePriority {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  QuickCase = 'QUICK_CASE'
}

export type CasePriorityFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<CasePriority>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<CasePriority>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<CasePriority>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<CasePriority>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<CasePriority>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<CasePriority>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<CasePriority>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<CasePriority>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<CasePriority>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<CasePriority>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<CasePriority>;
};

/** CaseROSIERAx(id, loss_of_consciousness, seizure_activity, asymmetric_facial_weakness, asymmetric_arm_weakness, asymmetric_leg_weakness, speech_disturbance, visual_field_defect, score) */
export type CaseRosierAxInput = {
  asymmetricArmWeakness: Scalars['Boolean']['input'];
  asymmetricFacialWeakness: Scalars['Boolean']['input'];
  asymmetricLegWeakness: Scalars['Boolean']['input'];
  lossOfConsciousness: Scalars['Boolean']['input'];
  score: Scalars['Int']['input'];
  seizureActivity: Scalars['Boolean']['input'];
  speechDisturbance: Scalars['Boolean']['input'];
  visualFieldDefect: Scalars['Boolean']['input'];
};

/** CaseROSIERAx(id, loss_of_consciousness, seizure_activity, asymmetric_facial_weakness, asymmetric_arm_weakness, asymmetric_leg_weakness, speech_disturbance, visual_field_defect, score) */
export type CaseRosierAxPartialInput = {
  asymmetricArmWeakness?: InputMaybe<Scalars['Boolean']['input']>;
  asymmetricFacialWeakness?: InputMaybe<Scalars['Boolean']['input']>;
  asymmetricLegWeakness?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  lossOfConsciousness?: InputMaybe<Scalars['Boolean']['input']>;
  score?: InputMaybe<Scalars['Int']['input']>;
  seizureActivity?: InputMaybe<Scalars['Boolean']['input']>;
  speechDisturbance?: InputMaybe<Scalars['Boolean']['input']>;
  visualFieldDefect?: InputMaybe<Scalars['Boolean']['input']>;
};

/** CaseROSIERAx(id, loss_of_consciousness, seizure_activity, asymmetric_facial_weakness, asymmetric_arm_weakness, asymmetric_leg_weakness, speech_disturbance, visual_field_defect, score) */
export type CaseRosierAxType = Node & {
  __typename?: 'CaseROSIERAxType';
  asymmetricArmWeakness: Scalars['Boolean']['output'];
  asymmetricFacialWeakness: Scalars['Boolean']['output'];
  asymmetricLegWeakness: Scalars['Boolean']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  lossOfConsciousness: Scalars['Boolean']['output'];
  score: Scalars['Int']['output'];
  seizureActivity: Scalars['Boolean']['output'];
  speechDisturbance: Scalars['Boolean']['output'];
  visualFieldDefect: Scalars['Boolean']['output'];
};

/** CaseRespiratoryAx(id, appearance, respiratory_rate, rhythm, skin_colour, conscious_state, speech, auscultation) */
export type CaseRespiratoryAxInput = {
  appearance: Appearance;
  auscultation?: InputMaybe<Scalars['String']['input']>;
  consciousState: Response;
  respiratoryRate: RespiratoryRate;
  rhythm: Rhythm;
  skinColour: SkinColour;
  speech: Speech;
};

/** CaseRespiratoryAx(id, appearance, respiratory_rate, rhythm, skin_colour, conscious_state, speech, auscultation) */
export type CaseRespiratoryAxPartialInput = {
  appearance?: InputMaybe<Appearance>;
  auscultation?: InputMaybe<Scalars['String']['input']>;
  consciousState?: InputMaybe<Response>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  respiratoryRate?: InputMaybe<RespiratoryRate>;
  rhythm?: InputMaybe<Rhythm>;
  skinColour?: InputMaybe<SkinColour>;
  speech?: InputMaybe<Speech>;
};

/** CaseRespiratoryAx(id, appearance, respiratory_rate, rhythm, skin_colour, conscious_state, speech, auscultation) */
export type CaseRespiratoryAxType = Node & {
  __typename?: 'CaseRespiratoryAxType';
  appearance: Appearance;
  auscultation?: Maybe<Scalars['String']['output']>;
  consciousState: Response;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  respiratoryRate: RespiratoryRate;
  rhythm: Rhythm;
  skinColour: SkinColour;
  speech: Speech;
};

/** CaseRxAirway(id, airway_type, oropharanyngeal_airway_size, nasopharanyngeal_airway_size, supraglottic_airway_size, endotracheal_tube_size, effectiveness) */
export type CaseRxAirwayInput = {
  airwayType: RxAirwayType;
  effectiveness: RxEffectiveness;
  endotrachealTubeSize?: InputMaybe<Scalars['String']['input']>;
  nasopharanyngealAirwaySize?: InputMaybe<Scalars['String']['input']>;
  oropharanyngealAirwaySize?: InputMaybe<Scalars['String']['input']>;
  supraglotticAirwaySize?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxAirway(id, airway_type, oropharanyngeal_airway_size, nasopharanyngeal_airway_size, supraglottic_airway_size, endotracheal_tube_size, effectiveness) */
export type CaseRxAirwayPartialInput = {
  airwayType?: InputMaybe<RxAirwayType>;
  effectiveness?: InputMaybe<RxEffectiveness>;
  endotrachealTubeSize?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  nasopharanyngealAirwaySize?: InputMaybe<Scalars['String']['input']>;
  oropharanyngealAirwaySize?: InputMaybe<Scalars['String']['input']>;
  supraglotticAirwaySize?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxAirway(id, airway_type, oropharanyngeal_airway_size, nasopharanyngeal_airway_size, supraglottic_airway_size, endotracheal_tube_size, effectiveness) */
export type CaseRxAirwayType = Node & {
  __typename?: 'CaseRxAirwayType';
  airwayType: RxAirwayType;
  effectiveness: RxEffectiveness;
  endotrachealTubeSize?: Maybe<Scalars['String']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  nasopharanyngealAirwaySize?: Maybe<Scalars['String']['output']>;
  oropharanyngealAirwaySize?: Maybe<Scalars['String']['output']>;
  supraglotticAirwaySize?: Maybe<Scalars['String']['output']>;
};

/** CaseRxBreathingVentilation(id, breathing_ventilation_type, rate, peep, tidal_volume, peak_pressure, location, effectiveness) */
export type CaseRxBreathingVentilationInput = {
  breathingVentilationType: RxBreathingVentilationType;
  effectiveness: RxEffectiveness;
  location?: InputMaybe<Scalars['String']['input']>;
  peakPressure?: InputMaybe<Scalars['String']['input']>;
  peep?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  tidalVolume?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxBreathingVentilation(id, breathing_ventilation_type, rate, peep, tidal_volume, peak_pressure, location, effectiveness) */
export type CaseRxBreathingVentilationPartialInput = {
  breathingVentilationType?: InputMaybe<RxBreathingVentilationType>;
  effectiveness?: InputMaybe<RxEffectiveness>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  peakPressure?: InputMaybe<Scalars['String']['input']>;
  peep?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['String']['input']>;
  tidalVolume?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxBreathingVentilation(id, breathing_ventilation_type, rate, peep, tidal_volume, peak_pressure, location, effectiveness) */
export type CaseRxBreathingVentilationType = Node & {
  __typename?: 'CaseRxBreathingVentilationType';
  breathingVentilationType: RxBreathingVentilationType;
  effectiveness: RxEffectiveness;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  peakPressure?: Maybe<Scalars['String']['output']>;
  peep?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['String']['output']>;
  tidalVolume?: Maybe<Scalars['String']['output']>;
};

/** CaseRxCPR(id, cpr_type, cpr_operation, effectiveness) */
export type CaseRxCprInput = {
  cprOperation: RxCprOperation;
  cprType: RxCprType;
  effectiveness: RxEffectiveness;
};

/** CaseRxCPR(id, cpr_type, cpr_operation, effectiveness) */
export type CaseRxCprPartialInput = {
  cprOperation?: InputMaybe<RxCprOperation>;
  cprType?: InputMaybe<RxCprType>;
  effectiveness?: InputMaybe<RxEffectiveness>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** CaseRxCPR(id, cpr_type, cpr_operation, effectiveness) */
export type CaseRxCprType = Node & {
  __typename?: 'CaseRxCPRType';
  cprOperation: RxCprOperation;
  cprType: RxCprType;
  effectiveness: RxEffectiveness;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
};

/** CaseRxConsult(id, consult_type, consult_method, user, name, notes) */
export type CaseRxConsultInput = {
  consultMethod: RxConsultMethod;
  consultType: RxConsultType;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<NodeInput>;
};

/** CaseRxConsult(id, consult_type, consult_method, user, name, notes) */
export type CaseRxConsultPartialInput = {
  consultMethod?: InputMaybe<RxConsultMethod>;
  consultType?: InputMaybe<RxConsultType>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<NodeInput>;
};

/** CaseRxConsult(id, consult_type, consult_method, user, name, notes) */
export type CaseRxConsultType = Node & {
  __typename?: 'CaseRxConsultType';
  consultMethod: RxConsultMethod;
  consultType: RxConsultType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  user?: Maybe<UserType>;
};

/** CaseRxDefibrillation(id, defibrillation_type, defibrillation_pads, joules, effectiveness) */
export type CaseRxDefibrillationInput = {
  defibrillationPads: RxDefibrillationPads;
  defibrillationType: RxDefibrillationType;
  effectiveness: RxEffectiveness;
  joules?: InputMaybe<Scalars['Int']['input']>;
};

/** CaseRxDefibrillation(id, defibrillation_type, defibrillation_pads, joules, effectiveness) */
export type CaseRxDefibrillationPartialInput = {
  defibrillationPads?: InputMaybe<RxDefibrillationPads>;
  defibrillationType?: InputMaybe<RxDefibrillationType>;
  effectiveness?: InputMaybe<RxEffectiveness>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  joules?: InputMaybe<Scalars['Int']['input']>;
};

/** CaseRxDefibrillation(id, defibrillation_type, defibrillation_pads, joules, effectiveness) */
export type CaseRxDefibrillationType = Node & {
  __typename?: 'CaseRxDefibrillationType';
  defibrillationPads: RxDefibrillationPads;
  defibrillationType: RxDefibrillationType;
  effectiveness: RxEffectiveness;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  joules?: Maybe<Scalars['Int']['output']>;
};

/** CaseRxFirstAid(id, first_aid_type, location, other, effectiveness) */
export type CaseRxFirstAidInput = {
  effectiveness: RxEffectiveness;
  firstAidType: RxFirstAidType;
  location?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxFirstAid(id, first_aid_type, location, other, effectiveness) */
export type CaseRxFirstAidPartialInput = {
  effectiveness?: InputMaybe<RxEffectiveness>;
  firstAidType?: InputMaybe<RxFirstAidType>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  other?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxFirstAid(id, first_aid_type, location, other, effectiveness) */
export type CaseRxFirstAidType = Node & {
  __typename?: 'CaseRxFirstAidType';
  effectiveness: RxEffectiveness;
  firstAidType: RxFirstAidType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  other?: Maybe<Scalars['String']['output']>;
};

/** CaseRxIVAccess(id, cannula_size, cannula_site, attempts, effectiveness) */
export type CaseRxIvAccessInput = {
  attempts: RxCannulaAttempts;
  cannulaSite: RxCannulaSite;
  cannulaSize: RxCannulaSize;
  effectiveness: RxEffectiveness;
};

/** CaseRxIVAccess(id, cannula_size, cannula_site, attempts, effectiveness) */
export type CaseRxIvAccessPartialInput = {
  attempts?: InputMaybe<RxCannulaAttempts>;
  cannulaSite?: InputMaybe<RxCannulaSite>;
  cannulaSize?: InputMaybe<RxCannulaSize>;
  effectiveness?: InputMaybe<RxEffectiveness>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** CaseRxIVAccess(id, cannula_size, cannula_site, attempts, effectiveness) */
export type CaseRxIvAccessType = Node & {
  __typename?: 'CaseRxIVAccessType';
  attempts: RxCannulaAttempts;
  cannulaSite: RxCannulaSite;
  cannulaSize: RxCannulaSize;
  effectiveness: RxEffectiveness;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
};

/** CaseRxMajorTrauma(id, major_trauma_type, location, effectiveness) */
export type CaseRxMajorTraumaInput = {
  effectiveness: RxEffectiveness;
  location?: InputMaybe<Scalars['String']['input']>;
  majorTraumaType: RxMajorTraumaType;
};

/** CaseRxMajorTrauma(id, major_trauma_type, location, effectiveness) */
export type CaseRxMajorTraumaPartialInput = {
  effectiveness?: InputMaybe<RxEffectiveness>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  majorTraumaType?: InputMaybe<RxMajorTraumaType>;
};

/** CaseRxMajorTrauma(id, major_trauma_type, location, effectiveness) */
export type CaseRxMajorTraumaType = Node & {
  __typename?: 'CaseRxMajorTraumaType';
  effectiveness: RxEffectiveness;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  location?: Maybe<Scalars['String']['output']>;
  majorTraumaType: RxMajorTraumaType;
};

/** CaseRxMedications(id, linked_drug, name, dose, route, effectiveness) */
export type CaseRxMedicationsInput = {
  dose: Scalars['String']['input'];
  effectiveness: RxEffectiveness;
  linkedDrug?: InputMaybe<NodeInput>;
  name: Scalars['String']['input'];
  route: RxMedicationRoute;
};

/** CaseRxMedications(id, linked_drug, name, dose, route, effectiveness) */
export type CaseRxMedicationsPartialInput = {
  dose?: InputMaybe<Scalars['String']['input']>;
  effectiveness?: InputMaybe<RxEffectiveness>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  linkedDrug?: InputMaybe<NodeInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  route?: InputMaybe<RxMedicationRoute>;
};

/** CaseRxMedications(id, linked_drug, name, dose, route, effectiveness) */
export type CaseRxMedicationsType = Node & {
  __typename?: 'CaseRxMedicationsType';
  dose: Scalars['String']['output'];
  effectiveness: RxEffectiveness;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  linkedDrug?: Maybe<DrugType>;
  name: Scalars['String']['output'];
  route: RxMedicationRoute;
};

/** CaseRxOther(id, notes) */
export type CaseRxOtherInput = {
  notes: Scalars['String']['input'];
};

/** CaseRxOther(id, notes) */
export type CaseRxOtherPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** CaseRxOther(id, notes) */
export type CaseRxOtherType = Node & {
  __typename?: 'CaseRxOtherType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  notes: Scalars['String']['output'];
};

/** CaseRxOxygen(id, oxygen_type, flow_rate, effectiveness) */
export type CaseRxOxygenInput = {
  effectiveness: RxEffectiveness;
  flowRate?: InputMaybe<Scalars['String']['input']>;
  oxygenType: RxOxygenType;
};

/** CaseRxOxygen(id, oxygen_type, flow_rate, effectiveness) */
export type CaseRxOxygenPartialInput = {
  effectiveness?: InputMaybe<RxEffectiveness>;
  flowRate?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  oxygenType?: InputMaybe<RxOxygenType>;
};

/** CaseRxOxygen(id, oxygen_type, flow_rate, effectiveness) */
export type CaseRxOxygenType = Node & {
  __typename?: 'CaseRxOxygenType';
  effectiveness: RxEffectiveness;
  flowRate?: Maybe<Scalars['String']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  oxygenType: RxOxygenType;
};

/** CaseSketch(id, uuid, created_at, updated_at, deleted_at, case, attendant, description, image_data) */
export type CaseSketchInput = {
  attendant: NodeInput;
  description?: InputMaybe<Scalars['String']['input']>;
  imageData: Scalars['String']['input'];
};

/** CaseSketch(id, uuid, created_at, updated_at, deleted_at, case, attendant, description, image_data) */
export type CaseSketchPartialInput = {
  attendant?: InputMaybe<NodeInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  imageData?: InputMaybe<Scalars['String']['input']>;
};

/** CaseSketch(id, uuid, created_at, updated_at, deleted_at, case, attendant, description, image_data) */
export type CaseSketchType = BaseInterface & Node & {
  __typename?: 'CaseSketchType';
  attendant: Node;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  imageData: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** CaseSpinalAx(id, pain, paraesthesia, pupils, movement, other) */
export type CaseSpinalAxInput = {
  movement: Movement;
  other?: InputMaybe<Scalars['String']['input']>;
  pain: Array<SpinalLocation>;
  paraesthesia?: InputMaybe<Scalars['String']['input']>;
  pupils: Pupils;
};

/** CaseSpinalAx(id, pain, paraesthesia, pupils, movement, other) */
export type CaseSpinalAxPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  movement?: InputMaybe<Movement>;
  other?: InputMaybe<Scalars['String']['input']>;
  pain: Array<SpinalLocation>;
  paraesthesia?: InputMaybe<Scalars['String']['input']>;
  pupils?: InputMaybe<Pupils>;
};

/** CaseSpinalAx(id, pain, paraesthesia, pupils, movement, other) */
export type CaseSpinalAxType = Node & {
  __typename?: 'CaseSpinalAxType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  movement: Movement;
  other?: Maybe<Scalars['String']['output']>;
  pain: Array<SpinalLocation>;
  paraesthesia?: Maybe<Scalars['String']['output']>;
  pupils: Pupils;
};

/** CaseTreatment(id, case, attendant, time, rx_type, rx_airway, rx_breathing_ventilation, rx_cpr, rx_defibrillation, rx_first_aid, rx_major_trauma, rx_oxygen, rx_iv_access, rx_medications, rx_consult, rx_other) */
export type CaseTreatmentInput = {
  attendant: NodeInput;
  rxAirway?: InputMaybe<CaseRxAirwayInput>;
  rxBreathingVentilation?: InputMaybe<CaseRxBreathingVentilationInput>;
  rxConsult?: InputMaybe<CaseRxConsultInput>;
  rxCpr?: InputMaybe<CaseRxCprInput>;
  rxDefibrillation?: InputMaybe<CaseRxDefibrillationInput>;
  rxFirstAid?: InputMaybe<CaseRxFirstAidInput>;
  rxIvAccess?: InputMaybe<CaseRxIvAccessInput>;
  rxMajorTrauma?: InputMaybe<CaseRxMajorTraumaInput>;
  rxMedications?: InputMaybe<CaseRxMedicationsInput>;
  rxOther?: InputMaybe<CaseRxOtherInput>;
  rxOxygen?: InputMaybe<CaseRxOxygenInput>;
  rxType: RxType;
  time: Scalars['DateTime']['input'];
};

/** CaseTreatment(id, case, attendant, time, rx_type, rx_airway, rx_breathing_ventilation, rx_cpr, rx_defibrillation, rx_first_aid, rx_major_trauma, rx_oxygen, rx_iv_access, rx_medications, rx_consult, rx_other) */
export type CaseTreatmentPartialInput = {
  attendant?: InputMaybe<NodeInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  rxAirway?: InputMaybe<CaseRxAirwayPartialInput>;
  rxBreathingVentilation?: InputMaybe<CaseRxBreathingVentilationPartialInput>;
  rxConsult?: InputMaybe<CaseRxConsultPartialInput>;
  rxCpr?: InputMaybe<CaseRxCprPartialInput>;
  rxDefibrillation?: InputMaybe<CaseRxDefibrillationPartialInput>;
  rxFirstAid?: InputMaybe<CaseRxFirstAidPartialInput>;
  rxIvAccess?: InputMaybe<CaseRxIvAccessPartialInput>;
  rxMajorTrauma?: InputMaybe<CaseRxMajorTraumaPartialInput>;
  rxMedications?: InputMaybe<CaseRxMedicationsPartialInput>;
  rxOther?: InputMaybe<CaseRxOtherPartialInput>;
  rxOxygen?: InputMaybe<CaseRxOxygenPartialInput>;
  rxType?: InputMaybe<RxType>;
  time?: InputMaybe<Scalars['DateTime']['input']>;
};

/** CaseTreatment(id, case, attendant, time, rx_type, rx_airway, rx_breathing_ventilation, rx_cpr, rx_defibrillation, rx_first_aid, rx_major_trauma, rx_oxygen, rx_iv_access, rx_medications, rx_consult, rx_other) */
export type CaseTreatmentType = Node & {
  __typename?: 'CaseTreatmentType';
  attendant: Node;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  rxAirway?: Maybe<CaseRxAirwayType>;
  rxBreathingVentilation?: Maybe<CaseRxBreathingVentilationType>;
  rxConsult?: Maybe<CaseRxConsultType>;
  rxCpr?: Maybe<CaseRxCprType>;
  rxDefibrillation?: Maybe<CaseRxDefibrillationType>;
  rxFirstAid?: Maybe<CaseRxFirstAidType>;
  rxIvAccess?: Maybe<CaseRxIvAccessType>;
  rxMajorTrauma?: Maybe<CaseRxMajorTraumaType>;
  rxMedications?: Maybe<CaseRxMedicationsType>;
  rxOther?: Maybe<CaseRxOtherType>;
  rxOxygen?: Maybe<CaseRxOxygenType>;
  rxType: RxType;
  time: Scalars['DateTime']['output'];
};

/** Case(id, uuid, created_at, updated_at, deleted_at, category, patient, date_time_commenced, date_time_concluded, legacy_id, case_id, event, field_case, attendant, linked_case, chief_complaint, case_narrative, advice_given, dx, outcome, summary, signature, archived, audited, pcr) */
export type CaseType = BaseInterface & Node & {
  __typename?: 'CaseType';
  adviceGiven?: Maybe<Scalars['String']['output']>;
  archived: Scalars['Boolean']['output'];
  attachments: Array<CaseAttachmentType>;
  attendant: UserType;
  audited: Scalars['Boolean']['output'];
  audits: Array<CaseAuditType>;
  caseId?: Maybe<Scalars['String']['output']>;
  caseNarrative: Scalars['String']['output'];
  category: CaseCategoryType;
  chiefComplaint: Scalars['String']['output'];
  coAttendants: Array<CaseAttendantType>;
  consumables: Array<CaseConsumableType>;
  createdAt: Scalars['DateTime']['output'];
  dateTimeCommenced?: Maybe<Scalars['DateTime']['output']>;
  dateTimeConcluded?: Maybe<Scalars['DateTime']['output']>;
  dx?: Maybe<Scalars['String']['output']>;
  event?: Maybe<EventType>;
  fieldCase: Scalars['Boolean']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  linkedCase?: Maybe<CaseType>;
  outcome?: Maybe<Scalars['String']['output']>;
  patient: PatientType;
  pcr?: Maybe<DjangoFileType>;
  secondarySurveys: Array<CaseAxType>;
  signature?: Maybe<Scalars['String']['output']>;
  sketches: Array<CaseSketchType>;
  summary?: Maybe<Scalars['String']['output']>;
  totalConsumableCost: Scalars['Decimal']['output'];
  treatments: Array<CaseTreatmentType>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Case(id, uuid, created_at, updated_at, deleted_at, category, patient, date_time_commenced, date_time_concluded, legacy_id, case_id, event, field_case, attendant, linked_case, chief_complaint, case_narrative, advice_given, dx, outcome, summary, signature, archived, audited, pcr) */
export type CaseTypeAuditsArgs = {
  order?: InputMaybe<CaseAuditOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

/** A connection to a list of items. */
export type CaseTypeConnection = {
  __typename?: 'CaseTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<CaseTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type CaseTypeEdge = {
  __typename?: 'CaseTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: CaseType;
};

/** CaseVitalsAx(id, pulse_regularity, pulse_rate, pulse_location, respiratory_regularity, respiratory_rate, blood_pressure, avpu, sp_o2, temperature, bsl, pupils_left, pupils_right, cap_refill, et_co2, ketones) */
export type CaseVitalsAxInput = {
  avpu?: InputMaybe<Response>;
  bloodPressure?: InputMaybe<Scalars['String']['input']>;
  bsl?: InputMaybe<Scalars['Decimal']['input']>;
  capRefill?: InputMaybe<Scalars['Int']['input']>;
  etCo2?: InputMaybe<Scalars['Int']['input']>;
  ketones?: InputMaybe<Scalars['Decimal']['input']>;
  pulseLocation?: InputMaybe<PulseLocation>;
  pulseRate?: InputMaybe<Scalars['Int']['input']>;
  pulseRegularity?: InputMaybe<PulseRegularity>;
  pupilsLeft?: InputMaybe<Scalars['String']['input']>;
  pupilsRight?: InputMaybe<Scalars['String']['input']>;
  respiratoryRate?: InputMaybe<Scalars['Int']['input']>;
  respiratoryRegularity?: InputMaybe<RespiratoryRegularity>;
  spO2?: InputMaybe<Scalars['Int']['input']>;
  temperature?: InputMaybe<Scalars['Decimal']['input']>;
};

/** CaseVitalsAx(id, pulse_regularity, pulse_rate, pulse_location, respiratory_regularity, respiratory_rate, blood_pressure, avpu, sp_o2, temperature, bsl, pupils_left, pupils_right, cap_refill, et_co2, ketones) */
export type CaseVitalsAxPartialInput = {
  avpu?: InputMaybe<Response>;
  bloodPressure?: InputMaybe<Scalars['String']['input']>;
  bsl?: InputMaybe<Scalars['Decimal']['input']>;
  capRefill?: InputMaybe<Scalars['Int']['input']>;
  etCo2?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  ketones?: InputMaybe<Scalars['Decimal']['input']>;
  pulseLocation?: InputMaybe<PulseLocation>;
  pulseRate?: InputMaybe<Scalars['Int']['input']>;
  pulseRegularity?: InputMaybe<PulseRegularity>;
  pupilsLeft?: InputMaybe<Scalars['String']['input']>;
  pupilsRight?: InputMaybe<Scalars['String']['input']>;
  respiratoryRate?: InputMaybe<Scalars['Int']['input']>;
  respiratoryRegularity?: InputMaybe<RespiratoryRegularity>;
  spO2?: InputMaybe<Scalars['Int']['input']>;
  temperature?: InputMaybe<Scalars['Decimal']['input']>;
};

/** CaseVitalsAx(id, pulse_regularity, pulse_rate, pulse_location, respiratory_regularity, respiratory_rate, blood_pressure, avpu, sp_o2, temperature, bsl, pupils_left, pupils_right, cap_refill, et_co2, ketones) */
export type CaseVitalsAxType = Node & {
  __typename?: 'CaseVitalsAxType';
  avpu?: Maybe<Response>;
  bloodPressure?: Maybe<Scalars['String']['output']>;
  bsl?: Maybe<Scalars['Decimal']['output']>;
  capRefill?: Maybe<Scalars['Int']['output']>;
  etCo2?: Maybe<Scalars['Int']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  ketones?: Maybe<Scalars['Decimal']['output']>;
  pulseLocation?: Maybe<PulseLocation>;
  pulseRate?: Maybe<Scalars['Int']['output']>;
  pulseRegularity?: Maybe<PulseRegularity>;
  pupilsLeft?: Maybe<Scalars['String']['output']>;
  pupilsRight?: Maybe<Scalars['String']['output']>;
  respiratoryRate?: Maybe<Scalars['Int']['output']>;
  respiratoryRegularity?: Maybe<RespiratoryRegularity>;
  spO2?: Maybe<Scalars['Int']['output']>;
  temperature?: Maybe<Scalars['Decimal']['output']>;
};

export enum Circulation {
  AbnormalPulse = 'ABNORMAL_PULSE',
  NoPulse = 'NO_PULSE',
  StrongPulse = 'STRONG_PULSE'
}

/** Client(id, uuid, created_at, updated_at, deleted_at, name, address) */
export type ClientFilter = {
  AND?: InputMaybe<ClientFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ClientFilter>;
  OR?: InputMaybe<ClientFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** Client(id, uuid, created_at, updated_at, deleted_at, name, address) */
export type ClientInput = {
  address?: InputMaybe<AddressInput>;
  contacts: Array<ContactInput>;
  name: Scalars['String']['input'];
};

export type ClientOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Client(id, uuid, created_at, updated_at, deleted_at, name, address) */
export type ClientPartialInput = {
  address?: InputMaybe<AddressInput>;
  contacts?: InputMaybe<Array<ContactPartialInput>>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Client(id, uuid, created_at, updated_at, deleted_at, name, address) */
export type ClientType = BaseInterface & Node & {
  __typename?: 'ClientType';
  address?: Maybe<AddressType>;
  contacts: Array<ContactType>;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type ClientTypeConnection = {
  __typename?: 'ClientTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ClientTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ClientTypeEdge = {
  __typename?: 'ClientTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ClientType;
};

/** Consumable(id, uuid, created_at, updated_at, deleted_at, name, price_per_unit) */
export type ConsumableFilter = {
  AND?: InputMaybe<ConsumableFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ConsumableFilter>;
  OR?: InputMaybe<ConsumableFilter>;
  containedIn?: InputMaybe<NodeInput>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  pricePerUnit?: InputMaybe<DecimalComparisonFilterLookup>;
};

/** Consumable(id, uuid, created_at, updated_at, deleted_at, name, price_per_unit) */
export type ConsumableInput = {
  containedIn?: InputMaybe<NodeInputPartialListInput>;
  name: Scalars['String']['input'];
  pricePerUnit: Scalars['Decimal']['input'];
};

export type ConsumableOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  pricePerUnit?: InputMaybe<Ordering>;
};

/** Consumable(id, uuid, created_at, updated_at, deleted_at, name, price_per_unit) */
export type ConsumablePartialInput = {
  containedIn?: InputMaybe<NodeInputPartialListInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pricePerUnit?: InputMaybe<Scalars['Decimal']['input']>;
};

/** Consumable(id, uuid, created_at, updated_at, deleted_at, name, price_per_unit) */
export type ConsumableType = BaseInterface & Node & {
  __typename?: 'ConsumableType';
  containedIn: Array<ResourceType>;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  pricePerUnit: Scalars['Decimal']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Consumable(id, uuid, created_at, updated_at, deleted_at, name, price_per_unit) */
export type ConsumableTypeContainedInArgs = {
  filters?: InputMaybe<ResourceFilter>;
  order?: InputMaybe<ResourceOrder>;
};

/** A connection to a list of items. */
export type ConsumableTypeConnection = {
  __typename?: 'ConsumableTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ConsumableTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ConsumableTypeEdge = {
  __typename?: 'ConsumableTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ConsumableType;
};

/** Contact(id, uuid, created_at, updated_at, deleted_at, first_name, last_name, position, email, phone, primary, client) */
export type ContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Contact(id, uuid, created_at, updated_at, deleted_at, first_name, last_name, position, email, phone, primary, client) */
export type ContactPartialInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  primary?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Contact(id, uuid, created_at, updated_at, deleted_at, first_name, last_name, position, email, phone, primary, client) */
export type ContactType = BaseInterface & Node & {
  __typename?: 'ContactType';
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  position?: Maybe<Scalars['String']['output']>;
  primary: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** Country(id, name, code) */
export type CountryType = {
  __typename?: 'CountryType';
  code: Scalars['String']['output'];
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
};

export type CreateAccreditationPayload = AccreditationType | OperationInfo;

export type CreateAdviceTemplatePayload = AdviceTemplateType | OperationInfo;

export type CreateCaseAuditPayload = CaseAuditType | OperationInfo;

export type CreateCaseCategoryPayload = CaseCategoryType | OperationInfo;

export type CreateCaseCategorySetPayload = CaseCategorySetType | OperationInfo;

export type CreateCasePayload = CaseType | OperationInfo;

export type CreateClientPayload = ClientType | OperationInfo;

export type CreateConsumablePayload = ConsumableType | OperationInfo;

export type CreateDeleteQualificationAuditPayload = OperationInfo | QualificationDocumentAuditType;

export type CreateDrugPayload = DrugType | OperationInfo;

export type CreateEventKindPayload = EventKindType | OperationInfo;

export type CreateEventPayload = EventType | OperationInfo;

export type CreateFileNodePayload = FileNodeType | OperationInfo;

export type CreateHospitalPayload = HospitalType | OperationInfo;

export type CreateMyTimeSheetPayload = OperationInfo | TimeSheetType;

export type CreateQualificationDocumentPayload = OperationInfo | QualificationDocumentType;

export type CreateQualificationPayload = OperationInfo | QualificationType;

export type CreateRegionPayload = OperationInfo | RegionType;

export type CreateResourceCategoryPayload = OperationInfo | ResourceCategoryType;

export type CreateResourcePayload = OperationInfo | ResourceType;

export type CreateSkillsetPayload = OperationInfo | SkillsetType;

export type CreateTextTemplatePayload = OperationInfo | TextTemplateType;

export type CreateTimeSheetPayload = OperationInfo | TimeSheetType;

export type CreateUserPayload = OperationInfo | UserType;

export type CreateWithdrawEoiRequestPayload = OperationInfo | ShiftAssignmentWithdrawRequestType;

export enum Dangers {
  No = 'NO',
  Yes = 'YES'
}

export type DatasetsType = {
  __typename?: 'DatasetsType';
  datasets: Array<GraphDataType>;
  labels: Array<Scalars['String']['output']>;
};

export type DatetimeDatetimeFilterLookup = {
  date?: InputMaybe<IntComparisonFilterLookup>;
  day?: InputMaybe<IntComparisonFilterLookup>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['DateTime']['input']>;
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  hour?: InputMaybe<IntComparisonFilterLookup>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  isoWeekDay?: InputMaybe<IntComparisonFilterLookup>;
  isoYear?: InputMaybe<IntComparisonFilterLookup>;
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  minute?: InputMaybe<IntComparisonFilterLookup>;
  month?: InputMaybe<IntComparisonFilterLookup>;
  quarter?: InputMaybe<IntComparisonFilterLookup>;
  /** Inclusive range test (between) */
  range?: InputMaybe<DatetimeRangeLookup>;
  second?: InputMaybe<IntComparisonFilterLookup>;
  time?: InputMaybe<IntComparisonFilterLookup>;
  week?: InputMaybe<IntComparisonFilterLookup>;
  weekDay?: InputMaybe<IntComparisonFilterLookup>;
  year?: InputMaybe<IntComparisonFilterLookup>;
};

export type DatetimeFilterLookup = {
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  exact?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  iContains?: InputMaybe<Scalars['DateTime']['input']>;
  iEndsWith?: InputMaybe<Scalars['DateTime']['input']>;
  iExact?: InputMaybe<Scalars['DateTime']['input']>;
  iRegex?: InputMaybe<Scalars['String']['input']>;
  iStartsWith?: InputMaybe<Scalars['DateTime']['input']>;
  inList?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  range?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  regex?: InputMaybe<Scalars['String']['input']>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DatetimeRangeLookup = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type DecimalComparisonFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Decimal']['input']>;
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  /** Inclusive range test (between) */
  range?: InputMaybe<DecimalRangeLookup>;
};

export type DecimalRangeLookup = {
  end?: InputMaybe<Scalars['Decimal']['input']>;
  start?: InputMaybe<Scalars['Decimal']['input']>;
};

export enum Defibrillation {
  No = 'NO',
  Yes = 'YES'
}

export type DeleteAccreditationPayload = AccreditationType | OperationInfo;

export type DeleteAdviceTemplatePayload = AdviceTemplateType | OperationInfo;

export type DeleteCaseCategoryPayload = CaseCategoryType | OperationInfo;

export type DeleteCaseCategorySetPayload = CaseCategorySetType | OperationInfo;

export type DeleteCasePayload = CaseType | OperationInfo;

export type DeleteClientPayload = ClientType | OperationInfo;

export type DeleteConsumablePayload = ConsumableType | OperationInfo;

export type DeleteDrugPayload = DrugType | OperationInfo;

export type DeleteEventKindPayload = EventKindType | OperationInfo;

export type DeleteEventPayload = EventType | OperationInfo;

export type DeleteFileNodePayload = FileNodeType | OperationInfo;

export type DeleteHospitalPayload = HospitalType | OperationInfo;

export type DeleteQualificationDocumentPayload = OperationInfo | QualificationDocumentType;

export type DeleteQualificationPayload = OperationInfo | QualificationType;

export type DeleteRegionPayload = OperationInfo | RegionType;

export type DeleteResourceCategoryPayload = OperationInfo | ResourceCategoryType;

export type DeleteResourcePayload = OperationInfo | ResourceType;

export type DeleteSkillsetPayload = OperationInfo | SkillsetType;

export type DeleteTextTemplatePayload = OperationInfo | TextTemplateType;

export type DeleteTimeSheetPayload = OperationInfo | TimeSheetType;

export type DeleteUserPayload = OperationInfo | UserType;

export type DjangoFileType = {
  __typename?: 'DjangoFileType';
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  size: Scalars['Int']['output'];
  url: Scalars['String']['output'];
};

/** DrugBrandName(id, parent, name) */
export type DrugBrandNamePartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** DrugBrandName(id, parent, name) */
export type DrugBrandNameType = Node & {
  __typename?: 'DrugBrandNameType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
};

/** DrugContraindication(id, parent, drug, contraindication) */
export type DrugContraindicationPartialInput = {
  contraindication?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** DrugContraindication(id, parent, drug, contraindication) */
export type DrugContraindicationType = Node & {
  __typename?: 'DrugContraindicationType';
  contraindication: Scalars['String']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
};

/** Drug(id, uuid, created_at, updated_at, deleted_at, name, presentation, pharmacology, metabolism, route_of_administration, special_notes) */
export type DrugFilter = {
  AND?: InputMaybe<DrugFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<DrugFilter>;
  OR?: InputMaybe<DrugFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** DrugIndication(id, parent, indication) */
export type DrugIndicationPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  indication?: InputMaybe<Scalars['String']['input']>;
};

/** DrugIndication(id, parent, indication) */
export type DrugIndicationType = Node & {
  __typename?: 'DrugIndicationType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  indication: Scalars['String']['output'];
};

/** Drug(id, uuid, created_at, updated_at, deleted_at, name, presentation, pharmacology, metabolism, route_of_administration, special_notes) */
export type DrugInput = {
  brandNames?: InputMaybe<Array<DrugBrandNamePartialInput>>;
  contraindications?: InputMaybe<Array<DrugContraindicationPartialInput>>;
  indications?: InputMaybe<Array<DrugIndicationPartialInput>>;
  metabolism?: InputMaybe<Scalars['JSON']['input']>;
  name: Scalars['String']['input'];
  pharmacology?: InputMaybe<Scalars['JSON']['input']>;
  precautions?: InputMaybe<Array<DrugPrecautionPartialInput>>;
  presentation?: InputMaybe<Scalars['String']['input']>;
  routeOfAdministration?: InputMaybe<Scalars['JSON']['input']>;
  sideEffects?: InputMaybe<Array<DrugSideEffectPartialInput>>;
  specialNotes?: InputMaybe<Scalars['JSON']['input']>;
};

export type DrugOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Drug(id, uuid, created_at, updated_at, deleted_at, name, presentation, pharmacology, metabolism, route_of_administration, special_notes) */
export type DrugPartialInput = {
  brandNames?: InputMaybe<Array<DrugBrandNamePartialInput>>;
  contraindications?: InputMaybe<Array<DrugContraindicationPartialInput>>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  indications?: InputMaybe<Array<DrugIndicationPartialInput>>;
  metabolism?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pharmacology?: InputMaybe<Scalars['JSON']['input']>;
  precautions?: InputMaybe<Array<DrugPrecautionPartialInput>>;
  presentation?: InputMaybe<Scalars['String']['input']>;
  routeOfAdministration?: InputMaybe<Scalars['JSON']['input']>;
  sideEffects?: InputMaybe<Array<DrugSideEffectPartialInput>>;
  specialNotes?: InputMaybe<Scalars['JSON']['input']>;
};

/** DrugPrecaution(id, parent, drug, precaution) */
export type DrugPrecautionPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  precaution?: InputMaybe<Scalars['String']['input']>;
};

/** DrugPrecaution(id, parent, drug, precaution) */
export type DrugPrecautionType = Node & {
  __typename?: 'DrugPrecautionType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  precaution: Scalars['String']['output'];
};

/** DrugSideEffect(id, parent, side_effect) */
export type DrugSideEffectPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  sideEffect?: InputMaybe<Scalars['String']['input']>;
};

/** DrugSideEffect(id, parent, side_effect) */
export type DrugSideEffectType = Node & {
  __typename?: 'DrugSideEffectType';
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  sideEffect: Scalars['String']['output'];
};

/** Drug(id, uuid, created_at, updated_at, deleted_at, name, presentation, pharmacology, metabolism, route_of_administration, special_notes) */
export type DrugType = BaseInterface & Node & {
  __typename?: 'DrugType';
  brandNames: Array<DrugBrandNameType>;
  contraindications: Array<DrugContraindicationType>;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  indications: Array<DrugIndicationType>;
  metabolism?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  pharmacology?: Maybe<Scalars['JSON']['output']>;
  precautions: Array<DrugPrecautionType>;
  presentation?: Maybe<Scalars['String']['output']>;
  routeOfAdministration?: Maybe<Scalars['JSON']['output']>;
  sideEffects: Array<DrugSideEffectType>;
  specialNotes?: Maybe<Scalars['JSON']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type DrugTypeConnection = {
  __typename?: 'DrugTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<DrugTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type DrugTypeEdge = {
  __typename?: 'DrugTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: DrugType;
};

export type EventConsumableType = {
  __typename?: 'EventConsumableType';
  consumable: ConsumableType;
  id: Scalars['String']['output'];
  quantity: Scalars['Int']['output'];
  resource: ResourceType;
  totalPrice: Scalars['Decimal']['output'];
};

export type EventConsumablesType = {
  __typename?: 'EventConsumablesType';
  consumables: Array<EventConsumableType>;
  totalPrice: Scalars['Decimal']['output'];
};

/** Event(id, uuid, created_at, updated_at, deleted_at, name, address, status, nearest_hospital, event_kind, client, contact, case_category_set, patrons, duties, staff_briefing, other_equipment, equipment_instructions, client_requests, other_information, post_issues, post_es_attendance, emp_sent, semp_sent, client_confirmation_sent, post_event_report_sent, legacy_id) */
export type EventFilter = {
  AND?: InputMaybe<EventFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<EventFilter>;
  OR?: InputMaybe<EventFilter>;
  endTime?: InputMaybe<DatetimeFilterLookup>;
  eventKind?: InputMaybe<EventKindFilter>;
  futureOnly?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  startTime?: InputMaybe<DatetimeFilterLookup>;
  status?: InputMaybe<EventStatusFilterLookup>;
};

/** Event(id, uuid, created_at, updated_at, deleted_at, name, address, status, nearest_hospital, event_kind, client, contact, case_category_set, patrons, duties, staff_briefing, other_equipment, equipment_instructions, client_requests, other_information, post_issues, post_es_attendance, emp_sent, semp_sent, client_confirmation_sent, post_event_report_sent, legacy_id) */
export type EventInput = {
  address: AddressInput;
  caseCategorySet?: InputMaybe<NodeInput>;
  client?: InputMaybe<NodeInput>;
  clientRequests?: InputMaybe<Scalars['JSON']['input']>;
  contact?: InputMaybe<NodeInput>;
  duties?: InputMaybe<Scalars['JSON']['input']>;
  empSent?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentInstructions?: InputMaybe<Scalars['JSON']['input']>;
  eventKind?: InputMaybe<NodeInput>;
  name: Scalars['String']['input'];
  nearestHospital?: InputMaybe<NodeInput>;
  otherEquipment?: InputMaybe<Scalars['JSON']['input']>;
  otherInformation?: InputMaybe<Scalars['JSON']['input']>;
  patrons?: InputMaybe<Scalars['Int']['input']>;
  resourceAllocations?: InputMaybe<Array<ResourceAllocationInput>>;
  sempSent?: InputMaybe<Scalars['Boolean']['input']>;
  shifts: Array<ShiftInput>;
  staffBriefing?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<EventStatus>;
};

export enum EventInterestStatus {
  /** Interest */
  Interest = 'INTEREST',
  /** No Interest */
  NoInterest = 'NO_INTEREST'
}

export type EventInterestStatusType = {
  __typename?: 'EventInterestStatusType';
  status: EventInterestStatus;
  totalInterests: Scalars['Int']['output'];
};

/** EventKind(id, uuid, created_at, updated_at, deleted_at, name) */
export type EventKindFilter = {
  AND?: InputMaybe<EventKindFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<EventKindFilter>;
  OR?: InputMaybe<EventKindFilter>;
  hasEventsOnly?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** EventKind(id, uuid, created_at, updated_at, deleted_at, name) */
export type EventKindInput = {
  name: Scalars['String']['input'];
};

export type EventKindOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** EventKind(id, uuid, created_at, updated_at, deleted_at, name) */
export type EventKindPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** EventKind(id, uuid, created_at, updated_at, deleted_at, name) */
export type EventKindType = BaseInterface & Node & {
  __typename?: 'EventKindType';
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type EventKindTypeConnection = {
  __typename?: 'EventKindTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<EventKindTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type EventKindTypeEdge = {
  __typename?: 'EventKindTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: EventKindType;
};

export type EventOrder = {
  client?: InputMaybe<ClientOrder>;
  createdAt?: InputMaybe<Ordering>;
  interestStatus?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  scheduleStatus?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
};

/** Event(id, uuid, created_at, updated_at, deleted_at, name, address, status, nearest_hospital, event_kind, client, contact, case_category_set, patrons, duties, staff_briefing, other_equipment, equipment_instructions, client_requests, other_information, post_issues, post_es_attendance, emp_sent, semp_sent, client_confirmation_sent, post_event_report_sent, legacy_id) */
export type EventPartialInput = {
  address: AddressInput;
  caseCategorySet?: InputMaybe<NodeInput>;
  client?: InputMaybe<NodeInput>;
  clientRequests?: InputMaybe<Scalars['JSON']['input']>;
  contact?: InputMaybe<NodeInput>;
  duties?: InputMaybe<Scalars['JSON']['input']>;
  empSent?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentInstructions?: InputMaybe<Scalars['JSON']['input']>;
  eventKind?: InputMaybe<NodeInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nearestHospital?: InputMaybe<NodeInput>;
  otherEquipment?: InputMaybe<Scalars['JSON']['input']>;
  otherInformation?: InputMaybe<Scalars['JSON']['input']>;
  patrons?: InputMaybe<Scalars['Int']['input']>;
  resourceAllocations: Array<ResourceAllocationPartialInput>;
  sempSent?: InputMaybe<Scalars['Boolean']['input']>;
  shifts: Array<ShiftPartialInput>;
  staffBriefing?: InputMaybe<Scalars['JSON']['input']>;
  status?: InputMaybe<EventStatus>;
};

export enum EventScheduleStatus {
  /** Fully Scheduled */
  FullyScheduled = 'FULLY_SCHEDULED',
  /** Not Scheduled */
  NotScheduled = 'NOT_SCHEDULED',
  /** Partially Scheduled */
  PartiallyScheduled = 'PARTIALLY_SCHEDULED'
}

export type EventScheduleStatusType = {
  __typename?: 'EventScheduleStatusType';
  approvedPositions: Scalars['Int']['output'];
  status: EventScheduleStatus;
  totalPositions: Scalars['Int']['output'];
};

export enum EventStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Quoted = 'QUOTED',
  Rejected = 'REJECTED'
}

export type EventStatusFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<EventStatus>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<EventStatus>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<EventStatus>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<EventStatus>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<EventStatus>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<EventStatus>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<EventStatus>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<EventStatus>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<EventStatus>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<EventStatus>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<EventStatus>;
};

/** Event(id, uuid, created_at, updated_at, deleted_at, name, address, status, nearest_hospital, event_kind, client, contact, case_category_set, patrons, duties, staff_briefing, other_equipment, equipment_instructions, client_requests, other_information, post_issues, post_es_attendance, emp_sent, semp_sent, client_confirmation_sent, post_event_report_sent, legacy_id) */
export type EventType = BaseInterface & Node & {
  __typename?: 'EventType';
  acuityDistribution: DatasetsType;
  address: AddressType;
  caseCategorySet?: Maybe<CaseCategorySetType>;
  casesOverTime: DatasetsType;
  client?: Maybe<ClientType>;
  clientConfirmationSent: Scalars['Boolean']['output'];
  clientRequests?: Maybe<Scalars['JSON']['output']>;
  contact?: Maybe<ContactType>;
  createdAt: Scalars['DateTime']['output'];
  duties?: Maybe<Scalars['JSON']['output']>;
  empSent: Scalars['Boolean']['output'];
  endTime?: Maybe<Scalars['DateTime']['output']>;
  equipmentInstructions?: Maybe<Scalars['JSON']['output']>;
  eventConsumables: EventConsumablesType;
  eventKind?: Maybe<EventKindType>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  interestStatus: EventInterestStatusType;
  name: Scalars['String']['output'];
  nearestHospital?: Maybe<HospitalType>;
  otherEquipment?: Maybe<Scalars['JSON']['output']>;
  otherInformation?: Maybe<Scalars['JSON']['output']>;
  patrons: Scalars['Int']['output'];
  postEventReportSent: Scalars['Boolean']['output'];
  resourceAllocations: Array<ResourceAllocationType>;
  scheduleStatus: EventScheduleStatusType;
  sempSent: Scalars['Boolean']['output'];
  shifts: Array<ShiftType>;
  staffBriefing?: Maybe<Scalars['JSON']['output']>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  status: EventStatus;
  topCaseCategories: Array<TopCaseCategoryType>;
  totalCases: Scalars['Int']['output'];
  totalUnauditedCases: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Event(id, uuid, created_at, updated_at, deleted_at, name, address, status, nearest_hospital, event_kind, client, contact, case_category_set, patrons, duties, staff_briefing, other_equipment, equipment_instructions, client_requests, other_information, post_issues, post_es_attendance, emp_sent, semp_sent, client_confirmation_sent, post_event_report_sent, legacy_id) */
export type EventTypeResourceAllocationsArgs = {
  filters?: InputMaybe<ResourceAllocationFilter>;
};


/** Event(id, uuid, created_at, updated_at, deleted_at, name, address, status, nearest_hospital, event_kind, client, contact, case_category_set, patrons, duties, staff_briefing, other_equipment, equipment_instructions, client_requests, other_information, post_issues, post_es_attendance, emp_sent, semp_sent, client_confirmation_sent, post_event_report_sent, legacy_id) */
export type EventTypeShiftsArgs = {
  filters?: InputMaybe<ShiftFilter>;
  order?: InputMaybe<ShiftOrder>;
};

/** A connection to a list of items. */
export type EventTypeConnection = {
  __typename?: 'EventTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<EventTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type EventTypeEdge = {
  __typename?: 'EventTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: EventType;
};

export enum ExtremityTemp {
  Cold = 'COLD',
  Hot = 'HOT',
  Warm = 'WARM'
}

/** FileNode(id, uuid, name, slug, file, file_type, parent, created_at) */
export type FileNodeFilter = {
  AND?: InputMaybe<FileNodeFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<FileNodeFilter>;
  OR?: InputMaybe<FileNodeFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  isRootLevel?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StrFilterLookup>;
  parent?: InputMaybe<NodeInput>;
};

/** FileNode(id, uuid, name, slug, file, file_type, parent, created_at) */
export type FileNodeInput = {
  file?: InputMaybe<Scalars['Upload']['input']>;
  fileType: FileNodeKind;
  name: Scalars['String']['input'];
  parent?: InputMaybe<NodeInput>;
};

export enum FileNodeKind {
  File = 'FILE',
  Folder = 'FOLDER'
}

/** FileNode(id, uuid, name, slug, file, file_type, parent, created_at) */
export type FileNodeType = Node & {
  __typename?: 'FileNodeType';
  children: Array<FileNodeType>;
  createdAt: Scalars['DateTime']['output'];
  file?: Maybe<DjangoFileType>;
  filePath?: Maybe<Scalars['String']['output']>;
  fileType: FileNodeKind;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  parent?: Maybe<FileNodeType>;
  parents: Array<FileNodeType>;
  slug: Scalars['String']['output'];
  uuid: Scalars['UUID']['output'];
};


/** FileNode(id, uuid, name, slug, file, file_type, parent, created_at) */
export type FileNodeTypeChildrenArgs = {
  filters?: InputMaybe<FileNodeFilter>;
};

/** A connection to a list of items. */
export type FileNodeTypeConnection = {
  __typename?: 'FileNodeTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<FileNodeTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type FileNodeTypeEdge = {
  __typename?: 'FileNodeTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: FileNodeType;
};

export enum GcsEyeResponse {
  None = 'NONE',
  Pain = 'PAIN',
  Sound = 'SOUND',
  Spontaneous = 'SPONTANEOUS'
}

export enum GcsMotorResponse {
  AbnormalFlexion = 'ABNORMAL_FLEXION',
  Extension = 'EXTENSION',
  LocalisesPain = 'LOCALISES_PAIN',
  None = 'NONE',
  ObeysCommands = 'OBEYS_COMMANDS',
  WithdrawsPain = 'WITHDRAWS_PAIN'
}

export enum GcsVerbalResponse {
  Confused = 'CONFUSED',
  Inappropriate = 'INAPPROPRIATE',
  Incomprehensible = 'INCOMPREHENSIBLE',
  None = 'NONE',
  Oriented = 'ORIENTED'
}

export type GlobalIdBaseFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GlobalIdFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['GlobalID']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<Scalars['GlobalID']['input']>;
};

export type GraphDataType = {
  __typename?: 'GraphDataType';
  backgroundColor?: Maybe<Array<Scalars['String']['output']>>;
  data: Array<Scalars['Int']['output']>;
  label: Scalars['String']['output'];
};

export enum Haemorrhage {
  No = 'NO',
  Yes = 'YES'
}

/** Hospital(id, uuid, created_at, updated_at, deleted_at, name, facilities, address) */
export type HospitalFilter = {
  AND?: InputMaybe<HospitalFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<HospitalFilter>;
  OR?: InputMaybe<HospitalFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** Hospital(id, uuid, created_at, updated_at, deleted_at, name, facilities, address) */
export type HospitalInput = {
  address: AddressInput;
  facilities?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type HospitalOrder = {
  createdAt?: InputMaybe<Ordering>;
  distance?: InputMaybe<Array<Scalars['Float']['input']>>;
  name?: InputMaybe<Ordering>;
};

/** Hospital(id, uuid, created_at, updated_at, deleted_at, name, facilities, address) */
export type HospitalPartialInput = {
  address: AddressInput;
  facilities?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Hospital(id, uuid, created_at, updated_at, deleted_at, name, facilities, address) */
export type HospitalType = BaseInterface & Node & {
  __typename?: 'HospitalType';
  address?: Maybe<AddressType>;
  createdAt: Scalars['DateTime']['output'];
  distance?: Maybe<Scalars['Float']['output']>;
  facilities?: Maybe<Scalars['String']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Hospital(id, uuid, created_at, updated_at, deleted_at, name, facilities, address) */
export type HospitalTypeDistanceArgs = {
  location?: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** A connection to a list of items. */
export type HospitalTypeConnection = {
  __typename?: 'HospitalTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<HospitalTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type HospitalTypeEdge = {
  __typename?: 'HospitalTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: HospitalType;
};

export type IntComparisonFilterLookup = {
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than. Filter will be skipped on `null` value */
  gt?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to. Filter will be skipped on `null` value */
  gte?: InputMaybe<Scalars['Int']['input']>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than. Filter will be skipped on `null` value */
  lt?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to. Filter will be skipped on `null` value */
  lte?: InputMaybe<Scalars['Int']['input']>;
  /** Inclusive range test (between) */
  range?: InputMaybe<IntRangeLookup>;
};

export type IntRangeLookup = {
  end?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

/** Locality(id, name, postal_code, state) */
export type LocalityType = {
  __typename?: 'LocalityType';
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state?: Maybe<StateType>;
};

export enum MhAffect {
  Constricted = 'CONSTRICTED',
  Flat = 'FLAT',
  Full = 'FULL',
  Labile = 'LABILE'
}

export enum MhAppearance {
  Bizarre = 'BIZARRE',
  Disheveled = 'DISHEVELED',
  Neat = 'NEAT'
}

export enum MhBehaviour {
  Aggressive = 'AGGRESSIVE',
  Agitated = 'AGITATED',
  Cooperative = 'COOPERATIVE',
  Guarded = 'GUARDED',
  Hyperactive = 'HYPERACTIVE',
  Nonsensical = 'NONSENSICAL',
  Paranoid = 'PARANOID',
  Stereotyped = 'STEREOTYPED',
  Withdrawn = 'WITHDRAWN'
}

export enum MhDelusions {
  Grandiose = 'GRANDIOSE',
  Jealous = 'JEALOUS',
  None = 'NONE',
  Paranoid = 'PARANOID',
  Persecutory = 'PERSECUTORY',
  Religious = 'RELIGIOUS',
  Somatic = 'SOMATIC'
}

export enum MhEyes {
  Avoidant = 'AVOIDANT',
  Intense = 'INTENSE',
  Normal = 'NORMAL'
}

export enum MhHallucinations {
  Auditory = 'AUDITORY',
  Gustatory = 'GUSTATORY',
  None = 'NONE',
  Olfactory = 'OLFACTORY',
  Tactile = 'TACTILE',
  Visual = 'VISUAL'
}

export enum MhHomicidality {
  Agression = 'AGRESSION',
  Intent = 'INTENT',
  None = 'NONE',
  Plan = 'PLAN',
  PlanIntent = 'PLAN_INTENT'
}

export enum MhMemory {
  Amnesia = 'AMNESIA',
  Hypermnesia = 'HYPERMNESIA',
  Impaired = 'IMPAIRED',
  Normal = 'NORMAL'
}

export enum MhMood {
  Angry = 'ANGRY',
  Anxious = 'ANXIOUS',
  Apathetic = 'APATHETIC',
  Depressed = 'DEPRESSED',
  Dysphoric = 'DYSPHORIC',
  Elevated = 'ELEVATED',
  Euphoric = 'EUPHORIC',
  Euthymic = 'EUTHYMIC',
  Irritable = 'IRRITABLE'
}

export enum MhMotorActivity {
  Normal = 'NORMAL',
  Restless = 'RESTLESS',
  Slowed = 'SLOWED',
  Tics = 'TICS'
}

export enum MhSpeech {
  Impoverished = 'IMPOVERISHED',
  Normal = 'NORMAL',
  Pressured = 'PRESSURED',
  Tangential = 'TANGENTIAL'
}

export enum MhSuicidality {
  Attempt = 'ATTEMPT',
  Ideation = 'IDEATION',
  Intent = 'INTENT',
  None = 'NONE',
  Plan = 'PLAN',
  PlanIntent = 'PLAN_INTENT'
}

export enum Movement {
  Abnormal = 'ABNORMAL',
  Normal = 'NORMAL',
  Painful = 'PAINFUL'
}

export type Mutation = {
  __typename?: 'Mutation';
  approveEoi: ShiftEoiResponseType;
  approveTimeSheet: Scalars['Boolean']['output'];
  assignShift: ShiftEoiResponseType;
  createAccreditation: CreateAccreditationPayload;
  createAdviceTemplate: CreateAdviceTemplatePayload;
  createCase: CreateCasePayload;
  createCaseAudit: CreateCaseAuditPayload;
  createCaseCategory: CreateCaseCategoryPayload;
  createCaseCategorySet: CreateCaseCategorySetPayload;
  createClient: CreateClientPayload;
  createConsumable: CreateConsumablePayload;
  createDeleteQualificationAudit: CreateDeleteQualificationAuditPayload;
  createDrug: CreateDrugPayload;
  createEvent: CreateEventPayload;
  createEventKind: CreateEventKindPayload;
  createFileNode: CreateFileNodePayload;
  createHospital: CreateHospitalPayload;
  createMyTimeSheet: CreateMyTimeSheetPayload;
  createQualification: CreateQualificationPayload;
  createQualificationDocument: CreateQualificationDocumentPayload;
  createRegion: CreateRegionPayload;
  createResource: CreateResourcePayload;
  createResourceCategory: CreateResourceCategoryPayload;
  createSkillset: CreateSkillsetPayload;
  createTextTemplate: CreateTextTemplatePayload;
  createTimeSheet: CreateTimeSheetPayload;
  createUser: CreateUserPayload;
  createWithdrawEoiRequest: CreateWithdrawEoiRequestPayload;
  deleteAccreditation: DeleteAccreditationPayload;
  deleteAdviceTemplate: DeleteAdviceTemplatePayload;
  deleteCase: DeleteCasePayload;
  deleteCaseCategory: DeleteCaseCategoryPayload;
  deleteCaseCategorySet: DeleteCaseCategorySetPayload;
  deleteClient: DeleteClientPayload;
  deleteConsumable: DeleteConsumablePayload;
  deleteDrug: DeleteDrugPayload;
  deleteEois: ShiftEoiResponseType;
  deleteEvent: DeleteEventPayload;
  deleteEventKind: DeleteEventKindPayload;
  deleteFileNode: DeleteFileNodePayload;
  deleteHospital: DeleteHospitalPayload;
  deleteQualification: DeleteQualificationPayload;
  deleteQualificationDocument: DeleteQualificationDocumentPayload;
  deleteRegion: DeleteRegionPayload;
  deleteResource: DeleteResourcePayload;
  deleteResourceCategory: DeleteResourceCategoryPayload;
  deleteSkillset: DeleteSkillsetPayload;
  deleteTextTemplate: DeleteTextTemplatePayload;
  deleteTimeSheet: DeleteTimeSheetPayload;
  deleteUser: DeleteUserPayload;
  mergeEventKinds: EventKindType;
  readNotifications: NotificationResponseType;
  /**
   * ### refreshToken to generate a new login token:
   *
   *     *Use this only if `JWT_LONG_RUNNING_REFRESH_TOKEN` is True*
   *
   *     using the refresh-token you already got during authorization, and
   *     obtain a brand-new token (and possibly a new refresh token if you
   *     revoked the previous). This is an alternative to log in when your
   *     token expired.
   *
   */
  refreshToken: ObtainJsonWebTokenType;
  registerEoi: ShiftEoiResponseType;
  rejectEoi: ShiftEoiResponseType;
  rejectPendingEois: ShiftEoiResponseType;
  reorder: Array<BaseReorderableInterface>;
  /**
   * Change user password without old password.
   *
   *     Receive the token that was sent by email.
   *
   *     If token and new passwords are valid, update user password and in
   *     case of using refresh tokens, revoke all of them.
   *
   *     Also, if user has not been verified yet, verify it.
   *
   */
  resetPassword: MutationNormalOutput;
  revertEoi: ShiftEoiResponseType;
  /**
   * ### Suspends a refresh token.
   *
   *     *token must exist to be revoked.*
   *
   */
  revokeToken: RevokeRefreshTokenType;
  sendEventEmp: EventType;
  sendPostReport: EventType;
  sendSmsEmail: Scalars['Boolean']['output'];
  sendUserActivation: Scalars['Boolean']['output'];
  sendUserPasswordReset: MutationNormalOutput;
  setEventStatus: EventType;
  /**
   *
   *     Set user password - for password-less registration
   *
   *     Receive the token that was sent by email.
   *
   *     If token and new passwords are valid, set
   *     user password and in case of using refresh
   *     tokens, revoke all of them.
   *
   *     Also, if user has not been verified yet, verify it.
   *
   */
  setPassword: MutationNormalOutput;
  setShiftDutyManager: ShiftEoiResponseType;
  /**
   * Obtain JSON web token for given user.
   *
   *     Allow to perform login with different fields, The fields are defined
   *     on settings.
   *
   *     Not verified users can log in by default. This can be changes on
   *     settings.
   *
   *     If user is archived, make it unarchived and return
   *     `unarchiving=True` on OutputBase.
   *
   */
  tokenAuth: ObtainJsonWebTokenType;
  twoFactorAuth: ObtainJsonWebTokenType;
  twoFactorProvision: Scalars['Boolean']['output'];
  twoFactorReset: Scalars['Boolean']['output'];
  updateAccount: UpdateAccountPayload;
  updateAccreditation: UpdateAccreditationPayload;
  updateAdviceTemplate: UpdateAdviceTemplatePayload;
  updateCase: UpdateCasePayload;
  updateCaseCategory: UpdateCaseCategoryPayload;
  updateCaseCategorySet: UpdateCaseCategorySetPayload;
  updateClient: UpdateClientPayload;
  updateConsumable: UpdateConsumablePayload;
  updateDrug: UpdateDrugPayload;
  updateEvent: UpdateEventPayload;
  updateEventKind: UpdateEventKindPayload;
  updateHospital: UpdateHospitalPayload;
  updateMyTimeSheet: UpdateMyTimeSheetPayload;
  updateQualification: UpdateQualificationPayload;
  updateQualificationDocument: UpdateQualificationDocumentPayload;
  updateRegion: UpdateRegionPayload;
  updateResource: UpdateResourcePayload;
  updateResourceCategory: UpdateResourceCategoryPayload;
  updateSkillset: UpdateSkillsetPayload;
  updateTenant: UpdateTenantPayload;
  updateTextTemplate: UpdateTextTemplatePayload;
  updateTimeSheet: UpdateTimeSheetPayload;
  updateUser: UpdateUserPayload;
  updateWithdrawEoiRequest: UpdateWithdrawEoiRequestPayload;
  /**
   * ### Checks if a token is not expired and correct.
   *
   *     *Note that this is not for refresh tokens.*
   *
   */
  verifyToken: VerifyTokenType;
  withdrawEoi: ShiftEoiResponseType;
};


export type MutationApproveEoiArgs = {
  shiftAssignmentIds: Array<Scalars['GlobalID']['input']>;
  shiftId: Scalars['GlobalID']['input'];
};


export type MutationApproveTimeSheetArgs = {
  pks: Array<Scalars['GlobalID']['input']>;
};


export type MutationAssignShiftArgs = {
  shiftId: Scalars['GlobalID']['input'];
  userId: Scalars['GlobalID']['input'];
};


export type MutationCreateAccreditationArgs = {
  data: AccreditationInput;
};


export type MutationCreateAdviceTemplateArgs = {
  data: AdviceTemplateInput;
};


export type MutationCreateCaseArgs = {
  data: CaseInput;
};


export type MutationCreateCaseAuditArgs = {
  data: CaseAuditInput;
};


export type MutationCreateCaseCategoryArgs = {
  data: CaseCategoryInput;
};


export type MutationCreateCaseCategorySetArgs = {
  data: CaseCategorySetInput;
};


export type MutationCreateClientArgs = {
  data: ClientInput;
};


export type MutationCreateConsumableArgs = {
  data: ConsumableInput;
};


export type MutationCreateDeleteQualificationAuditArgs = {
  data: QualificationDocumentAuditInput;
};


export type MutationCreateDrugArgs = {
  data: DrugInput;
};


export type MutationCreateEventArgs = {
  data: EventInput;
};


export type MutationCreateEventKindArgs = {
  data: EventKindInput;
};


export type MutationCreateFileNodeArgs = {
  data: FileNodeInput;
};


export type MutationCreateHospitalArgs = {
  data: HospitalInput;
};


export type MutationCreateMyTimeSheetArgs = {
  data: MyTimeSheetInput;
};


export type MutationCreateQualificationArgs = {
  data: QualificationInput;
};


export type MutationCreateQualificationDocumentArgs = {
  data: QualificationDocumentInput;
};


export type MutationCreateRegionArgs = {
  data: RegionInput;
};


export type MutationCreateResourceArgs = {
  data: ResourceInput;
};


export type MutationCreateResourceCategoryArgs = {
  data: ResourceCategoryInput;
};


export type MutationCreateSkillsetArgs = {
  data: SkillsetInput;
};


export type MutationCreateTextTemplateArgs = {
  data: TextTemplateInput;
};


export type MutationCreateTimeSheetArgs = {
  data: TimeSheetInput;
};


export type MutationCreateUserArgs = {
  data: UserInput;
};


export type MutationCreateWithdrawEoiRequestArgs = {
  data: ShiftAssignmentWithdrawRequestInput;
};


export type MutationDeleteAccreditationArgs = {
  data: NodeInput;
};


export type MutationDeleteAdviceTemplateArgs = {
  data: NodeInput;
};


export type MutationDeleteCaseArgs = {
  data: NodeInput;
};


export type MutationDeleteCaseCategoryArgs = {
  data: NodeInput;
};


export type MutationDeleteCaseCategorySetArgs = {
  data: NodeInput;
};


export type MutationDeleteClientArgs = {
  data: NodeInput;
};


export type MutationDeleteConsumableArgs = {
  data: NodeInput;
};


export type MutationDeleteDrugArgs = {
  data: NodeInput;
};


export type MutationDeleteEoisArgs = {
  shiftAssignmentIds: Array<Scalars['GlobalID']['input']>;
};


export type MutationDeleteEventArgs = {
  data: NodeInput;
};


export type MutationDeleteEventKindArgs = {
  data: NodeInput;
};


export type MutationDeleteFileNodeArgs = {
  data: NodeInput;
};


export type MutationDeleteHospitalArgs = {
  data: NodeInput;
};


export type MutationDeleteQualificationArgs = {
  data: NodeInput;
};


export type MutationDeleteQualificationDocumentArgs = {
  data: NodeInput;
};


export type MutationDeleteRegionArgs = {
  data: NodeInput;
};


export type MutationDeleteResourceArgs = {
  data: NodeInput;
};


export type MutationDeleteResourceCategoryArgs = {
  data: NodeInput;
};


export type MutationDeleteSkillsetArgs = {
  data: NodeInput;
};


export type MutationDeleteTextTemplateArgs = {
  data: NodeInput;
};


export type MutationDeleteTimeSheetArgs = {
  data: NodeInput;
};


export type MutationDeleteUserArgs = {
  data: NodeInput;
};


export type MutationMergeEventKindsArgs = {
  eventKindIds: Array<Scalars['GlobalID']['input']>;
  name: Scalars['String']['input'];
};


export type MutationReadNotificationsArgs = {
  notificationIds: Array<Scalars['GlobalID']['input']>;
};


export type MutationRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationRegisterEoiArgs = {
  shiftIds: Array<Scalars['GlobalID']['input']>;
};


export type MutationRejectEoiArgs = {
  shiftAssignmentIds: Array<Scalars['GlobalID']['input']>;
  shiftId: Scalars['GlobalID']['input'];
};


export type MutationRejectPendingEoisArgs = {
  shiftId: Scalars['GlobalID']['input'];
};


export type MutationReorderArgs = {
  ids: Array<Scalars['GlobalID']['input']>;
};


export type MutationResetPasswordArgs = {
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationRevertEoiArgs = {
  shiftAssignmentIds: Array<Scalars['GlobalID']['input']>;
  shiftId: Scalars['GlobalID']['input'];
};


export type MutationRevokeTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type MutationSendEventEmpArgs = {
  empConfig: SendEmpConfig;
  eventId: Scalars['GlobalID']['input'];
  sempConfig: SendEmpConfig;
  sendEmp: Scalars['Boolean']['input'];
  sendStaffEmp: Scalars['Boolean']['input'];
};


export type MutationSendPostReportArgs = {
  emailContent: Scalars['JSON']['input'];
  eventId: Scalars['GlobalID']['input'];
};


export type MutationSendSmsEmailArgs = {
  data: SmsEmailInput;
};


export type MutationSendUserActivationArgs = {
  pks: Array<Scalars['GlobalID']['input']>;
};


export type MutationSendUserPasswordResetArgs = {
  email: Scalars['String']['input'];
};


export type MutationSetEventStatusArgs = {
  eventId: Scalars['GlobalID']['input'];
  sendClientConfirmation: Scalars['Boolean']['input'];
  sendNotifications: Scalars['Boolean']['input'];
  status: EventStatus;
};


export type MutationSetPasswordArgs = {
  newPassword1: Scalars['String']['input'];
  newPassword2: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSetShiftDutyManagerArgs = {
  assignmentId: Scalars['GlobalID']['input'];
  shiftId: Scalars['GlobalID']['input'];
};


export type MutationTokenAuthArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationTwoFactorAuthArgs = {
  oneTimePassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationTwoFactorProvisionArgs = {
  otp: Scalars['String']['input'];
  secret: Scalars['String']['input'];
};


export type MutationUpdateAccountArgs = {
  data: AccountPartialInput;
};


export type MutationUpdateAccreditationArgs = {
  data: AccreditationPartialInput;
};


export type MutationUpdateAdviceTemplateArgs = {
  data: AdviceTemplatePartialInput;
};


export type MutationUpdateCaseArgs = {
  data: CasePartialInput;
};


export type MutationUpdateCaseCategoryArgs = {
  data: CaseCategoryPartialInput;
};


export type MutationUpdateCaseCategorySetArgs = {
  data: CaseCategorySetPartialInput;
};


export type MutationUpdateClientArgs = {
  data: ClientPartialInput;
};


export type MutationUpdateConsumableArgs = {
  data: ConsumablePartialInput;
};


export type MutationUpdateDrugArgs = {
  data: DrugPartialInput;
};


export type MutationUpdateEventArgs = {
  data: EventPartialInput;
};


export type MutationUpdateEventKindArgs = {
  data: EventKindPartialInput;
};


export type MutationUpdateHospitalArgs = {
  data: HospitalPartialInput;
};


export type MutationUpdateMyTimeSheetArgs = {
  data: MyTimeSheetPartialInput;
};


export type MutationUpdateQualificationArgs = {
  data: QualificationPartialInput;
};


export type MutationUpdateQualificationDocumentArgs = {
  data: QualificationDocumentPartialInput;
};


export type MutationUpdateRegionArgs = {
  data: RegionPartialInput;
};


export type MutationUpdateResourceArgs = {
  data: ResourcePartialInput;
};


export type MutationUpdateResourceCategoryArgs = {
  data: ResourceCategoryPartialInput;
};


export type MutationUpdateSkillsetArgs = {
  data: SkillsetPartialInput;
};


export type MutationUpdateTenantArgs = {
  data: TenantPartialInput;
};


export type MutationUpdateTextTemplateArgs = {
  data: TextTemplatePartialInput;
};


export type MutationUpdateTimeSheetArgs = {
  data: TimeSheetPartialInput;
};


export type MutationUpdateUserArgs = {
  data: UserPartialInput;
};


export type MutationUpdateWithdrawEoiRequestArgs = {
  data: ShiftAssignmentWithdrawRequestPartialInput;
};


export type MutationVerifyTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationWithdrawEoiArgs = {
  shiftAssignmentIds: Array<Scalars['GlobalID']['input']>;
};

export type MutationNormalOutput = {
  __typename?: 'MutationNormalOutput';
  errors?: Maybe<Scalars['ExpectedError']['output']>;
  success: Scalars['Boolean']['output'];
};

/** TimeSheet(id, uuid, status, user, shift, date_time_start, date_time_end, notes, created_at, updated_at) */
export type MyTimeSheetInput = {
  dateTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateTimeStart: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  shift: NodeInput;
};

/** TimeSheet(id, uuid, status, user, shift, date_time_start, date_time_end, notes, created_at, updated_at) */
export type MyTimeSheetPartialInput = {
  dateTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
};

/** An object with a Globally Unique ID */
export type Node = {
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
};

/** Input of an object that implements the `Node` interface. */
export type NodeInput = {
  id: Scalars['GlobalID']['input'];
};

/** Input of an object that implements the `Node` interface. */
export type NodeInputPartial = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
};

/** Add/remove/set the selected nodes. */
export type NodeInputPartialListInput = {
  add?: InputMaybe<Array<NodeInputPartial>>;
  remove?: InputMaybe<Array<NodeInputPartial>>;
  set?: InputMaybe<Array<NodeInputPartial>>;
};

export type NotificationCreatedType = {
  __typename?: 'NotificationCreatedType';
  id: Scalars['ID']['output'];
  notification: NotificationType;
  totalUnreadCount: Scalars['Int']['output'];
};

/** Notification(id, uuid, user, message, url, read, created_at) */
export type NotificationFilter = {
  AND?: InputMaybe<NotificationFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<NotificationFilter>;
  OR?: InputMaybe<NotificationFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  read?: InputMaybe<BoolBaseFilterLookup>;
};

export type NotificationResponseType = {
  __typename?: 'NotificationResponseType';
  notifications: Array<NotificationType>;
  totalUnreadCount: Scalars['Int']['output'];
};

/** Notification(id, uuid, user, message, url, read, created_at) */
export type NotificationType = Node & {
  __typename?: 'NotificationType';
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  message: Scalars['String']['output'];
  read: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type NotificationTypeConnection = {
  __typename?: 'NotificationTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<NotificationTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type NotificationTypeEdge = {
  __typename?: 'NotificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: NotificationType;
};

/**
 *
 *     encapsulates token data, and refresh token data if `JWT_LONG_RUNNING_REFRESH_TOKEN` is on.
 *     with an output interface.
 *
 */
export type ObtainJsonWebTokenType = OutputInterface & {
  __typename?: 'ObtainJSONWebTokenType';
  errors?: Maybe<Scalars['ExpectedError']['output']>;
  refreshToken?: Maybe<RefreshTokenType>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<TokenType>;
  user?: Maybe<UserType>;
};

export type OffsetPaginationInput = {
  limit?: Scalars['Int']['input'];
  offset?: Scalars['Int']['input'];
};

export type OperationInfo = {
  __typename?: 'OperationInfo';
  /** List of messages returned by the operation. */
  messages: Array<OperationMessage>;
};

export type OperationMessage = {
  __typename?: 'OperationMessage';
  /** The error code, or `null` if no error code was set. */
  code?: Maybe<Scalars['String']['output']>;
  /** The field that caused the error, or `null` if it isn't associated with any particular field. */
  field?: Maybe<Scalars['String']['output']>;
  /** The kind of this message. */
  kind: OperationMessageKind;
  /** The error message. */
  message: Scalars['String']['output'];
};

export enum OperationMessageKind {
  Error = 'ERROR',
  Info = 'INFO',
  Permission = 'PERMISSION',
  Validation = 'VALIDATION',
  Warning = 'WARNING'
}

export enum Ordering {
  Asc = 'ASC',
  AscNullsFirst = 'ASC_NULLS_FIRST',
  AscNullsLast = 'ASC_NULLS_LAST',
  Desc = 'DESC',
  DescNullsFirst = 'DESC_NULLS_FIRST',
  DescNullsLast = 'DESC_NULLS_LAST'
}

export type OutputInterface = {
  errors?: Maybe<Scalars['ExpectedError']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Information to aid in pagination. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PatientGender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

/** Patient(id, uuid, created_at, updated_at, deleted_at, patient_name, patient_name_uto, patient_name_hashed, patient_dob, patient_dob_age, patient_dob_use_age, patient_dob_uto, patient_dob_hashed, patient_gender, patient_phone, patient_phone_uto, patient_phone_hashed, patient_address, patient_address_uto, patient_address_hashed, patient_email, patient_email_hashed) */
export type PatientInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  patientAddress?: InputMaybe<Scalars['String']['input']>;
  patientAddressUto?: InputMaybe<Scalars['Boolean']['input']>;
  patientDob?: InputMaybe<Scalars['DateTime']['input']>;
  patientDobAge?: InputMaybe<Scalars['String']['input']>;
  patientDobUseAge?: InputMaybe<Scalars['Boolean']['input']>;
  patientDobUto?: InputMaybe<Scalars['Boolean']['input']>;
  patientEmail?: InputMaybe<Scalars['String']['input']>;
  patientGender?: InputMaybe<PatientGender>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  patientNameUto?: InputMaybe<Scalars['Boolean']['input']>;
  patientPhone?: InputMaybe<Scalars['String']['input']>;
  patientPhoneUto?: InputMaybe<Scalars['Boolean']['input']>;
  pmhxAllergies: Array<CasePatientPmHxAllergyInput>;
  pmhxHistory: Array<CasePatientPmHxHistoryInput>;
  pmhxMedications: Array<CasePatientPmHxMedicationInput>;
};

/** Patient(id, uuid, created_at, updated_at, deleted_at, patient_name, patient_name_uto, patient_name_hashed, patient_dob, patient_dob_age, patient_dob_use_age, patient_dob_uto, patient_dob_hashed, patient_gender, patient_phone, patient_phone_uto, patient_phone_hashed, patient_address, patient_address_uto, patient_address_hashed, patient_email, patient_email_hashed) */
export type PatientPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  patientAddress?: InputMaybe<Scalars['String']['input']>;
  patientAddressUto?: InputMaybe<Scalars['Boolean']['input']>;
  patientDob?: InputMaybe<Scalars['DateTime']['input']>;
  patientDobAge?: InputMaybe<Scalars['String']['input']>;
  patientDobUseAge?: InputMaybe<Scalars['Boolean']['input']>;
  patientDobUto?: InputMaybe<Scalars['Boolean']['input']>;
  patientEmail?: InputMaybe<Scalars['String']['input']>;
  patientGender?: InputMaybe<PatientGender>;
  patientName?: InputMaybe<Scalars['String']['input']>;
  patientNameUto?: InputMaybe<Scalars['Boolean']['input']>;
  patientPhone?: InputMaybe<Scalars['String']['input']>;
  patientPhoneUto?: InputMaybe<Scalars['Boolean']['input']>;
  pmhxAllergies?: InputMaybe<Array<CasePatientPmHxAllergyPartialInput>>;
  pmhxHistory?: InputMaybe<Array<CasePatientPmHxHistoryPartialInput>>;
  pmhxMedications?: InputMaybe<Array<CasePatientPmHxMedicationPartialInput>>;
};

/** Patient(id, uuid, created_at, updated_at, deleted_at, patient_name, patient_name_uto, patient_name_hashed, patient_dob, patient_dob_age, patient_dob_use_age, patient_dob_uto, patient_dob_hashed, patient_gender, patient_phone, patient_phone_uto, patient_phone_hashed, patient_address, patient_address_uto, patient_address_hashed, patient_email, patient_email_hashed) */
export type PatientType = BaseInterface & Node & {
  __typename?: 'PatientType';
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  patientAddress?: Maybe<Scalars['String']['output']>;
  patientAddressUto: Scalars['Boolean']['output'];
  patientDob?: Maybe<Scalars['DateTime']['output']>;
  patientDobAge?: Maybe<Scalars['String']['output']>;
  patientDobUseAge: Scalars['Boolean']['output'];
  patientDobUto: Scalars['Boolean']['output'];
  patientEmail?: Maybe<Scalars['String']['output']>;
  patientGender?: Maybe<PatientGender>;
  patientName?: Maybe<Scalars['String']['output']>;
  patientNameUto: Scalars['Boolean']['output'];
  patientPhone?: Maybe<Scalars['String']['output']>;
  patientPhoneUto: Scalars['Boolean']['output'];
  pmhxAllergies: Array<CasePatientPmHxAllergyType>;
  pmhxHistory: Array<CasePatientPmHxHistoryType>;
  pmhxMedications: Array<CasePatientPmHxMedicationType>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

export enum PeripheralPulse {
  Absent = 'ABSENT',
  Strong = 'STRONG',
  Weak = 'WEAK'
}

export type Permission = {
  __typename?: 'Permission';
  label: Scalars['String']['output'];
  permission: Scalars['String']['output'];
};

export type PermissionGroup = {
  __typename?: 'PermissionGroup';
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  permissions: Array<Permission>;
};

export enum PulseLocation {
  Brachial = 'BRACHIAL',
  Carotid = 'CAROTID',
  DorsalisPedis = 'DORSALIS_PEDIS',
  Femoral = 'FEMORAL',
  PosteriorTibial = 'POSTERIOR_TIBIAL',
  Radial = 'RADIAL'
}

export enum PulseRegularity {
  Irregular = 'IRREGULAR',
  Regular = 'REGULAR'
}

export enum Pupils {
  NonReactive = 'NON_REACTIVE',
  Pearl = 'PEARL',
  Sluggish = 'SLUGGISH',
  Unequal = 'UNEQUAL'
}

/** QualificationDocumentAudit(id, uuid, created_at, updated_at, deleted_at, document, user, notes, outcome) */
export type QualificationDocumentAuditInput = {
  document: NodeInput;
  notes?: InputMaybe<Scalars['String']['input']>;
  outcome?: InputMaybe<QualificationDocumentAuditOutcome>;
};

export type QualificationDocumentAuditOrder = {
  createdAt?: InputMaybe<Ordering>;
  outcome?: InputMaybe<Ordering>;
};

export enum QualificationDocumentAuditOutcome {
  Approved = 'APPROVED',
  Returned = 'RETURNED'
}

/** QualificationDocumentAudit(id, uuid, created_at, updated_at, deleted_at, document, user, notes, outcome) */
export type QualificationDocumentAuditType = BaseInterface & Node & {
  __typename?: 'QualificationDocumentAuditType';
  createdAt: Scalars['DateTime']['output'];
  document: QualificationDocumentType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  outcome: QualificationDocumentAuditOutcome;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

export enum QualificationDocumentExpiryStatus {
  Current = 'CURRENT',
  Expired = 'EXPIRED',
  ExpiresSoon = 'EXPIRES_SOON'
}

/** QualificationDocument(id, uuid, created_at, updated_at, deleted_at, status, expiry_status, qualification, user, file, ref, issued_at, expires_at) */
export type QualificationDocumentFilter = {
  AND?: InputMaybe<QualificationDocumentFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<QualificationDocumentFilter>;
  OR?: InputMaybe<QualificationDocumentFilter>;
  expiresAt?: InputMaybe<DatetimeDatetimeFilterLookup>;
  expiresSoon?: InputMaybe<Scalars['Boolean']['input']>;
  qualification?: InputMaybe<QualificationFilter>;
  ref?: InputMaybe<StrFilterLookup>;
  status?: InputMaybe<QualificationDocumentStatusFilterLookup>;
  user?: InputMaybe<UserFilter>;
};

/** QualificationDocument(id, uuid, created_at, updated_at, deleted_at, status, expiry_status, qualification, user, file, ref, issued_at, expires_at) */
export type QualificationDocumentInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  issuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  qualification: NodeInput;
  ref?: InputMaybe<Scalars['String']['input']>;
  submitted: Scalars['Boolean']['input'];
};

export type QualificationDocumentOrder = {
  createdAt?: InputMaybe<Ordering>;
  expiresAt?: InputMaybe<Ordering>;
  expiryStatus?: InputMaybe<Ordering>;
  qualification?: InputMaybe<QualificationOrder>;
  status?: InputMaybe<Ordering>;
  user?: InputMaybe<UserOrder>;
};

/** QualificationDocument(id, uuid, created_at, updated_at, deleted_at, status, expiry_status, qualification, user, file, ref, issued_at, expires_at) */
export type QualificationDocumentPartialInput = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  file?: InputMaybe<Scalars['Upload']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  issuedAt?: InputMaybe<Scalars['DateTime']['input']>;
  qualification?: InputMaybe<NodeInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  submitted: Scalars['Boolean']['input'];
};

export enum QualificationDocumentStatus {
  Approved = 'APPROVED',
  Draft = 'DRAFT',
  Pending = 'PENDING'
}

export type QualificationDocumentStatusFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<QualificationDocumentStatus>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<QualificationDocumentStatus>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<QualificationDocumentStatus>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<QualificationDocumentStatus>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<QualificationDocumentStatus>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<QualificationDocumentStatus>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<QualificationDocumentStatus>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<QualificationDocumentStatus>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<QualificationDocumentStatus>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<QualificationDocumentStatus>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<QualificationDocumentStatus>;
};

/** QualificationDocument(id, uuid, created_at, updated_at, deleted_at, status, expiry_status, qualification, user, file, ref, issued_at, expires_at) */
export type QualificationDocumentType = BaseInterface & Node & {
  __typename?: 'QualificationDocumentType';
  audits: Array<QualificationDocumentAuditType>;
  createdAt: Scalars['DateTime']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  expiryStatus: QualificationDocumentExpiryStatus;
  file?: Maybe<DjangoFileType>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  isExpired: Scalars['Boolean']['output'];
  issuedAt?: Maybe<Scalars['DateTime']['output']>;
  qualification: QualificationType;
  ref?: Maybe<Scalars['String']['output']>;
  status: QualificationDocumentStatus;
  updatedAt: Scalars['DateTime']['output'];
  user: UserType;
  uuid: Scalars['UUID']['output'];
};


/** QualificationDocument(id, uuid, created_at, updated_at, deleted_at, status, expiry_status, qualification, user, file, ref, issued_at, expires_at) */
export type QualificationDocumentTypeAuditsArgs = {
  order?: InputMaybe<QualificationDocumentAuditOrder>;
  pagination?: InputMaybe<OffsetPaginationInput>;
};

/** A connection to a list of items. */
export type QualificationDocumentTypeConnection = {
  __typename?: 'QualificationDocumentTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<QualificationDocumentTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type QualificationDocumentTypeEdge = {
  __typename?: 'QualificationDocumentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: QualificationDocumentType;
};

export enum QualificationExpiryType {
  Date = 'DATE',
  Interval = 'INTERVAL',
  Never = 'NEVER'
}

/** Qualification(id, uuid, created_at, updated_at, deleted_at, name, code, expiry_type, expiry_interval) */
export type QualificationFilter = {
  AND?: InputMaybe<QualificationFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<QualificationFilter>;
  OR?: InputMaybe<QualificationFilter>;
  code?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  isMissing?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<StrFilterLookup>;
};

/** Qualification(id, uuid, created_at, updated_at, deleted_at, name, code, expiry_type, expiry_interval) */
export type QualificationInput = {
  code: Scalars['String']['input'];
  expiryInterval?: InputMaybe<Scalars['Int']['input']>;
  expiryType?: InputMaybe<QualificationExpiryType>;
  name: Scalars['String']['input'];
};

export type QualificationOrder = {
  code?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Qualification(id, uuid, created_at, updated_at, deleted_at, name, code, expiry_type, expiry_interval) */
export type QualificationPartialInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  expiryInterval?: InputMaybe<Scalars['Int']['input']>;
  expiryType?: InputMaybe<QualificationExpiryType>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** QualificationRequirement(id, uuid, skillset, validation_type, created_at) */
export type QualificationRequirementFilter = {
  AND?: InputMaybe<QualificationRequirementFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<QualificationRequirementFilter>;
  OR?: InputMaybe<QualificationRequirementFilter>;
  isExpired?: InputMaybe<Scalars['Boolean']['input']>;
  isMissing?: InputMaybe<Scalars['Boolean']['input']>;
  skillset?: InputMaybe<NodeInput>;
};

/** QualificationRequirement(id, uuid, skillset, validation_type, created_at) */
export type QualificationRequirementInput = {
  qualifications?: InputMaybe<NodeInputPartialListInput>;
  validationType?: InputMaybe<ValidationType>;
};

/** QualificationRequirement(id, uuid, skillset, validation_type, created_at) */
export type QualificationRequirementType = Node & {
  __typename?: 'QualificationRequirementType';
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  qualifications: Array<QualificationType>;
  uuid: Scalars['UUID']['output'];
  validationType: ValidationType;
};


/** QualificationRequirement(id, uuid, skillset, validation_type, created_at) */
export type QualificationRequirementTypeQualificationsArgs = {
  filters?: InputMaybe<QualificationFilter>;
  order?: InputMaybe<QualificationOrder>;
};

/** A connection to a list of items. */
export type QualificationRequirementTypeConnection = {
  __typename?: 'QualificationRequirementTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<QualificationRequirementTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type QualificationRequirementTypeEdge = {
  __typename?: 'QualificationRequirementTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: QualificationRequirementType;
};

/** Qualification(id, uuid, created_at, updated_at, deleted_at, name, code, expiry_type, expiry_interval) */
export type QualificationType = BaseInterface & Node & {
  __typename?: 'QualificationType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  expiryInterval?: Maybe<Scalars['Int']['output']>;
  expiryType: QualificationExpiryType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  isExpired: Scalars['Boolean']['output'];
  isMissing: Scalars['Boolean']['output'];
  isPending: Scalars['Boolean']['output'];
  isRequired: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type QualificationTypeConnection = {
  __typename?: 'QualificationTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<QualificationTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type QualificationTypeEdge = {
  __typename?: 'QualificationTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: QualificationType;
};

export type Query = {
  __typename?: 'Query';
  accreditation: AccreditationType;
  accreditations: AccreditationTypeConnection;
  activeTimeSheet?: Maybe<TimeSheetType>;
  adviceTemplate: AdviceTemplateType;
  adviceTemplates: AdviceTemplateTypeConnection;
  case: CaseType;
  caseCategories: CaseCategoryTypeConnection;
  caseCategory: CaseCategoryType;
  caseCategorySet: CaseCategorySetType;
  caseCategorySets: CaseCategorySetTypeConnection;
  cases: CaseTypeConnection;
  client: ClientType;
  clients: ClientTypeConnection;
  consumable: ConsumableType;
  consumables: ConsumableTypeConnection;
  drug: DrugType;
  drugs: DrugTypeConnection;
  event: EventType;
  eventKind: EventKindType;
  eventKinds: EventKindTypeConnection;
  events: EventTypeConnection;
  fileNode: FileNodeType;
  fileNodes: FileNodeTypeConnection;
  hospital: HospitalType;
  hospitals: HospitalTypeConnection;
  me: UserType;
  myCases: CaseTypeConnection;
  myShiftAssignments: ShiftAssignmentTypeConnection;
  myTimeSheets: TimeSheetTypeConnection;
  notifications: NotificationTypeConnection;
  permissions: Array<AppPermissions>;
  qualification: QualificationType;
  qualificationDocument: QualificationDocumentType;
  qualificationDocuments: QualificationDocumentTypeConnection;
  qualificationRequirements: QualificationRequirementTypeConnection;
  qualifications: QualificationTypeConnection;
  region: RegionType;
  regions: RegionTypeConnection;
  resource: ResourceType;
  resourceCategories: ResourceCategoryTypeConnection;
  resourceCategory: ResourceCategoryType;
  resources: ResourceTypeConnection;
  searchForPatient?: Maybe<PatientType>;
  shift: ShiftType;
  shiftAssignments: ShiftAssignmentTypeConnection;
  shiftPosition: ShiftPositionType;
  shiftPositions: ShiftPositionTypeConnection;
  shifts: ShiftTypeConnection;
  skillset: SkillsetType;
  skillsets: SkillsetTypeConnection;
  slackChannels: Array<SlackChannelType>;
  tenant?: Maybe<TenantType>;
  textTemplate: TextTemplateType;
  textTemplates: TextTemplateTypeConnection;
  timeSheet: TimeSheetType;
  timeSheets: TimeSheetTypeConnection;
  twoFactorProvision: TwoFactorProvision;
  user: UserType;
  users: UserTypeConnection;
};


export type QueryAccreditationArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryAccreditationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AccreditationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<AccreditationOrder>;
};


export type QueryAdviceTemplateArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryAdviceTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<AdviceTemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<AdviceTemplateOrder>;
};


export type QueryCaseArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryCaseCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CaseCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CaseCategoryOrder>;
};


export type QueryCaseCategoryArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryCaseCategorySetArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryCaseCategorySetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CaseCategorySetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CaseCategorySetOrder>;
};


export type QueryCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CaseFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CaseOrder>;
};


export type QueryClientArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryClientsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ClientFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ClientOrder>;
};


export type QueryConsumableArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryConsumablesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ConsumableFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ConsumableOrder>;
};


export type QueryDrugArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryDrugsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<DrugFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<DrugOrder>;
};


export type QueryEventArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryEventKindArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryEventKindsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<EventKindFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<EventKindOrder>;
};


export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<EventFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<EventOrder>;
};


export type QueryFileNodeArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryFileNodesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<FileNodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryHospitalArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryHospitalsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<HospitalFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<HospitalOrder>;
};


export type QueryMyCasesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<CaseFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<CaseOrder>;
};


export type QueryMyShiftAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ShiftAssignmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ShiftAssignmentOrder>;
};


export type QueryMyTimeSheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TimeSheetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TimeSheetOrder>;
};


export type QueryNotificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<NotificationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQualificationArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryQualificationDocumentArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryQualificationDocumentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<QualificationDocumentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<QualificationDocumentOrder>;
};


export type QueryQualificationRequirementsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<QualificationRequirementFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryQualificationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<QualificationFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<QualificationOrder>;
};


export type QueryRegionArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryRegionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<RegionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<RegionOrder>;
};


export type QueryResourceArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryResourceCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ResourceCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ResourceCategoryOrder>;
};


export type QueryResourceCategoryArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ResourceFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ResourceOrder>;
};


export type QuerySearchForPatientArgs = {
  phone: Scalars['String']['input'];
};


export type QueryShiftArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryShiftAssignmentsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ShiftAssignmentFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ShiftAssignmentOrder>;
};


export type QueryShiftPositionArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryShiftPositionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ShiftPositionFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ShiftPositionOrder>;
};


export type QueryShiftsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<ShiftFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<ShiftOrder>;
};


export type QuerySkillsetArgs = {
  pk: Scalars['ID']['input'];
};


export type QuerySkillsetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<SkillsetOrder>;
};


export type QueryTextTemplateArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryTextTemplatesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TextTemplateFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TextTemplateOrder>;
};


export type QueryTimeSheetArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryTimeSheetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<TimeSheetFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<TimeSheetOrder>;
};


export type QueryUserArgs = {
  pk: Scalars['ID']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  order?: InputMaybe<UserOrder>;
};

/**
 *
 * Refresh token can be used to obtain a new token instead of log in again
 * when the token expires.
 *
 * *This is only used if `JWT_LONG_RUNNING_REFRESH_TOKEN` is set to True.*
 *
 */
export type RefreshTokenType = {
  __typename?: 'RefreshTokenType';
  created: Scalars['DateTime']['output'];
  expiresAt: Scalars['DateTime']['output'];
  isExpired: Scalars['Boolean']['output'];
  revoked?: Maybe<Scalars['DateTime']['output']>;
  /** randomly generated token that is attached to a FK user. */
  token: Scalars['String']['output'];
};

/** Region(id, uuid, created_at, updated_at, deleted_at, name, code) */
export type RegionFilter = {
  AND?: InputMaybe<RegionFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<RegionFilter>;
  OR?: InputMaybe<RegionFilter>;
  code?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** Region(id, uuid, created_at, updated_at, deleted_at, name, code) */
export type RegionInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type RegionOrder = {
  code?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Region(id, uuid, created_at, updated_at, deleted_at, name, code) */
export type RegionPartialInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Region(id, uuid, created_at, updated_at, deleted_at, name, code) */
export type RegionType = Node & {
  __typename?: 'RegionType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type RegionTypeConnection = {
  __typename?: 'RegionTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<RegionTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type RegionTypeEdge = {
  __typename?: 'RegionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: RegionType;
};

/** ResourceAllocation(id, uuid, resource, event, notes, created_at) */
export type ResourceAllocationFilter = {
  AND?: InputMaybe<ResourceAllocationFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ResourceAllocationFilter>;
  OR?: InputMaybe<ResourceAllocationFilter>;
  event?: InputMaybe<EventFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
};

/** ResourceAllocation(id, uuid, resource, event, notes, created_at) */
export type ResourceAllocationInput = {
  notes?: InputMaybe<Scalars['String']['input']>;
  resource: NodeInput;
};

/** ResourceAllocation(id, uuid, resource, event, notes, created_at) */
export type ResourceAllocationPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<NodeInput>;
};

/** ResourceAllocation(id, uuid, resource, event, notes, created_at) */
export type ResourceAllocationType = Node & {
  __typename?: 'ResourceAllocationType';
  createdAt: Scalars['DateTime']['output'];
  event: EventType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  resource: ResourceType;
  uuid: Scalars['UUID']['output'];
};

/** ResourceCategory(id, uuid, created_at, updated_at, deleted_at, order, name) */
export type ResourceCategoryFilter = {
  AND?: InputMaybe<ResourceCategoryFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ResourceCategoryFilter>;
  OR?: InputMaybe<ResourceCategoryFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** ResourceCategory(id, uuid, created_at, updated_at, deleted_at, order, name) */
export type ResourceCategoryInput = {
  name: Scalars['String']['input'];
};

export type ResourceCategoryOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** ResourceCategory(id, uuid, created_at, updated_at, deleted_at, order, name) */
export type ResourceCategoryPartialInput = {
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** ResourceCategory(id, uuid, created_at, updated_at, deleted_at, order, name) */
export type ResourceCategoryType = BaseInterface & BaseReorderableInterface & Node & {
  __typename?: 'ResourceCategoryType';
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  resources: Array<ResourceType>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** ResourceCategory(id, uuid, created_at, updated_at, deleted_at, order, name) */
export type ResourceCategoryTypeResourcesArgs = {
  filters?: InputMaybe<ResourceFilter>;
  order?: InputMaybe<ResourceOrder>;
};

/** A connection to a list of items. */
export type ResourceCategoryTypeConnection = {
  __typename?: 'ResourceCategoryTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ResourceCategoryTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ResourceCategoryTypeEdge = {
  __typename?: 'ResourceCategoryTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ResourceCategoryType;
};

/** Resource(id, uuid, created_at, updated_at, deleted_at, order, name, description, category, is_personal, user) */
export type ResourceFilter = {
  AND?: InputMaybe<ResourceFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ResourceFilter>;
  OR?: InputMaybe<ResourceFilter>;
  category?: InputMaybe<ResourceCategoryFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
};

/** Resource(id, uuid, created_at, updated_at, deleted_at, order, name, description, category, is_personal, user) */
export type ResourceInput = {
  category: NodeInput;
  description?: InputMaybe<Scalars['String']['input']>;
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  user?: InputMaybe<NodeInput>;
};

export type ResourceOrder = {
  category?: InputMaybe<ResourceCategoryOrder>;
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Resource(id, uuid, created_at, updated_at, deleted_at, order, name, description, category, is_personal, user) */
export type ResourcePartialInput = {
  category?: InputMaybe<NodeInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  isPersonal?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<NodeInput>;
};

/** Resource(id, uuid, created_at, updated_at, deleted_at, order, name, description, category, is_personal, user) */
export type ResourceType = BaseInterface & BaseReorderableInterface & Node & {
  __typename?: 'ResourceType';
  category: ResourceCategoryType;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  isPersonal: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  resourceAllocations: Array<ResourceAllocationType>;
  updatedAt: Scalars['DateTime']['output'];
  user?: Maybe<UserType>;
  uuid: Scalars['UUID']['output'];
};


/** Resource(id, uuid, created_at, updated_at, deleted_at, order, name, description, category, is_personal, user) */
export type ResourceTypeResourceAllocationsArgs = {
  filters?: InputMaybe<ResourceAllocationFilter>;
};

/** A connection to a list of items. */
export type ResourceTypeConnection = {
  __typename?: 'ResourceTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ResourceTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ResourceTypeEdge = {
  __typename?: 'ResourceTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ResourceType;
};

export enum RespiratoryRate {
  HighRisk = 'HIGH_RISK',
  LowRisk = 'LOW_RISK',
  MediumRisk = 'MEDIUM_RISK'
}

export enum RespiratoryRegularity {
  Irregular = 'IRREGULAR',
  Normal = 'NORMAL',
  Shallow = 'SHALLOW'
}

export enum Response {
  Alert = 'ALERT',
  Pain = 'PAIN',
  Unresponsive = 'UNRESPONSIVE',
  Voice = 'VOICE'
}

export type RevokeRefreshTokenType = {
  __typename?: 'RevokeRefreshTokenType';
  errors?: Maybe<Scalars['ExpectedError']['output']>;
  refreshToken?: Maybe<RefreshTokenType>;
  success: Scalars['Boolean']['output'];
};

export enum Rhythm {
  Irregular = 'IRREGULAR',
  LongExp = 'LONG_EXP',
  LongInsp = 'LONG_INSP',
  Regular = 'REGULAR'
}

export enum RxAirwayType {
  Cricothyroidotomy = 'CRICOTHYROIDOTOMY',
  DoubleAirwayManoeuvre = 'DOUBLE_AIRWAY_MANOEUVRE',
  EndotrachealTube = 'ENDOTRACHEAL_TUBE',
  Laryngoscopy = 'LARYNGOSCOPY',
  NasopharyngealAirway = 'NASOPHARYNGEAL_AIRWAY',
  OropharyngealAirway = 'OROPHARYNGEAL_AIRWAY',
  Suction = 'SUCTION',
  SupraglotticAirway = 'SUPRAGLOTTIC_AIRWAY',
  TripleAirwayManoeuvre = 'TRIPLE_AIRWAY_MANOEUVRE'
}

export enum RxBreathingVentilationType {
  BagValveMask = 'BAG_VALVE_MASK',
  MechanicalVentilation = 'MECHANICAL_VENTILATION',
  MouthToMask = 'MOUTH_TO_MASK',
  NeedleThoracocentesis = 'NEEDLE_THORACOCENTESIS'
}

export enum RxCprOperation {
  MultipleOperator = 'MULTIPLE_OPERATOR',
  SingleOperator = 'SINGLE_OPERATOR'
}

export enum RxCprType {
  Cpr_30_2 = 'CPR_30_2',
  HandsOnly = 'HANDS_ONLY'
}

export enum RxCannulaAttempts {
  One = 'ONE',
  Two = 'TWO'
}

export enum RxCannulaSite {
  LAntecubitalFossa = 'L_ANTECUBITAL_FOSSA',
  LDorsumHand = 'L_DORSUM_HAND',
  LFoot = 'L_FOOT',
  LForearm = 'L_FOREARM',
  LWrist = 'L_WRIST',
  RAntecubitalFossa = 'R_ANTECUBITAL_FOSSA',
  RDorsumHand = 'R_DORSUM_HAND',
  RFoot = 'R_FOOT',
  RForearm = 'R_FOREARM',
  RWrist = 'R_WRIST'
}

export enum RxCannulaSize {
  G14 = 'G14',
  G16 = 'G16',
  G18 = 'G18',
  G20 = 'G20',
  G22 = 'G22',
  G24 = 'G24'
}

export enum RxConsultMethod {
  InPerson = 'IN_PERSON',
  Phone = 'PHONE',
  Radio = 'RADIO'
}

export enum RxConsultType {
  ExternalHcp = 'EXTERNAL_HCP',
  InternalStaff = 'INTERNAL_STAFF'
}

export enum RxDefibrillationPads {
  Adult = 'ADULT',
  Paediatric = 'PAEDIATRIC'
}

export enum RxDefibrillationType {
  Manual = 'MANUAL',
  SemiAutomatic = 'SEMI_AUTOMATIC'
}

export enum RxEffectiveness {
  Effective = 'EFFECTIVE',
  Ineffective = 'INEFFECTIVE',
  PartiallyEffective = 'PARTIALLY_EFFECTIVE',
  UnableToAscertain = 'UNABLE_TO_ASCERTAIN'
}

export enum RxFirstAidType {
  AnatomicalSplinting = 'ANATOMICAL_SPLINTING',
  AntisepticCream = 'ANTISEPTIC_CREAM',
  ArmSling = 'ARM_SLING',
  BurnDressingGel = 'BURN_DRESSING_GEL',
  CleanedWithSalineGauze = 'CLEANED_WITH_SALINE_GAUZE',
  CompressionBandage = 'COMPRESSION_BANDAGE',
  DressedWithAdhesiveDressing = 'DRESSED_WITH_ADHESIVE_DRESSING',
  EyeIrrigation = 'EYE_IRRIGATION',
  EyePad = 'EYE_PAD',
  FormableSplint = 'FORMABLE_SPLINT',
  HeatPack = 'HEAT_PACK',
  IcePack = 'ICE_PACK',
  Other = 'OTHER',
  PressureImmobilisationTechnique = 'PRESSURE_IMMOBILISATION_TECHNIQUE',
  SplinterProbe = 'SPLINTER_PROBE',
  ThermalBlanket = 'THERMAL_BLANKET',
  WoundClosureStrips = 'WOUND_CLOSURE_STRIPS',
  WoundDressingWithRollerBandage = 'WOUND_DRESSING_WITH_ROLLER_BANDAGE',
  WoundIrrigation = 'WOUND_IRRIGATION'
}

export enum RxMajorTraumaType {
  ArterialTourniquet = 'ARTERIAL_TOURNIQUET',
  CervicalCollar = 'CERVICAL_COLLAR',
  ChestSeal = 'CHEST_SEAL',
  HaemostaticDressing = 'HAEMOSTATIC_DRESSING',
  PelvicBinder = 'PELVIC_BINDER',
  TractionSplint = 'TRACTION_SPLINT'
}

export enum RxMedicationRoute {
  Extraocular = 'EXTRAOCULAR',
  Inhalation = 'INHALATION',
  Intramuscular = 'INTRAMUSCULAR',
  Intranasal = 'INTRANASAL',
  Intraosseous = 'INTRAOSSEOUS',
  Intravenous = 'INTRAVENOUS',
  Oral = 'ORAL',
  Subcutaneous = 'SUBCUTANEOUS',
  Sublingual = 'SUBLINGUAL',
  Topical = 'TOPICAL',
  Transdermal = 'TRANSDERMAL'
}

export enum RxOxygenType {
  NasalCannula = 'NASAL_CANNULA',
  NebuilserMask = 'NEBUILSER_MASK',
  NonRebreatherMask = 'NON_REBREATHER_MASK'
}

export enum RxType {
  Airway = 'AIRWAY',
  BreathingVentilation = 'BREATHING_VENTILATION',
  Consult = 'CONSULT',
  Cpr = 'CPR',
  Defibrillation = 'DEFIBRILLATION',
  FirstAid = 'FIRST_AID',
  IvAccess = 'IV_ACCESS',
  MajorTrauma = 'MAJOR_TRAUMA',
  Medications = 'MEDICATIONS',
  Other = 'OTHER',
  Oxygen = 'OXYGEN',
  RestAndReassurance = 'REST_AND_REASSURANCE'
}

export type SmsEmailInput = {
  emailContent?: InputMaybe<Scalars['JSON']['input']>;
  emailSubject?: InputMaybe<Scalars['String']['input']>;
  smsContent?: InputMaybe<Scalars['String']['input']>;
  type: SmsEmailType;
  users: Array<Scalars['GlobalID']['input']>;
};

export enum SmsEmailType {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type SendEmpConfig = {
  briefing?: InputMaybe<Scalars['Boolean']['input']>;
  clientRequests?: InputMaybe<Scalars['Boolean']['input']>;
  duties?: InputMaybe<Scalars['Boolean']['input']>;
  equipment?: InputMaybe<Scalars['Boolean']['input']>;
  equipmentCollection?: InputMaybe<Scalars['Boolean']['input']>;
  info?: InputMaybe<Scalars['Boolean']['input']>;
  otherInfo?: InputMaybe<Scalars['Boolean']['input']>;
  staffing?: InputMaybe<Scalars['Boolean']['input']>;
  terms?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SendForHelp {
  AmboBystander = 'AMBO_BYSTANDER',
  AmboControl = 'AMBO_CONTROL',
  AmboMedic = 'AMBO_MEDIC',
  Fire = 'FIRE',
  NotApplicable = 'NOT_APPLICABLE',
  Police = 'POLICE'
}

export enum Sensation {
  Absent = 'ABSENT',
  Normal = 'NORMAL',
  Paraesthesia = 'PARAESTHESIA'
}

/** ShiftAssignment(id, status, shift, shift_position, user, confirmed, confirmed_at, created_at) */
export type ShiftAssignmentFilter = {
  AND?: InputMaybe<ShiftAssignmentFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ShiftAssignmentFilter>;
  OR?: InputMaybe<ShiftAssignmentFilter>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  shift?: InputMaybe<ShiftFilter>;
  status?: InputMaybe<Array<AssignmentStatus>>;
  user?: InputMaybe<UserFilter>;
};

export type ShiftAssignmentOrder = {
  createdAt?: InputMaybe<Ordering>;
  shift?: InputMaybe<ShiftOrder>;
  shiftPosition?: InputMaybe<ShiftPositionOrder>;
  status?: InputMaybe<Ordering>;
  user?: InputMaybe<UserOrder>;
};

/** ShiftAssignment(id, status, shift, shift_position, user, confirmed, confirmed_at, created_at) */
export type ShiftAssignmentType = Node & {
  __typename?: 'ShiftAssignmentType';
  conflicts: Array<ShiftAssignmentType>;
  createdAt: Scalars['DateTime']['output'];
  hasConflicts: Scalars['Boolean']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  shift: ShiftType;
  shiftPosition: ShiftPositionType;
  status: AssignmentStatus;
  user: UserType;
  withdrawRequest?: Maybe<ShiftAssignmentWithdrawRequestType>;
};

/** A connection to a list of items. */
export type ShiftAssignmentTypeConnection = {
  __typename?: 'ShiftAssignmentTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ShiftAssignmentTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ShiftAssignmentTypeEdge = {
  __typename?: 'ShiftAssignmentTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ShiftAssignmentType;
};

/** ShiftAssignmentWithdrawRequest(id, status, assignment, reason, message, created_at) */
export type ShiftAssignmentWithdrawRequestInput = {
  assignment: NodeInput;
  reason: Scalars['String']['input'];
};

/** ShiftAssignmentWithdrawRequest(id, status, assignment, reason, message, created_at) */
export type ShiftAssignmentWithdrawRequestPartialInput = {
  assignment?: InputMaybe<NodeInput>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<WithdrawRequestStatus>;
};

/** ShiftAssignmentWithdrawRequest(id, status, assignment, reason, message, created_at) */
export type ShiftAssignmentWithdrawRequestType = Node & {
  __typename?: 'ShiftAssignmentWithdrawRequestType';
  assignment: ShiftAssignmentType;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  message: Scalars['String']['output'];
  reason: Scalars['String']['output'];
  status: WithdrawRequestStatus;
};

export type ShiftEoiResponseType = {
  __typename?: 'ShiftEOIResponseType';
  operationInfo?: Maybe<OperationInfo>;
  shift?: Maybe<ShiftType>;
  shiftAssignments?: Maybe<Array<ShiftAssignmentType>>;
};

/** Shift(id, start_time, end_time, description, event, duty_manager, created_at) */
export type ShiftFilter = {
  AND?: InputMaybe<ShiftFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ShiftFilter>;
  OR?: InputMaybe<ShiftFilter>;
  availableOnly?: InputMaybe<Scalars['Boolean']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  endTime?: InputMaybe<DatetimeDatetimeFilterLookup>;
  event?: InputMaybe<EventFilter>;
  eventStatus?: InputMaybe<EventStatus>;
  futureOnly?: InputMaybe<Scalars['Boolean']['input']>;
  skillsetOnly?: InputMaybe<Scalars['Boolean']['input']>;
  startTime?: InputMaybe<DatetimeDatetimeFilterLookup>;
  todayOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Shift(id, start_time, end_time, description, event, duty_manager, created_at) */
export type ShiftInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dutyManager?: InputMaybe<NodeInput>;
  endTime: Scalars['DateTime']['input'];
  shiftPositions: Array<ShiftPositionInput>;
  startTime: Scalars['DateTime']['input'];
};

export type ShiftOrder = {
  endTime?: InputMaybe<Ordering>;
  event?: InputMaybe<EventOrder>;
  interestStatus?: InputMaybe<Ordering>;
  scheduleStatus?: InputMaybe<Ordering>;
  startTime?: InputMaybe<Ordering>;
};

/** Shift(id, start_time, end_time, description, event, duty_manager, created_at) */
export type ShiftPartialInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  dutyManager?: InputMaybe<NodeInput>;
  endTime?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  shiftPositions: Array<ShiftPositionPartialInput>;
  startTime?: InputMaybe<Scalars['DateTime']['input']>;
};

/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionFilter = {
  AND?: InputMaybe<ShiftPositionFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<ShiftPositionFilter>;
  OR?: InputMaybe<ShiftPositionFilter>;
  hideFullyScheduled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  positions?: InputMaybe<Scalars['Int']['input']>;
  shift?: InputMaybe<ShiftFilter>;
};

/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionInput = {
  assignments?: InputMaybe<Array<NodeInput>>;
  positions?: InputMaybe<Scalars['Int']['input']>;
  requiredAccreditations?: InputMaybe<NodeInputPartialListInput>;
  requiredSkillsets?: InputMaybe<NodeInputPartialListInput>;
  skillsetType?: InputMaybe<ShiftSkillsetType>;
};

export type ShiftPositionOrder = {
  id?: InputMaybe<Ordering>;
  interestStatus?: InputMaybe<Ordering>;
  positions?: InputMaybe<Ordering>;
  scheduleStatus?: InputMaybe<Ordering>;
  shift?: InputMaybe<ShiftOrder>;
};

/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionPartialInput = {
  assignments?: InputMaybe<Array<NodeInput>>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  positions?: InputMaybe<Scalars['Int']['input']>;
  requiredAccreditations?: InputMaybe<NodeInputPartialListInput>;
  requiredSkillsets?: InputMaybe<NodeInputPartialListInput>;
  skillsetType?: InputMaybe<ShiftSkillsetType>;
};

/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionType = Node & {
  __typename?: 'ShiftPositionType';
  assignments: Array<ShiftAssignmentType>;
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  interestStatus: EventInterestStatusType;
  positions: Scalars['Int']['output'];
  requiredAccreditations: Array<AccreditationType>;
  requiredSkillsets: Array<SkillsetType>;
  scheduleStatus: EventScheduleStatusType;
  shift: ShiftType;
  skillsetType: ShiftSkillsetType;
  totalAcceptedPositions: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionTypeAssignmentsArgs = {
  filters?: InputMaybe<ShiftAssignmentFilter>;
  order?: InputMaybe<ShiftAssignmentOrder>;
};


/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionTypeRequiredAccreditationsArgs = {
  filters?: InputMaybe<AccreditationFilter>;
  order?: InputMaybe<AccreditationOrder>;
};


/** ShiftPosition(id, shift, skillset_type, positions, created_at) */
export type ShiftPositionTypeRequiredSkillsetsArgs = {
  order?: InputMaybe<SkillsetOrder>;
};

/** A connection to a list of items. */
export type ShiftPositionTypeConnection = {
  __typename?: 'ShiftPositionTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ShiftPositionTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ShiftPositionTypeEdge = {
  __typename?: 'ShiftPositionTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ShiftPositionType;
};

export enum ShiftSkillsetType {
  MinimumSkillset = 'MINIMUM_SKILLSET',
  SkillsetChoices = 'SKILLSET_CHOICES'
}

/** Shift(id, start_time, end_time, description, event, duty_manager, created_at) */
export type ShiftType = Node & {
  __typename?: 'ShiftType';
  assignments: Array<ShiftAssignmentType>;
  createdAt: Scalars['DateTime']['output'];
  description: Scalars['String']['output'];
  dutyManager?: Maybe<ShiftAssignmentType>;
  endTime: Scalars['DateTime']['output'];
  eoiStatus?: Maybe<AssignmentStatus>;
  event: EventType;
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  interestStatus: EventInterestStatusType;
  scheduleStatus: EventScheduleStatusType;
  shiftPositions: Array<ShiftPositionType>;
  startTime: Scalars['DateTime']['output'];
  timeSheet?: Maybe<TimeSheetType>;
  totalAcceptedPositions: Scalars['Int']['output'];
  totalPositions: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Shift(id, start_time, end_time, description, event, duty_manager, created_at) */
export type ShiftTypeAssignmentsArgs = {
  filters?: InputMaybe<ShiftAssignmentFilter>;
  order?: InputMaybe<ShiftAssignmentOrder>;
};


/** Shift(id, start_time, end_time, description, event, duty_manager, created_at) */
export type ShiftTypeShiftPositionsArgs = {
  filters?: InputMaybe<ShiftPositionFilter>;
  order?: InputMaybe<ShiftPositionOrder>;
};

/** A connection to a list of items. */
export type ShiftTypeConnection = {
  __typename?: 'ShiftTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<ShiftTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type ShiftTypeEdge = {
  __typename?: 'ShiftTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: ShiftType;
};

/** Skillset(id, uuid, created_at, updated_at, deleted_at, order, name, code) */
export type SkillsetInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  qualificationRequirements: Array<QualificationRequirementInput>;
};

export type SkillsetOrder = {
  code?: InputMaybe<Ordering>;
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
};

/** Skillset(id, uuid, created_at, updated_at, deleted_at, order, name, code) */
export type SkillsetPartialInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  qualificationRequirements: Array<QualificationRequirementInput>;
};

/** Skillset(id, uuid, created_at, updated_at, deleted_at, order, name, code) */
export type SkillsetType = BaseInterface & BaseReorderableInterface & Node & {
  __typename?: 'SkillsetType';
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  qualificationRequirements: Array<QualificationRequirementType>;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};


/** Skillset(id, uuid, created_at, updated_at, deleted_at, order, name, code) */
export type SkillsetTypeQualificationRequirementsArgs = {
  filters?: InputMaybe<QualificationRequirementFilter>;
};

/** A connection to a list of items. */
export type SkillsetTypeConnection = {
  __typename?: 'SkillsetTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<SkillsetTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type SkillsetTypeEdge = {
  __typename?: 'SkillsetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: SkillsetType;
};

export enum SkinColour {
  Cyanosis = 'CYANOSIS',
  Pale = 'PALE',
  Pink = 'PINK'
}

export type SlackChannelType = {
  __typename?: 'SlackChannelType';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export enum Speech {
  FullSentences = 'FULL_SENTENCES',
  OneWordPhrase = 'ONE_WORD_PHRASE',
  ShortSentences = 'SHORT_SENTENCES',
  WordsNone = 'WORDS_NONE'
}

export enum SpinalLocation {
  CervicalMidline = 'CERVICAL_MIDLINE',
  CervicalSoftTissue = 'CERVICAL_SOFT_TISSUE',
  LumbarMidline = 'LUMBAR_MIDLINE',
  LumbarSoftTissue = 'LUMBAR_SOFT_TISSUE',
  ThoracicMidline = 'THORACIC_MIDLINE',
  ThoracicSoftTissue = 'THORACIC_SOFT_TISSUE'
}

/** State(id, name, code, country) */
export type StateType = {
  __typename?: 'StateType';
  code: Scalars['String']['output'];
  country?: Maybe<CountryType>;
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
};

export type StrFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<Scalars['String']['input']>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<Scalars['String']['input']>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<Scalars['String']['input']>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<Scalars['String']['input']>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<Scalars['String']['input']>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  caseUpdated: CaseType;
  notificationCreated: NotificationCreatedType;
};


export type SubscriptionCaseUpdatedArgs = {
  caseUuid: Scalars['UUID']['input'];
};

/** Tenant(id, schema_name, name, email, phone, website, abn, address, logo_default, logo_dark, logo_email, logo_size_bytes, emp_cover_image, emp_equipment, emp_insurance, emp_terms, post_report_template, email_emp, email_semp, email_eoi_assigned, email_eoi_unsuccessful, email_event_confirmation, email_event_post_report, slack_access_token, slack_team_id, slack_channel, timezone, created_at) */
export type TenantPartialInput = {
  abn?: InputMaybe<Scalars['String']['input']>;
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['String']['input']>;
  emailEmp?: InputMaybe<Scalars['JSON']['input']>;
  emailEoiAssigned?: InputMaybe<Scalars['JSON']['input']>;
  emailEoiUnsuccessful?: InputMaybe<Scalars['JSON']['input']>;
  emailEventConfirmation?: InputMaybe<Scalars['JSON']['input']>;
  emailEventPostReport?: InputMaybe<Scalars['JSON']['input']>;
  emailSemp?: InputMaybe<Scalars['JSON']['input']>;
  empCoverImage?: InputMaybe<Scalars['Upload']['input']>;
  empEquipment?: InputMaybe<Scalars['JSON']['input']>;
  empInsurance?: InputMaybe<Scalars['JSON']['input']>;
  empTerms?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  logoDark?: InputMaybe<Scalars['Upload']['input']>;
  logoDefault?: InputMaybe<Scalars['Upload']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  postReportTemplate?: InputMaybe<Scalars['JSON']['input']>;
  slackChannel?: InputMaybe<Scalars['String']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** Tenant(id, schema_name, name, email, phone, website, abn, address, logo_default, logo_dark, logo_email, logo_size_bytes, emp_cover_image, emp_equipment, emp_insurance, emp_terms, post_report_template, email_emp, email_semp, email_eoi_assigned, email_eoi_unsuccessful, email_event_confirmation, email_event_post_report, slack_access_token, slack_team_id, slack_channel, timezone, created_at) */
export type TenantType = {
  __typename?: 'TenantType';
  abn?: Maybe<Scalars['String']['output']>;
  address?: Maybe<AddressType>;
  email?: Maybe<Scalars['String']['output']>;
  emailEmp?: Maybe<Scalars['JSON']['output']>;
  emailEoiAssigned?: Maybe<Scalars['JSON']['output']>;
  emailEoiUnsuccessful?: Maybe<Scalars['JSON']['output']>;
  emailEventConfirmation?: Maybe<Scalars['JSON']['output']>;
  emailEventPostReport?: Maybe<Scalars['JSON']['output']>;
  emailSemp?: Maybe<Scalars['JSON']['output']>;
  empCoverImage?: Maybe<DjangoFileType>;
  empEquipment?: Maybe<Scalars['JSON']['output']>;
  empInsurance?: Maybe<Scalars['JSON']['output']>;
  empTerms?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['GlobalID']['output'];
  logoDark?: Maybe<DjangoFileType>;
  logoDefault?: Maybe<DjangoFileType>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postReportTemplate?: Maybe<Scalars['JSON']['output']>;
  slackChannel?: Maybe<Scalars['String']['output']>;
  slackIsConnected: Scalars['Boolean']['output'];
  website?: Maybe<Scalars['String']['output']>;
};

/** TextTemplate(id, uuid, created_at, updated_at, deleted_at, name, type, content) */
export type TextTemplateFilter = {
  AND?: InputMaybe<TextTemplateFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<TextTemplateFilter>;
  OR?: InputMaybe<TextTemplateFilter>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  name?: InputMaybe<StrFilterLookup>;
  type?: InputMaybe<TextTemplateKindFilterLookup>;
};

/** TextTemplate(id, uuid, created_at, updated_at, deleted_at, name, type, content) */
export type TextTemplateInput = {
  content: Scalars['JSON']['input'];
  name: Scalars['String']['input'];
  type: TextTemplateKind;
};

export enum TextTemplateKind {
  EventDuties = 'EVENT_DUTIES',
  EventEquipmentInstructions = 'EVENT_EQUIPMENT_INSTRUCTIONS',
  EventStaffBriefing = 'EVENT_STAFF_BRIEFING'
}

export type TextTemplateKindFilterLookup = {
  /** Case-sensitive containment test. Filter will be skipped on `null` value */
  contains?: InputMaybe<TextTemplateKind>;
  /** Case-sensitive ends-with. Filter will be skipped on `null` value */
  endsWith?: InputMaybe<TextTemplateKind>;
  /** Exact match. Filter will be skipped on `null` value */
  exact?: InputMaybe<TextTemplateKind>;
  /** Case-insensitive containment test. Filter will be skipped on `null` value */
  iContains?: InputMaybe<TextTemplateKind>;
  /** Case-insensitive ends-with. Filter will be skipped on `null` value */
  iEndsWith?: InputMaybe<TextTemplateKind>;
  /** Case-insensitive exact match. Filter will be skipped on `null` value */
  iExact?: InputMaybe<TextTemplateKind>;
  /** Case-insensitive regular expression match. Filter will be skipped on `null` value */
  iRegex?: InputMaybe<TextTemplateKind>;
  /** Case-insensitive starts-with. Filter will be skipped on `null` value */
  iStartsWith?: InputMaybe<TextTemplateKind>;
  /** Exact match of items in a given list. Filter will be skipped on `null` value */
  inList?: InputMaybe<Array<TextTemplateKind>>;
  /** Assignment test. Filter will be skipped on `null` value */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Case-sensitive regular expression match. Filter will be skipped on `null` value */
  regex?: InputMaybe<TextTemplateKind>;
  /** Case-sensitive starts-with. Filter will be skipped on `null` value */
  startsWith?: InputMaybe<TextTemplateKind>;
};

export type TextTemplateOrder = {
  createdAt?: InputMaybe<Ordering>;
  name?: InputMaybe<Ordering>;
  type?: InputMaybe<Ordering>;
};

/** TextTemplate(id, uuid, created_at, updated_at, deleted_at, name, type, content) */
export type TextTemplatePartialInput = {
  content?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<TextTemplateKind>;
};

/** TextTemplate(id, uuid, created_at, updated_at, deleted_at, name, type, content) */
export type TextTemplateType = BaseInterface & Node & {
  __typename?: 'TextTemplateType';
  content: Scalars['JSON']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  name: Scalars['String']['output'];
  type: TextTemplateKind;
  updatedAt: Scalars['DateTime']['output'];
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type TextTemplateTypeConnection = {
  __typename?: 'TextTemplateTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<TextTemplateTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type TextTemplateTypeEdge = {
  __typename?: 'TextTemplateTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: TextTemplateType;
};

/** TimeSheet(id, uuid, status, user, shift, date_time_start, date_time_end, notes, created_at, updated_at) */
export type TimeSheetFilter = {
  AND?: InputMaybe<TimeSheetFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<TimeSheetFilter>;
  OR?: InputMaybe<TimeSheetFilter>;
  dateTimeEnd?: InputMaybe<DatetimeDatetimeFilterLookup>;
  dateTimeStart?: InputMaybe<DatetimeDatetimeFilterLookup>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  shift?: InputMaybe<ShiftFilter>;
  user?: InputMaybe<UserFilter>;
};

/** TimeSheet(id, uuid, status, user, shift, date_time_start, date_time_end, notes, created_at, updated_at) */
export type TimeSheetInput = {
  dateTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateTimeStart: Scalars['DateTime']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  shift: NodeInput;
  status?: InputMaybe<TimeSheetStatus>;
  user: NodeInput;
};

export type TimeSheetOrder = {
  createdAt?: InputMaybe<Ordering>;
  dateTimeEnd?: InputMaybe<Ordering>;
  dateTimeStart?: InputMaybe<Ordering>;
  shift?: InputMaybe<ShiftOrder>;
  status?: InputMaybe<Ordering>;
  user?: InputMaybe<UserOrder>;
};

/** TimeSheet(id, uuid, status, user, shift, date_time_start, date_time_end, notes, created_at, updated_at) */
export type TimeSheetPartialInput = {
  dateTimeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateTimeStart?: InputMaybe<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  shift?: InputMaybe<NodeInput>;
  status?: InputMaybe<TimeSheetStatus>;
  user?: InputMaybe<NodeInput>;
};

export enum TimeSheetStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING'
}

/** TimeSheet(id, uuid, status, user, shift, date_time_start, date_time_end, notes, created_at, updated_at) */
export type TimeSheetType = Node & {
  __typename?: 'TimeSheetType';
  createdAt: Scalars['DateTime']['output'];
  dateTimeEnd?: Maybe<Scalars['DateTime']['output']>;
  dateTimeStart: Scalars['DateTime']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  shift: ShiftType;
  status: TimeSheetStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user: UserType;
  uuid: Scalars['UUID']['output'];
};

/** A connection to a list of items. */
export type TimeSheetTypeConnection = {
  __typename?: 'TimeSheetTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<TimeSheetTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type TimeSheetTypeEdge = {
  __typename?: 'TimeSheetTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: TimeSheetType;
};

/**
 *
 * the data that was used to create the token.
 *
 */
export type TokenPayloadType = {
  __typename?: 'TokenPayloadType';
  email: Scalars['String']['output'];
  /** when the token will be expired */
  exp: Scalars['DateTime']['output'];
  is2faAuthenticated: Scalars['Boolean']['output'];
  /** when the token was created */
  origIat: Scalars['DateTime']['output'];
  verificationSid?: Maybe<Scalars['String']['output']>;
};

/**
 *
 * encapsulates the token with the payload that was used to create the token.
 *
 */
export type TokenType = {
  __typename?: 'TokenType';
  payload: TokenPayloadType;
  /** The encoded payload, namely a token. */
  token: Scalars['String']['output'];
};

export type TopCaseCategoryType = {
  __typename?: 'TopCaseCategoryType';
  category: CaseCategoryType;
  numCases: Scalars['Int']['output'];
};

export type TwoFactorProvision = {
  __typename?: 'TwoFactorProvision';
  qrCode: Scalars['String']['output'];
  secret: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type UpdateAccountPayload = OperationInfo | UserType;

export type UpdateAccreditationPayload = AccreditationType | OperationInfo;

export type UpdateAdviceTemplatePayload = AdviceTemplateType | OperationInfo;

export type UpdateCaseCategoryPayload = CaseCategoryType | OperationInfo;

export type UpdateCaseCategorySetPayload = CaseCategorySetType | OperationInfo;

export type UpdateCasePayload = CaseType | OperationInfo;

export type UpdateClientPayload = ClientType | OperationInfo;

export type UpdateConsumablePayload = ConsumableType | OperationInfo;

export type UpdateDrugPayload = DrugType | OperationInfo;

export type UpdateEventKindPayload = EventKindType | OperationInfo;

export type UpdateEventPayload = EventType | OperationInfo;

export type UpdateHospitalPayload = HospitalType | OperationInfo;

export type UpdateMyTimeSheetPayload = OperationInfo | TimeSheetType;

export type UpdateQualificationDocumentPayload = OperationInfo | QualificationDocumentType;

export type UpdateQualificationPayload = OperationInfo | QualificationType;

export type UpdateRegionPayload = OperationInfo | RegionType;

export type UpdateResourceCategoryPayload = OperationInfo | ResourceCategoryType;

export type UpdateResourcePayload = OperationInfo | ResourceType;

export type UpdateSkillsetPayload = OperationInfo | SkillsetType;

export type UpdateTenantPayload = OperationInfo | TenantType;

export type UpdateTextTemplatePayload = OperationInfo | TextTemplateType;

export type UpdateTimeSheetPayload = OperationInfo | TimeSheetType;

export type UpdateUserPayload = OperationInfo | UserType;

export type UpdateWithdrawEoiRequestPayload = OperationInfo | ShiftAssignmentWithdrawRequestType;

/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserFilter = {
  AND?: InputMaybe<UserFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<UserFilter>;
  OR?: InputMaybe<UserFilter>;
  dateJoined?: InputMaybe<DatetimeDatetimeFilterLookup>;
  email?: InputMaybe<StrFilterLookup>;
  firstName?: InputMaybe<StrFilterLookup>;
  fullName?: InputMaybe<StrFilterLookup>;
  id?: InputMaybe<GlobalIdFilterLookup>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: InputMaybe<BoolBaseFilterLookup>;
  isOnline?: InputMaybe<BoolBaseFilterLookup>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: InputMaybe<BoolBaseFilterLookup>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: InputMaybe<BoolBaseFilterLookup>;
  lastLogin?: InputMaybe<DatetimeDatetimeFilterLookup>;
  lastName?: InputMaybe<StrFilterLookup>;
  logentrySet?: InputMaybe<NodeInput>;
  region?: InputMaybe<GlobalIdBaseFilterLookup>;
  skillset?: InputMaybe<GlobalIdBaseFilterLookup>;
  status?: InputMaybe<UserStatusFilter>;
};

/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserInput = {
  accreditations?: InputMaybe<NodeInputPartialListInput>;
  address?: InputMaybe<AddressInput>;
  bypassQualificationRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<NodeInput>;
  sendAccountActivation?: InputMaybe<Scalars['Boolean']['input']>;
  skillset?: InputMaybe<NodeInput>;
};

export type UserOrder = {
  dateJoined?: InputMaybe<Ordering>;
  email?: InputMaybe<Ordering>;
  firstName?: InputMaybe<Ordering>;
  isActive?: InputMaybe<Ordering>;
  lastLogin?: InputMaybe<Ordering>;
  lastName?: InputMaybe<Ordering>;
  skillset?: InputMaybe<SkillsetOrder>;
};

/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserPartialInput = {
  accreditations?: InputMaybe<NodeInputPartialListInput>;
  address?: InputMaybe<AddressInput>;
  bypassQualificationRequirements?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['GlobalID']['input']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  permissions: Array<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  region?: InputMaybe<NodeInput>;
  sendAccountActivation?: InputMaybe<Scalars['Boolean']['input']>;
  skillset?: InputMaybe<NodeInput>;
};

/** A helper model that handles user account stuff. */
export type UserStatusFilter = {
  AND?: InputMaybe<UserStatusFilter>;
  DISTINCT?: InputMaybe<Scalars['Boolean']['input']>;
  NOT?: InputMaybe<UserStatusFilter>;
  OR?: InputMaybe<UserStatusFilter>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  verified?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A helper model that handles user account stuff. */
export type UserStatusType = {
  __typename?: 'UserStatusType';
  archived: Scalars['Boolean']['output'];
  verified: Scalars['Boolean']['output'];
};

/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserType = Node & {
  __typename?: 'UserType';
  accreditations: Array<AccreditationType>;
  address?: Maybe<AddressType>;
  archived: Scalars['Boolean']['output'];
  bypassQualificationRequirements: Scalars['Boolean']['output'];
  canRegisterInterest: Scalars['Boolean']['output'];
  dateJoined: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  hasUsablePassword: Scalars['Boolean']['output'];
  /** The Globally Unique ID of this object */
  id: Scalars['GlobalID']['output'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean']['output'];
  isOnline: Scalars['Boolean']['output'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean']['output'];
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean']['output'];
  lastLogin?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  logentrySet: Array<Node>;
  permissions: Array<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  qualificationDocuments: Array<QualificationDocumentType>;
  region?: Maybe<RegionType>;
  shiftAssignments: Array<ShiftAssignmentType>;
  shiftsL1m: Scalars['Int']['output'];
  shiftsL3m: Scalars['Int']['output'];
  shiftsL6m: Scalars['Int']['output'];
  skillset?: Maybe<SkillsetType>;
  status: UserStatusType;
  twoFactorEnabled: Scalars['Boolean']['output'];
  uuid: Scalars['UUID']['output'];
  verified: Scalars['Boolean']['output'];
};


/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserTypeAccreditationsArgs = {
  filters?: InputMaybe<AccreditationFilter>;
  order?: InputMaybe<AccreditationOrder>;
};


/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserTypeQualificationDocumentsArgs = {
  filters?: InputMaybe<QualificationDocumentFilter>;
  order?: InputMaybe<QualificationDocumentOrder>;
};


/** User(id, created_at, updated_at, deleted_at, password, last_login, is_superuser, first_name, last_name, is_staff, is_active, date_joined, email, phone, address, uuid, skillset, region, two_factor_secret, bypass_qualification_requirements, is_online, legacy_id) */
export type UserTypeShiftAssignmentsArgs = {
  filters?: InputMaybe<ShiftAssignmentFilter>;
  order?: InputMaybe<ShiftAssignmentOrder>;
};

/** A connection to a list of items. */
export type UserTypeConnection = {
  __typename?: 'UserTypeConnection';
  /** Contains the nodes in this connection */
  edges: Array<UserTypeEdge>;
  /** Pagination data for this connection */
  pageInfo: PageInfo;
  /** Total quantity of existing nodes. */
  totalCount?: Maybe<Scalars['Int']['output']>;
};

/** An edge in a connection. */
export type UserTypeEdge = {
  __typename?: 'UserTypeEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String']['output'];
  /** The item at the end of the edge */
  node: UserType;
};

export enum ValidationType {
  All = 'ALL',
  Any = 'ANY'
}

export type VerifyTokenType = OutputInterface & {
  __typename?: 'VerifyTokenType';
  errors?: Maybe<Scalars['ExpectedError']['output']>;
  success: Scalars['Boolean']['output'];
  token?: Maybe<TokenType>;
  user?: Maybe<UserType>;
};

export enum WithdrawRequestStatus {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<RefType extends Record<string, unknown>> = {
  CreateAccreditationPayload: ( AccreditationType ) | ( OperationInfo );
  CreateAdviceTemplatePayload: ( AdviceTemplateType ) | ( OperationInfo );
  CreateCaseAuditPayload: ( CaseAuditType ) | ( OperationInfo );
  CreateCaseCategoryPayload: ( CaseCategoryType ) | ( OperationInfo );
  CreateCaseCategorySetPayload: ( CaseCategorySetType ) | ( OperationInfo );
  CreateCasePayload: ( CaseType ) | ( OperationInfo );
  CreateClientPayload: ( ClientType ) | ( OperationInfo );
  CreateConsumablePayload: ( ConsumableType ) | ( OperationInfo );
  CreateDeleteQualificationAuditPayload: ( OperationInfo ) | ( QualificationDocumentAuditType );
  CreateDrugPayload: ( DrugType ) | ( OperationInfo );
  CreateEventKindPayload: ( EventKindType ) | ( OperationInfo );
  CreateEventPayload: ( EventType ) | ( OperationInfo );
  CreateFileNodePayload: ( FileNodeType ) | ( OperationInfo );
  CreateHospitalPayload: ( HospitalType ) | ( OperationInfo );
  CreateMyTimeSheetPayload: ( OperationInfo ) | ( TimeSheetType );
  CreateQualificationDocumentPayload: ( OperationInfo ) | ( QualificationDocumentType );
  CreateQualificationPayload: ( OperationInfo ) | ( QualificationType );
  CreateRegionPayload: ( OperationInfo ) | ( RegionType );
  CreateResourceCategoryPayload: ( OperationInfo ) | ( ResourceCategoryType );
  CreateResourcePayload: ( OperationInfo ) | ( ResourceType );
  CreateSkillsetPayload: ( OperationInfo ) | ( SkillsetType );
  CreateTextTemplatePayload: ( OperationInfo ) | ( TextTemplateType );
  CreateTimeSheetPayload: ( OperationInfo ) | ( TimeSheetType );
  CreateUserPayload: ( OperationInfo ) | ( UserType );
  CreateWithdrawEoiRequestPayload: ( OperationInfo ) | ( ShiftAssignmentWithdrawRequestType );
  DeleteAccreditationPayload: ( AccreditationType ) | ( OperationInfo );
  DeleteAdviceTemplatePayload: ( AdviceTemplateType ) | ( OperationInfo );
  DeleteCaseCategoryPayload: ( CaseCategoryType ) | ( OperationInfo );
  DeleteCaseCategorySetPayload: ( CaseCategorySetType ) | ( OperationInfo );
  DeleteCasePayload: ( CaseType ) | ( OperationInfo );
  DeleteClientPayload: ( ClientType ) | ( OperationInfo );
  DeleteConsumablePayload: ( ConsumableType ) | ( OperationInfo );
  DeleteDrugPayload: ( DrugType ) | ( OperationInfo );
  DeleteEventKindPayload: ( EventKindType ) | ( OperationInfo );
  DeleteEventPayload: ( EventType ) | ( OperationInfo );
  DeleteFileNodePayload: ( FileNodeType ) | ( OperationInfo );
  DeleteHospitalPayload: ( HospitalType ) | ( OperationInfo );
  DeleteQualificationDocumentPayload: ( OperationInfo ) | ( QualificationDocumentType );
  DeleteQualificationPayload: ( OperationInfo ) | ( QualificationType );
  DeleteRegionPayload: ( OperationInfo ) | ( RegionType );
  DeleteResourceCategoryPayload: ( OperationInfo ) | ( ResourceCategoryType );
  DeleteResourcePayload: ( OperationInfo ) | ( ResourceType );
  DeleteSkillsetPayload: ( OperationInfo ) | ( SkillsetType );
  DeleteTextTemplatePayload: ( OperationInfo ) | ( TextTemplateType );
  DeleteTimeSheetPayload: ( OperationInfo ) | ( TimeSheetType );
  DeleteUserPayload: ( OperationInfo ) | ( UserType );
  UpdateAccountPayload: ( OperationInfo ) | ( UserType );
  UpdateAccreditationPayload: ( AccreditationType ) | ( OperationInfo );
  UpdateAdviceTemplatePayload: ( AdviceTemplateType ) | ( OperationInfo );
  UpdateCaseCategoryPayload: ( CaseCategoryType ) | ( OperationInfo );
  UpdateCaseCategorySetPayload: ( CaseCategorySetType ) | ( OperationInfo );
  UpdateCasePayload: ( CaseType ) | ( OperationInfo );
  UpdateClientPayload: ( ClientType ) | ( OperationInfo );
  UpdateConsumablePayload: ( ConsumableType ) | ( OperationInfo );
  UpdateDrugPayload: ( DrugType ) | ( OperationInfo );
  UpdateEventKindPayload: ( EventKindType ) | ( OperationInfo );
  UpdateEventPayload: ( EventType ) | ( OperationInfo );
  UpdateHospitalPayload: ( HospitalType ) | ( OperationInfo );
  UpdateMyTimeSheetPayload: ( OperationInfo ) | ( TimeSheetType );
  UpdateQualificationDocumentPayload: ( OperationInfo ) | ( QualificationDocumentType );
  UpdateQualificationPayload: ( OperationInfo ) | ( QualificationType );
  UpdateRegionPayload: ( OperationInfo ) | ( RegionType );
  UpdateResourceCategoryPayload: ( OperationInfo ) | ( ResourceCategoryType );
  UpdateResourcePayload: ( OperationInfo ) | ( ResourceType );
  UpdateSkillsetPayload: ( OperationInfo ) | ( SkillsetType );
  UpdateTenantPayload: ( OperationInfo ) | ( TenantType );
  UpdateTextTemplatePayload: ( OperationInfo ) | ( TextTemplateType );
  UpdateTimeSheetPayload: ( OperationInfo ) | ( TimeSheetType );
  UpdateUserPayload: ( OperationInfo ) | ( UserType );
  UpdateWithdrawEoiRequestPayload: ( OperationInfo ) | ( ShiftAssignmentWithdrawRequestType );
};

/** Mapping of interface types */
export type ResolversInterfaceTypes<RefType extends Record<string, unknown>> = {
  BaseInterface: ( AdviceTemplateType ) | ( CaseAuditType ) | ( CaseCategorySetType ) | ( CaseCategoryType ) | ( CaseSketchType ) | ( CaseType ) | ( ClientType ) | ( ConsumableType ) | ( ContactType ) | ( DrugType ) | ( EventKindType ) | ( EventType ) | ( HospitalType ) | ( PatientType ) | ( QualificationDocumentAuditType ) | ( QualificationDocumentType ) | ( QualificationType ) | ( ResourceCategoryType ) | ( ResourceType ) | ( SkillsetType ) | ( TextTemplateType );
  BaseReorderableInterface: ( ResourceCategoryType ) | ( ResourceType ) | ( SkillsetType );
  Node: ( AccreditationType ) | ( AdviceTemplateType ) | ( CaseAttachmentType ) | ( CaseAttendantType ) | ( CaseAuditType ) | ( CaseAxType ) | ( CaseCategorySetType ) | ( CaseCategoryType ) | ( CaseConsumableType ) | ( CaseCustomAxType ) | ( CaseEcgAxType ) | ( CaseGcsAxType ) | ( CaseMentalAxType ) | ( CaseNeurovascularAxType ) | ( CasePainAxType ) | ( CasePatientPmHxAllergyType ) | ( CasePatientPmHxHistoryType ) | ( CasePatientPmHxMedicationType ) | ( CasePrimaryAxType ) | ( CaseRosierAxType ) | ( CaseRespiratoryAxType ) | ( CaseRxAirwayType ) | ( CaseRxBreathingVentilationType ) | ( CaseRxCprType ) | ( CaseRxConsultType ) | ( CaseRxDefibrillationType ) | ( CaseRxFirstAidType ) | ( CaseRxIvAccessType ) | ( CaseRxMajorTraumaType ) | ( CaseRxMedicationsType ) | ( CaseRxOtherType ) | ( CaseRxOxygenType ) | ( CaseSketchType ) | ( CaseSpinalAxType ) | ( CaseTreatmentType ) | ( CaseType ) | ( CaseVitalsAxType ) | ( ClientType ) | ( ConsumableType ) | ( ContactType ) | ( DrugBrandNameType ) | ( DrugContraindicationType ) | ( DrugIndicationType ) | ( DrugPrecautionType ) | ( DrugSideEffectType ) | ( DrugType ) | ( EventKindType ) | ( EventType ) | ( FileNodeType ) | ( HospitalType ) | ( NotificationType ) | ( PatientType ) | ( QualificationDocumentAuditType ) | ( QualificationDocumentType ) | ( QualificationRequirementType ) | ( QualificationType ) | ( RegionType ) | ( ResourceAllocationType ) | ( ResourceCategoryType ) | ( ResourceType ) | ( ShiftAssignmentType ) | ( ShiftAssignmentWithdrawRequestType ) | ( ShiftPositionType ) | ( ShiftType ) | ( SkillsetType ) | ( TextTemplateType ) | ( TimeSheetType ) | ( UserType );
  OutputInterface: ( ObtainJsonWebTokenType ) | ( VerifyTokenType );
};

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountPartialInput: AccountPartialInput;
  AccreditationFilter: AccreditationFilter;
  AccreditationInput: AccreditationInput;
  AccreditationOrder: AccreditationOrder;
  AccreditationPartialInput: AccreditationPartialInput;
  AccreditationType: ResolverTypeWrapper<AccreditationType>;
  AccreditationTypeConnection: ResolverTypeWrapper<AccreditationTypeConnection>;
  AccreditationTypeEdge: ResolverTypeWrapper<AccreditationTypeEdge>;
  AddressInput: AddressInput;
  AddressType: ResolverTypeWrapper<AddressType>;
  AdviceTemplateFilter: AdviceTemplateFilter;
  AdviceTemplateInput: AdviceTemplateInput;
  AdviceTemplateOrder: AdviceTemplateOrder;
  AdviceTemplatePartialInput: AdviceTemplatePartialInput;
  AdviceTemplateType: ResolverTypeWrapper<AdviceTemplateType>;
  AdviceTemplateTypeConnection: ResolverTypeWrapper<AdviceTemplateTypeConnection>;
  AdviceTemplateTypeEdge: ResolverTypeWrapper<AdviceTemplateTypeEdge>;
  Airway: Airway;
  AppPermissions: ResolverTypeWrapper<AppPermissions>;
  Appearance: Appearance;
  AssignmentStatus: AssignmentStatus;
  AxType: AxType;
  BaseInterface: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BaseInterface']>;
  BaseReorderableInterface: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['BaseReorderableInterface']>;
  BoolBaseFilterLookup: BoolBaseFilterLookup;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Breathing: Breathing;
  CapRefill: CapRefill;
  CaseAttachmentInput: CaseAttachmentInput;
  CaseAttachmentPartialInput: CaseAttachmentPartialInput;
  CaseAttachmentType: ResolverTypeWrapper<CaseAttachmentType>;
  CaseAttendantInput: CaseAttendantInput;
  CaseAttendantPartialInput: CaseAttendantPartialInput;
  CaseAttendantRole: CaseAttendantRole;
  CaseAttendantType: ResolverTypeWrapper<CaseAttendantType>;
  CaseAuditInput: CaseAuditInput;
  CaseAuditOrder: CaseAuditOrder;
  CaseAuditOutcome: CaseAuditOutcome;
  CaseAuditType: ResolverTypeWrapper<CaseAuditType>;
  CaseAxInput: CaseAxInput;
  CaseAxPartialInput: CaseAxPartialInput;
  CaseAxType: ResolverTypeWrapper<CaseAxType>;
  CaseCategoryFilter: CaseCategoryFilter;
  CaseCategoryInput: CaseCategoryInput;
  CaseCategoryOrder: CaseCategoryOrder;
  CaseCategoryPartialInput: CaseCategoryPartialInput;
  CaseCategorySetFilter: CaseCategorySetFilter;
  CaseCategorySetInput: CaseCategorySetInput;
  CaseCategorySetOrder: CaseCategorySetOrder;
  CaseCategorySetPartialInput: CaseCategorySetPartialInput;
  CaseCategorySetType: ResolverTypeWrapper<CaseCategorySetType>;
  CaseCategorySetTypeConnection: ResolverTypeWrapper<CaseCategorySetTypeConnection>;
  CaseCategorySetTypeEdge: ResolverTypeWrapper<CaseCategorySetTypeEdge>;
  CaseCategoryType: ResolverTypeWrapper<CaseCategoryType>;
  CaseCategoryTypeConnection: ResolverTypeWrapper<CaseCategoryTypeConnection>;
  CaseCategoryTypeEdge: ResolverTypeWrapper<CaseCategoryTypeEdge>;
  CaseConsumableInput: CaseConsumableInput;
  CaseConsumablePartialInput: CaseConsumablePartialInput;
  CaseConsumableType: ResolverTypeWrapper<CaseConsumableType>;
  CaseCustomAxInput: CaseCustomAxInput;
  CaseCustomAxPartialInput: CaseCustomAxPartialInput;
  CaseCustomAxType: ResolverTypeWrapper<CaseCustomAxType>;
  CaseECGAxInput: CaseEcgAxInput;
  CaseECGAxPartialInput: CaseEcgAxPartialInput;
  CaseECGAxType: ResolverTypeWrapper<CaseEcgAxType>;
  CaseFilter: CaseFilter;
  CaseGCSAxInput: CaseGcsAxInput;
  CaseGCSAxPartialInput: CaseGcsAxPartialInput;
  CaseGCSAxType: ResolverTypeWrapper<CaseGcsAxType>;
  CaseInput: CaseInput;
  CaseMentalAxInput: CaseMentalAxInput;
  CaseMentalAxPartialInput: CaseMentalAxPartialInput;
  CaseMentalAxType: ResolverTypeWrapper<CaseMentalAxType>;
  CaseNeurovascularAxInput: CaseNeurovascularAxInput;
  CaseNeurovascularAxPartialInput: CaseNeurovascularAxPartialInput;
  CaseNeurovascularAxType: ResolverTypeWrapper<CaseNeurovascularAxType>;
  CaseOrder: CaseOrder;
  CasePainAxInput: CasePainAxInput;
  CasePainAxPartialInput: CasePainAxPartialInput;
  CasePainAxType: ResolverTypeWrapper<CasePainAxType>;
  CasePartialInput: CasePartialInput;
  CasePatientPMHxAllergyInput: CasePatientPmHxAllergyInput;
  CasePatientPMHxAllergyPartialInput: CasePatientPmHxAllergyPartialInput;
  CasePatientPMHxAllergyType: ResolverTypeWrapper<CasePatientPmHxAllergyType>;
  CasePatientPMHxHistoryInput: CasePatientPmHxHistoryInput;
  CasePatientPMHxHistoryPartialInput: CasePatientPmHxHistoryPartialInput;
  CasePatientPMHxHistoryType: ResolverTypeWrapper<CasePatientPmHxHistoryType>;
  CasePatientPMHxMedicationInput: CasePatientPmHxMedicationInput;
  CasePatientPMHxMedicationPartialInput: CasePatientPmHxMedicationPartialInput;
  CasePatientPMHxMedicationType: ResolverTypeWrapper<CasePatientPmHxMedicationType>;
  CasePrimaryAxInput: CasePrimaryAxInput;
  CasePrimaryAxPartialInput: CasePrimaryAxPartialInput;
  CasePrimaryAxType: ResolverTypeWrapper<CasePrimaryAxType>;
  CasePriority: CasePriority;
  CasePriorityFilterLookup: CasePriorityFilterLookup;
  CaseROSIERAxInput: CaseRosierAxInput;
  CaseROSIERAxPartialInput: CaseRosierAxPartialInput;
  CaseROSIERAxType: ResolverTypeWrapper<CaseRosierAxType>;
  CaseRespiratoryAxInput: CaseRespiratoryAxInput;
  CaseRespiratoryAxPartialInput: CaseRespiratoryAxPartialInput;
  CaseRespiratoryAxType: ResolverTypeWrapper<CaseRespiratoryAxType>;
  CaseRxAirwayInput: CaseRxAirwayInput;
  CaseRxAirwayPartialInput: CaseRxAirwayPartialInput;
  CaseRxAirwayType: ResolverTypeWrapper<CaseRxAirwayType>;
  CaseRxBreathingVentilationInput: CaseRxBreathingVentilationInput;
  CaseRxBreathingVentilationPartialInput: CaseRxBreathingVentilationPartialInput;
  CaseRxBreathingVentilationType: ResolverTypeWrapper<CaseRxBreathingVentilationType>;
  CaseRxCPRInput: CaseRxCprInput;
  CaseRxCPRPartialInput: CaseRxCprPartialInput;
  CaseRxCPRType: ResolverTypeWrapper<CaseRxCprType>;
  CaseRxConsultInput: CaseRxConsultInput;
  CaseRxConsultPartialInput: CaseRxConsultPartialInput;
  CaseRxConsultType: ResolverTypeWrapper<CaseRxConsultType>;
  CaseRxDefibrillationInput: CaseRxDefibrillationInput;
  CaseRxDefibrillationPartialInput: CaseRxDefibrillationPartialInput;
  CaseRxDefibrillationType: ResolverTypeWrapper<CaseRxDefibrillationType>;
  CaseRxFirstAidInput: CaseRxFirstAidInput;
  CaseRxFirstAidPartialInput: CaseRxFirstAidPartialInput;
  CaseRxFirstAidType: ResolverTypeWrapper<CaseRxFirstAidType>;
  CaseRxIVAccessInput: CaseRxIvAccessInput;
  CaseRxIVAccessPartialInput: CaseRxIvAccessPartialInput;
  CaseRxIVAccessType: ResolverTypeWrapper<CaseRxIvAccessType>;
  CaseRxMajorTraumaInput: CaseRxMajorTraumaInput;
  CaseRxMajorTraumaPartialInput: CaseRxMajorTraumaPartialInput;
  CaseRxMajorTraumaType: ResolverTypeWrapper<CaseRxMajorTraumaType>;
  CaseRxMedicationsInput: CaseRxMedicationsInput;
  CaseRxMedicationsPartialInput: CaseRxMedicationsPartialInput;
  CaseRxMedicationsType: ResolverTypeWrapper<CaseRxMedicationsType>;
  CaseRxOtherInput: CaseRxOtherInput;
  CaseRxOtherPartialInput: CaseRxOtherPartialInput;
  CaseRxOtherType: ResolverTypeWrapper<CaseRxOtherType>;
  CaseRxOxygenInput: CaseRxOxygenInput;
  CaseRxOxygenPartialInput: CaseRxOxygenPartialInput;
  CaseRxOxygenType: ResolverTypeWrapper<CaseRxOxygenType>;
  CaseSketchInput: CaseSketchInput;
  CaseSketchPartialInput: CaseSketchPartialInput;
  CaseSketchType: ResolverTypeWrapper<CaseSketchType>;
  CaseSpinalAxInput: CaseSpinalAxInput;
  CaseSpinalAxPartialInput: CaseSpinalAxPartialInput;
  CaseSpinalAxType: ResolverTypeWrapper<CaseSpinalAxType>;
  CaseTreatmentInput: CaseTreatmentInput;
  CaseTreatmentPartialInput: CaseTreatmentPartialInput;
  CaseTreatmentType: ResolverTypeWrapper<CaseTreatmentType>;
  CaseType: ResolverTypeWrapper<CaseType>;
  CaseTypeConnection: ResolverTypeWrapper<CaseTypeConnection>;
  CaseTypeEdge: ResolverTypeWrapper<CaseTypeEdge>;
  CaseVitalsAxInput: CaseVitalsAxInput;
  CaseVitalsAxPartialInput: CaseVitalsAxPartialInput;
  CaseVitalsAxType: ResolverTypeWrapper<CaseVitalsAxType>;
  Circulation: Circulation;
  ClientFilter: ClientFilter;
  ClientInput: ClientInput;
  ClientOrder: ClientOrder;
  ClientPartialInput: ClientPartialInput;
  ClientType: ResolverTypeWrapper<ClientType>;
  ClientTypeConnection: ResolverTypeWrapper<ClientTypeConnection>;
  ClientTypeEdge: ResolverTypeWrapper<ClientTypeEdge>;
  ConsumableFilter: ConsumableFilter;
  ConsumableInput: ConsumableInput;
  ConsumableOrder: ConsumableOrder;
  ConsumablePartialInput: ConsumablePartialInput;
  ConsumableType: ResolverTypeWrapper<ConsumableType>;
  ConsumableTypeConnection: ResolverTypeWrapper<ConsumableTypeConnection>;
  ConsumableTypeEdge: ResolverTypeWrapper<ConsumableTypeEdge>;
  ContactInput: ContactInput;
  ContactPartialInput: ContactPartialInput;
  ContactType: ResolverTypeWrapper<ContactType>;
  CountryType: ResolverTypeWrapper<CountryType>;
  CreateAccreditationPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateAccreditationPayload']>;
  CreateAdviceTemplatePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateAdviceTemplatePayload']>;
  CreateCaseAuditPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateCaseAuditPayload']>;
  CreateCaseCategoryPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateCaseCategoryPayload']>;
  CreateCaseCategorySetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateCaseCategorySetPayload']>;
  CreateCasePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateCasePayload']>;
  CreateClientPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateClientPayload']>;
  CreateConsumablePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateConsumablePayload']>;
  CreateDeleteQualificationAuditPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateDeleteQualificationAuditPayload']>;
  CreateDrugPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateDrugPayload']>;
  CreateEventKindPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateEventKindPayload']>;
  CreateEventPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateEventPayload']>;
  CreateFileNodePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateFileNodePayload']>;
  CreateHospitalPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateHospitalPayload']>;
  CreateMyTimeSheetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateMyTimeSheetPayload']>;
  CreateQualificationDocumentPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateQualificationDocumentPayload']>;
  CreateQualificationPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateQualificationPayload']>;
  CreateRegionPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateRegionPayload']>;
  CreateResourceCategoryPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateResourceCategoryPayload']>;
  CreateResourcePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateResourcePayload']>;
  CreateSkillsetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateSkillsetPayload']>;
  CreateTextTemplatePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateTextTemplatePayload']>;
  CreateTimeSheetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateTimeSheetPayload']>;
  CreateUserPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateUserPayload']>;
  CreateWithdrawEoiRequestPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['CreateWithdrawEoiRequestPayload']>;
  Dangers: Dangers;
  DatasetsType: ResolverTypeWrapper<DatasetsType>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DatetimeDatetimeFilterLookup: DatetimeDatetimeFilterLookup;
  DatetimeFilterLookup: DatetimeFilterLookup;
  DatetimeRangeLookup: DatetimeRangeLookup;
  Decimal: ResolverTypeWrapper<Scalars['Decimal']['output']>;
  DecimalComparisonFilterLookup: DecimalComparisonFilterLookup;
  DecimalRangeLookup: DecimalRangeLookup;
  Defibrillation: Defibrillation;
  DeleteAccreditationPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteAccreditationPayload']>;
  DeleteAdviceTemplatePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteAdviceTemplatePayload']>;
  DeleteCaseCategoryPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteCaseCategoryPayload']>;
  DeleteCaseCategorySetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteCaseCategorySetPayload']>;
  DeleteCasePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteCasePayload']>;
  DeleteClientPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteClientPayload']>;
  DeleteConsumablePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteConsumablePayload']>;
  DeleteDrugPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteDrugPayload']>;
  DeleteEventKindPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteEventKindPayload']>;
  DeleteEventPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteEventPayload']>;
  DeleteFileNodePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteFileNodePayload']>;
  DeleteHospitalPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteHospitalPayload']>;
  DeleteQualificationDocumentPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteQualificationDocumentPayload']>;
  DeleteQualificationPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteQualificationPayload']>;
  DeleteRegionPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteRegionPayload']>;
  DeleteResourceCategoryPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteResourceCategoryPayload']>;
  DeleteResourcePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteResourcePayload']>;
  DeleteSkillsetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteSkillsetPayload']>;
  DeleteTextTemplatePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteTextTemplatePayload']>;
  DeleteTimeSheetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteTimeSheetPayload']>;
  DeleteUserPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['DeleteUserPayload']>;
  DjangoFileType: ResolverTypeWrapper<DjangoFileType>;
  DrugBrandNamePartialInput: DrugBrandNamePartialInput;
  DrugBrandNameType: ResolverTypeWrapper<DrugBrandNameType>;
  DrugContraindicationPartialInput: DrugContraindicationPartialInput;
  DrugContraindicationType: ResolverTypeWrapper<DrugContraindicationType>;
  DrugFilter: DrugFilter;
  DrugIndicationPartialInput: DrugIndicationPartialInput;
  DrugIndicationType: ResolverTypeWrapper<DrugIndicationType>;
  DrugInput: DrugInput;
  DrugOrder: DrugOrder;
  DrugPartialInput: DrugPartialInput;
  DrugPrecautionPartialInput: DrugPrecautionPartialInput;
  DrugPrecautionType: ResolverTypeWrapper<DrugPrecautionType>;
  DrugSideEffectPartialInput: DrugSideEffectPartialInput;
  DrugSideEffectType: ResolverTypeWrapper<DrugSideEffectType>;
  DrugType: ResolverTypeWrapper<DrugType>;
  DrugTypeConnection: ResolverTypeWrapper<DrugTypeConnection>;
  DrugTypeEdge: ResolverTypeWrapper<DrugTypeEdge>;
  EventConsumableType: ResolverTypeWrapper<EventConsumableType>;
  EventConsumablesType: ResolverTypeWrapper<EventConsumablesType>;
  EventFilter: EventFilter;
  EventInput: EventInput;
  EventInterestStatus: EventInterestStatus;
  EventInterestStatusType: ResolverTypeWrapper<EventInterestStatusType>;
  EventKindFilter: EventKindFilter;
  EventKindInput: EventKindInput;
  EventKindOrder: EventKindOrder;
  EventKindPartialInput: EventKindPartialInput;
  EventKindType: ResolverTypeWrapper<EventKindType>;
  EventKindTypeConnection: ResolverTypeWrapper<EventKindTypeConnection>;
  EventKindTypeEdge: ResolverTypeWrapper<EventKindTypeEdge>;
  EventOrder: EventOrder;
  EventPartialInput: EventPartialInput;
  EventScheduleStatus: EventScheduleStatus;
  EventScheduleStatusType: ResolverTypeWrapper<EventScheduleStatusType>;
  EventStatus: EventStatus;
  EventStatusFilterLookup: EventStatusFilterLookup;
  EventType: ResolverTypeWrapper<EventType>;
  EventTypeConnection: ResolverTypeWrapper<EventTypeConnection>;
  EventTypeEdge: ResolverTypeWrapper<EventTypeEdge>;
  ExpectedError: ResolverTypeWrapper<Scalars['ExpectedError']['output']>;
  ExtremityTemp: ExtremityTemp;
  FileNodeFilter: FileNodeFilter;
  FileNodeInput: FileNodeInput;
  FileNodeKind: FileNodeKind;
  FileNodeType: ResolverTypeWrapper<FileNodeType>;
  FileNodeTypeConnection: ResolverTypeWrapper<FileNodeTypeConnection>;
  FileNodeTypeEdge: ResolverTypeWrapper<FileNodeTypeEdge>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  GCSEyeResponse: GcsEyeResponse;
  GCSMotorResponse: GcsMotorResponse;
  GCSVerbalResponse: GcsVerbalResponse;
  GlobalID: ResolverTypeWrapper<Scalars['GlobalID']['output']>;
  GlobalIDBaseFilterLookup: GlobalIdBaseFilterLookup;
  GlobalIDFilterLookup: GlobalIdFilterLookup;
  GraphDataType: ResolverTypeWrapper<GraphDataType>;
  Haemorrhage: Haemorrhage;
  HospitalFilter: HospitalFilter;
  HospitalInput: HospitalInput;
  HospitalOrder: HospitalOrder;
  HospitalPartialInput: HospitalPartialInput;
  HospitalType: ResolverTypeWrapper<HospitalType>;
  HospitalTypeConnection: ResolverTypeWrapper<HospitalTypeConnection>;
  HospitalTypeEdge: ResolverTypeWrapper<HospitalTypeEdge>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  IntComparisonFilterLookup: IntComparisonFilterLookup;
  IntRangeLookup: IntRangeLookup;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  LocalityType: ResolverTypeWrapper<LocalityType>;
  MHAffect: MhAffect;
  MHAppearance: MhAppearance;
  MHBehaviour: MhBehaviour;
  MHDelusions: MhDelusions;
  MHEyes: MhEyes;
  MHHallucinations: MhHallucinations;
  MHHomicidality: MhHomicidality;
  MHMemory: MhMemory;
  MHMood: MhMood;
  MHMotorActivity: MhMotorActivity;
  MHSpeech: MhSpeech;
  MHSuicidality: MhSuicidality;
  Movement: Movement;
  Mutation: ResolverTypeWrapper<{}>;
  MutationNormalOutput: ResolverTypeWrapper<MutationNormalOutput>;
  MyTimeSheetInput: MyTimeSheetInput;
  MyTimeSheetPartialInput: MyTimeSheetPartialInput;
  Node: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Node']>;
  NodeInput: NodeInput;
  NodeInputPartial: NodeInputPartial;
  NodeInputPartialListInput: NodeInputPartialListInput;
  NotificationCreatedType: ResolverTypeWrapper<NotificationCreatedType>;
  NotificationFilter: NotificationFilter;
  NotificationResponseType: ResolverTypeWrapper<NotificationResponseType>;
  NotificationType: ResolverTypeWrapper<NotificationType>;
  NotificationTypeConnection: ResolverTypeWrapper<NotificationTypeConnection>;
  NotificationTypeEdge: ResolverTypeWrapper<NotificationTypeEdge>;
  ObtainJSONWebTokenType: ResolverTypeWrapper<ObtainJsonWebTokenType>;
  OffsetPaginationInput: OffsetPaginationInput;
  OperationInfo: ResolverTypeWrapper<OperationInfo>;
  OperationMessage: ResolverTypeWrapper<OperationMessage>;
  OperationMessageKind: OperationMessageKind;
  Ordering: Ordering;
  OutputInterface: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['OutputInterface']>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PatientGender: PatientGender;
  PatientInput: PatientInput;
  PatientPartialInput: PatientPartialInput;
  PatientType: ResolverTypeWrapper<PatientType>;
  PeripheralPulse: PeripheralPulse;
  Permission: ResolverTypeWrapper<Permission>;
  PermissionGroup: ResolverTypeWrapper<PermissionGroup>;
  PulseLocation: PulseLocation;
  PulseRegularity: PulseRegularity;
  Pupils: Pupils;
  QualificationDocumentAuditInput: QualificationDocumentAuditInput;
  QualificationDocumentAuditOrder: QualificationDocumentAuditOrder;
  QualificationDocumentAuditOutcome: QualificationDocumentAuditOutcome;
  QualificationDocumentAuditType: ResolverTypeWrapper<QualificationDocumentAuditType>;
  QualificationDocumentExpiryStatus: QualificationDocumentExpiryStatus;
  QualificationDocumentFilter: QualificationDocumentFilter;
  QualificationDocumentInput: QualificationDocumentInput;
  QualificationDocumentOrder: QualificationDocumentOrder;
  QualificationDocumentPartialInput: QualificationDocumentPartialInput;
  QualificationDocumentStatus: QualificationDocumentStatus;
  QualificationDocumentStatusFilterLookup: QualificationDocumentStatusFilterLookup;
  QualificationDocumentType: ResolverTypeWrapper<QualificationDocumentType>;
  QualificationDocumentTypeConnection: ResolverTypeWrapper<QualificationDocumentTypeConnection>;
  QualificationDocumentTypeEdge: ResolverTypeWrapper<QualificationDocumentTypeEdge>;
  QualificationExpiryType: QualificationExpiryType;
  QualificationFilter: QualificationFilter;
  QualificationInput: QualificationInput;
  QualificationOrder: QualificationOrder;
  QualificationPartialInput: QualificationPartialInput;
  QualificationRequirementFilter: QualificationRequirementFilter;
  QualificationRequirementInput: QualificationRequirementInput;
  QualificationRequirementType: ResolverTypeWrapper<QualificationRequirementType>;
  QualificationRequirementTypeConnection: ResolverTypeWrapper<QualificationRequirementTypeConnection>;
  QualificationRequirementTypeEdge: ResolverTypeWrapper<QualificationRequirementTypeEdge>;
  QualificationType: ResolverTypeWrapper<QualificationType>;
  QualificationTypeConnection: ResolverTypeWrapper<QualificationTypeConnection>;
  QualificationTypeEdge: ResolverTypeWrapper<QualificationTypeEdge>;
  Query: ResolverTypeWrapper<{}>;
  RefreshTokenType: ResolverTypeWrapper<RefreshTokenType>;
  RegionFilter: RegionFilter;
  RegionInput: RegionInput;
  RegionOrder: RegionOrder;
  RegionPartialInput: RegionPartialInput;
  RegionType: ResolverTypeWrapper<RegionType>;
  RegionTypeConnection: ResolverTypeWrapper<RegionTypeConnection>;
  RegionTypeEdge: ResolverTypeWrapper<RegionTypeEdge>;
  ResourceAllocationFilter: ResourceAllocationFilter;
  ResourceAllocationInput: ResourceAllocationInput;
  ResourceAllocationPartialInput: ResourceAllocationPartialInput;
  ResourceAllocationType: ResolverTypeWrapper<ResourceAllocationType>;
  ResourceCategoryFilter: ResourceCategoryFilter;
  ResourceCategoryInput: ResourceCategoryInput;
  ResourceCategoryOrder: ResourceCategoryOrder;
  ResourceCategoryPartialInput: ResourceCategoryPartialInput;
  ResourceCategoryType: ResolverTypeWrapper<ResourceCategoryType>;
  ResourceCategoryTypeConnection: ResolverTypeWrapper<ResourceCategoryTypeConnection>;
  ResourceCategoryTypeEdge: ResolverTypeWrapper<ResourceCategoryTypeEdge>;
  ResourceFilter: ResourceFilter;
  ResourceInput: ResourceInput;
  ResourceOrder: ResourceOrder;
  ResourcePartialInput: ResourcePartialInput;
  ResourceType: ResolverTypeWrapper<ResourceType>;
  ResourceTypeConnection: ResolverTypeWrapper<ResourceTypeConnection>;
  ResourceTypeEdge: ResolverTypeWrapper<ResourceTypeEdge>;
  RespiratoryRate: RespiratoryRate;
  RespiratoryRegularity: RespiratoryRegularity;
  Response: Response;
  RevokeRefreshTokenType: ResolverTypeWrapper<RevokeRefreshTokenType>;
  Rhythm: Rhythm;
  RxAirwayType: RxAirwayType;
  RxBreathingVentilationType: RxBreathingVentilationType;
  RxCPROperation: RxCprOperation;
  RxCPRType: RxCprType;
  RxCannulaAttempts: RxCannulaAttempts;
  RxCannulaSite: RxCannulaSite;
  RxCannulaSize: RxCannulaSize;
  RxConsultMethod: RxConsultMethod;
  RxConsultType: RxConsultType;
  RxDefibrillationPads: RxDefibrillationPads;
  RxDefibrillationType: RxDefibrillationType;
  RxEffectiveness: RxEffectiveness;
  RxFirstAidType: RxFirstAidType;
  RxMajorTraumaType: RxMajorTraumaType;
  RxMedicationRoute: RxMedicationRoute;
  RxOxygenType: RxOxygenType;
  RxType: RxType;
  SMSEmailInput: SmsEmailInput;
  SMSEmailType: SmsEmailType;
  SendEMPConfig: SendEmpConfig;
  SendForHelp: SendForHelp;
  Sensation: Sensation;
  ShiftAssignmentFilter: ShiftAssignmentFilter;
  ShiftAssignmentOrder: ShiftAssignmentOrder;
  ShiftAssignmentType: ResolverTypeWrapper<ShiftAssignmentType>;
  ShiftAssignmentTypeConnection: ResolverTypeWrapper<ShiftAssignmentTypeConnection>;
  ShiftAssignmentTypeEdge: ResolverTypeWrapper<ShiftAssignmentTypeEdge>;
  ShiftAssignmentWithdrawRequestInput: ShiftAssignmentWithdrawRequestInput;
  ShiftAssignmentWithdrawRequestPartialInput: ShiftAssignmentWithdrawRequestPartialInput;
  ShiftAssignmentWithdrawRequestType: ResolverTypeWrapper<ShiftAssignmentWithdrawRequestType>;
  ShiftEOIResponseType: ResolverTypeWrapper<ShiftEoiResponseType>;
  ShiftFilter: ShiftFilter;
  ShiftInput: ShiftInput;
  ShiftOrder: ShiftOrder;
  ShiftPartialInput: ShiftPartialInput;
  ShiftPositionFilter: ShiftPositionFilter;
  ShiftPositionInput: ShiftPositionInput;
  ShiftPositionOrder: ShiftPositionOrder;
  ShiftPositionPartialInput: ShiftPositionPartialInput;
  ShiftPositionType: ResolverTypeWrapper<ShiftPositionType>;
  ShiftPositionTypeConnection: ResolverTypeWrapper<ShiftPositionTypeConnection>;
  ShiftPositionTypeEdge: ResolverTypeWrapper<ShiftPositionTypeEdge>;
  ShiftSkillsetType: ShiftSkillsetType;
  ShiftType: ResolverTypeWrapper<ShiftType>;
  ShiftTypeConnection: ResolverTypeWrapper<ShiftTypeConnection>;
  ShiftTypeEdge: ResolverTypeWrapper<ShiftTypeEdge>;
  SkillsetInput: SkillsetInput;
  SkillsetOrder: SkillsetOrder;
  SkillsetPartialInput: SkillsetPartialInput;
  SkillsetType: ResolverTypeWrapper<SkillsetType>;
  SkillsetTypeConnection: ResolverTypeWrapper<SkillsetTypeConnection>;
  SkillsetTypeEdge: ResolverTypeWrapper<SkillsetTypeEdge>;
  SkinColour: SkinColour;
  SlackChannelType: ResolverTypeWrapper<SlackChannelType>;
  Speech: Speech;
  SpinalLocation: SpinalLocation;
  StateType: ResolverTypeWrapper<StateType>;
  StrFilterLookup: StrFilterLookup;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  Subscription: ResolverTypeWrapper<{}>;
  TenantPartialInput: TenantPartialInput;
  TenantType: ResolverTypeWrapper<TenantType>;
  TextTemplateFilter: TextTemplateFilter;
  TextTemplateInput: TextTemplateInput;
  TextTemplateKind: TextTemplateKind;
  TextTemplateKindFilterLookup: TextTemplateKindFilterLookup;
  TextTemplateOrder: TextTemplateOrder;
  TextTemplatePartialInput: TextTemplatePartialInput;
  TextTemplateType: ResolverTypeWrapper<TextTemplateType>;
  TextTemplateTypeConnection: ResolverTypeWrapper<TextTemplateTypeConnection>;
  TextTemplateTypeEdge: ResolverTypeWrapper<TextTemplateTypeEdge>;
  TimeSheetFilter: TimeSheetFilter;
  TimeSheetInput: TimeSheetInput;
  TimeSheetOrder: TimeSheetOrder;
  TimeSheetPartialInput: TimeSheetPartialInput;
  TimeSheetStatus: TimeSheetStatus;
  TimeSheetType: ResolverTypeWrapper<TimeSheetType>;
  TimeSheetTypeConnection: ResolverTypeWrapper<TimeSheetTypeConnection>;
  TimeSheetTypeEdge: ResolverTypeWrapper<TimeSheetTypeEdge>;
  TokenPayloadType: ResolverTypeWrapper<TokenPayloadType>;
  TokenType: ResolverTypeWrapper<TokenType>;
  TopCaseCategoryType: ResolverTypeWrapper<TopCaseCategoryType>;
  TwoFactorProvision: ResolverTypeWrapper<TwoFactorProvision>;
  UUID: ResolverTypeWrapper<Scalars['UUID']['output']>;
  UpdateAccountPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateAccountPayload']>;
  UpdateAccreditationPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateAccreditationPayload']>;
  UpdateAdviceTemplatePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateAdviceTemplatePayload']>;
  UpdateCaseCategoryPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateCaseCategoryPayload']>;
  UpdateCaseCategorySetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateCaseCategorySetPayload']>;
  UpdateCasePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateCasePayload']>;
  UpdateClientPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateClientPayload']>;
  UpdateConsumablePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateConsumablePayload']>;
  UpdateDrugPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateDrugPayload']>;
  UpdateEventKindPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateEventKindPayload']>;
  UpdateEventPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateEventPayload']>;
  UpdateHospitalPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateHospitalPayload']>;
  UpdateMyTimeSheetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateMyTimeSheetPayload']>;
  UpdateQualificationDocumentPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateQualificationDocumentPayload']>;
  UpdateQualificationPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateQualificationPayload']>;
  UpdateRegionPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateRegionPayload']>;
  UpdateResourceCategoryPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateResourceCategoryPayload']>;
  UpdateResourcePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateResourcePayload']>;
  UpdateSkillsetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateSkillsetPayload']>;
  UpdateTenantPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateTenantPayload']>;
  UpdateTextTemplatePayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateTextTemplatePayload']>;
  UpdateTimeSheetPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateTimeSheetPayload']>;
  UpdateUserPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateUserPayload']>;
  UpdateWithdrawEoiRequestPayload: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['UpdateWithdrawEoiRequestPayload']>;
  Upload: ResolverTypeWrapper<Scalars['Upload']['output']>;
  UserFilter: UserFilter;
  UserInput: UserInput;
  UserOrder: UserOrder;
  UserPartialInput: UserPartialInput;
  UserStatusFilter: UserStatusFilter;
  UserStatusType: ResolverTypeWrapper<UserStatusType>;
  UserType: ResolverTypeWrapper<UserType>;
  UserTypeConnection: ResolverTypeWrapper<UserTypeConnection>;
  UserTypeEdge: ResolverTypeWrapper<UserTypeEdge>;
  ValidationType: ValidationType;
  VerifyTokenType: ResolverTypeWrapper<VerifyTokenType>;
  WithdrawRequestStatus: WithdrawRequestStatus;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  AccountPartialInput: AccountPartialInput;
  AccreditationFilter: AccreditationFilter;
  AccreditationInput: AccreditationInput;
  AccreditationOrder: AccreditationOrder;
  AccreditationPartialInput: AccreditationPartialInput;
  AccreditationType: AccreditationType;
  AccreditationTypeConnection: AccreditationTypeConnection;
  AccreditationTypeEdge: AccreditationTypeEdge;
  AddressInput: AddressInput;
  AddressType: AddressType;
  AdviceTemplateFilter: AdviceTemplateFilter;
  AdviceTemplateInput: AdviceTemplateInput;
  AdviceTemplateOrder: AdviceTemplateOrder;
  AdviceTemplatePartialInput: AdviceTemplatePartialInput;
  AdviceTemplateType: AdviceTemplateType;
  AdviceTemplateTypeConnection: AdviceTemplateTypeConnection;
  AdviceTemplateTypeEdge: AdviceTemplateTypeEdge;
  AppPermissions: AppPermissions;
  BaseInterface: ResolversInterfaceTypes<ResolversParentTypes>['BaseInterface'];
  BaseReorderableInterface: ResolversInterfaceTypes<ResolversParentTypes>['BaseReorderableInterface'];
  BoolBaseFilterLookup: BoolBaseFilterLookup;
  Boolean: Scalars['Boolean']['output'];
  CaseAttachmentInput: CaseAttachmentInput;
  CaseAttachmentPartialInput: CaseAttachmentPartialInput;
  CaseAttachmentType: CaseAttachmentType;
  CaseAttendantInput: CaseAttendantInput;
  CaseAttendantPartialInput: CaseAttendantPartialInput;
  CaseAttendantType: CaseAttendantType;
  CaseAuditInput: CaseAuditInput;
  CaseAuditOrder: CaseAuditOrder;
  CaseAuditType: CaseAuditType;
  CaseAxInput: CaseAxInput;
  CaseAxPartialInput: CaseAxPartialInput;
  CaseAxType: CaseAxType;
  CaseCategoryFilter: CaseCategoryFilter;
  CaseCategoryInput: CaseCategoryInput;
  CaseCategoryOrder: CaseCategoryOrder;
  CaseCategoryPartialInput: CaseCategoryPartialInput;
  CaseCategorySetFilter: CaseCategorySetFilter;
  CaseCategorySetInput: CaseCategorySetInput;
  CaseCategorySetOrder: CaseCategorySetOrder;
  CaseCategorySetPartialInput: CaseCategorySetPartialInput;
  CaseCategorySetType: CaseCategorySetType;
  CaseCategorySetTypeConnection: CaseCategorySetTypeConnection;
  CaseCategorySetTypeEdge: CaseCategorySetTypeEdge;
  CaseCategoryType: CaseCategoryType;
  CaseCategoryTypeConnection: CaseCategoryTypeConnection;
  CaseCategoryTypeEdge: CaseCategoryTypeEdge;
  CaseConsumableInput: CaseConsumableInput;
  CaseConsumablePartialInput: CaseConsumablePartialInput;
  CaseConsumableType: CaseConsumableType;
  CaseCustomAxInput: CaseCustomAxInput;
  CaseCustomAxPartialInput: CaseCustomAxPartialInput;
  CaseCustomAxType: CaseCustomAxType;
  CaseECGAxInput: CaseEcgAxInput;
  CaseECGAxPartialInput: CaseEcgAxPartialInput;
  CaseECGAxType: CaseEcgAxType;
  CaseFilter: CaseFilter;
  CaseGCSAxInput: CaseGcsAxInput;
  CaseGCSAxPartialInput: CaseGcsAxPartialInput;
  CaseGCSAxType: CaseGcsAxType;
  CaseInput: CaseInput;
  CaseMentalAxInput: CaseMentalAxInput;
  CaseMentalAxPartialInput: CaseMentalAxPartialInput;
  CaseMentalAxType: CaseMentalAxType;
  CaseNeurovascularAxInput: CaseNeurovascularAxInput;
  CaseNeurovascularAxPartialInput: CaseNeurovascularAxPartialInput;
  CaseNeurovascularAxType: CaseNeurovascularAxType;
  CaseOrder: CaseOrder;
  CasePainAxInput: CasePainAxInput;
  CasePainAxPartialInput: CasePainAxPartialInput;
  CasePainAxType: CasePainAxType;
  CasePartialInput: CasePartialInput;
  CasePatientPMHxAllergyInput: CasePatientPmHxAllergyInput;
  CasePatientPMHxAllergyPartialInput: CasePatientPmHxAllergyPartialInput;
  CasePatientPMHxAllergyType: CasePatientPmHxAllergyType;
  CasePatientPMHxHistoryInput: CasePatientPmHxHistoryInput;
  CasePatientPMHxHistoryPartialInput: CasePatientPmHxHistoryPartialInput;
  CasePatientPMHxHistoryType: CasePatientPmHxHistoryType;
  CasePatientPMHxMedicationInput: CasePatientPmHxMedicationInput;
  CasePatientPMHxMedicationPartialInput: CasePatientPmHxMedicationPartialInput;
  CasePatientPMHxMedicationType: CasePatientPmHxMedicationType;
  CasePrimaryAxInput: CasePrimaryAxInput;
  CasePrimaryAxPartialInput: CasePrimaryAxPartialInput;
  CasePrimaryAxType: CasePrimaryAxType;
  CasePriorityFilterLookup: CasePriorityFilterLookup;
  CaseROSIERAxInput: CaseRosierAxInput;
  CaseROSIERAxPartialInput: CaseRosierAxPartialInput;
  CaseROSIERAxType: CaseRosierAxType;
  CaseRespiratoryAxInput: CaseRespiratoryAxInput;
  CaseRespiratoryAxPartialInput: CaseRespiratoryAxPartialInput;
  CaseRespiratoryAxType: CaseRespiratoryAxType;
  CaseRxAirwayInput: CaseRxAirwayInput;
  CaseRxAirwayPartialInput: CaseRxAirwayPartialInput;
  CaseRxAirwayType: CaseRxAirwayType;
  CaseRxBreathingVentilationInput: CaseRxBreathingVentilationInput;
  CaseRxBreathingVentilationPartialInput: CaseRxBreathingVentilationPartialInput;
  CaseRxBreathingVentilationType: CaseRxBreathingVentilationType;
  CaseRxCPRInput: CaseRxCprInput;
  CaseRxCPRPartialInput: CaseRxCprPartialInput;
  CaseRxCPRType: CaseRxCprType;
  CaseRxConsultInput: CaseRxConsultInput;
  CaseRxConsultPartialInput: CaseRxConsultPartialInput;
  CaseRxConsultType: CaseRxConsultType;
  CaseRxDefibrillationInput: CaseRxDefibrillationInput;
  CaseRxDefibrillationPartialInput: CaseRxDefibrillationPartialInput;
  CaseRxDefibrillationType: CaseRxDefibrillationType;
  CaseRxFirstAidInput: CaseRxFirstAidInput;
  CaseRxFirstAidPartialInput: CaseRxFirstAidPartialInput;
  CaseRxFirstAidType: CaseRxFirstAidType;
  CaseRxIVAccessInput: CaseRxIvAccessInput;
  CaseRxIVAccessPartialInput: CaseRxIvAccessPartialInput;
  CaseRxIVAccessType: CaseRxIvAccessType;
  CaseRxMajorTraumaInput: CaseRxMajorTraumaInput;
  CaseRxMajorTraumaPartialInput: CaseRxMajorTraumaPartialInput;
  CaseRxMajorTraumaType: CaseRxMajorTraumaType;
  CaseRxMedicationsInput: CaseRxMedicationsInput;
  CaseRxMedicationsPartialInput: CaseRxMedicationsPartialInput;
  CaseRxMedicationsType: CaseRxMedicationsType;
  CaseRxOtherInput: CaseRxOtherInput;
  CaseRxOtherPartialInput: CaseRxOtherPartialInput;
  CaseRxOtherType: CaseRxOtherType;
  CaseRxOxygenInput: CaseRxOxygenInput;
  CaseRxOxygenPartialInput: CaseRxOxygenPartialInput;
  CaseRxOxygenType: CaseRxOxygenType;
  CaseSketchInput: CaseSketchInput;
  CaseSketchPartialInput: CaseSketchPartialInput;
  CaseSketchType: CaseSketchType;
  CaseSpinalAxInput: CaseSpinalAxInput;
  CaseSpinalAxPartialInput: CaseSpinalAxPartialInput;
  CaseSpinalAxType: CaseSpinalAxType;
  CaseTreatmentInput: CaseTreatmentInput;
  CaseTreatmentPartialInput: CaseTreatmentPartialInput;
  CaseTreatmentType: CaseTreatmentType;
  CaseType: CaseType;
  CaseTypeConnection: CaseTypeConnection;
  CaseTypeEdge: CaseTypeEdge;
  CaseVitalsAxInput: CaseVitalsAxInput;
  CaseVitalsAxPartialInput: CaseVitalsAxPartialInput;
  CaseVitalsAxType: CaseVitalsAxType;
  ClientFilter: ClientFilter;
  ClientInput: ClientInput;
  ClientOrder: ClientOrder;
  ClientPartialInput: ClientPartialInput;
  ClientType: ClientType;
  ClientTypeConnection: ClientTypeConnection;
  ClientTypeEdge: ClientTypeEdge;
  ConsumableFilter: ConsumableFilter;
  ConsumableInput: ConsumableInput;
  ConsumableOrder: ConsumableOrder;
  ConsumablePartialInput: ConsumablePartialInput;
  ConsumableType: ConsumableType;
  ConsumableTypeConnection: ConsumableTypeConnection;
  ConsumableTypeEdge: ConsumableTypeEdge;
  ContactInput: ContactInput;
  ContactPartialInput: ContactPartialInput;
  ContactType: ContactType;
  CountryType: CountryType;
  CreateAccreditationPayload: ResolversUnionTypes<ResolversParentTypes>['CreateAccreditationPayload'];
  CreateAdviceTemplatePayload: ResolversUnionTypes<ResolversParentTypes>['CreateAdviceTemplatePayload'];
  CreateCaseAuditPayload: ResolversUnionTypes<ResolversParentTypes>['CreateCaseAuditPayload'];
  CreateCaseCategoryPayload: ResolversUnionTypes<ResolversParentTypes>['CreateCaseCategoryPayload'];
  CreateCaseCategorySetPayload: ResolversUnionTypes<ResolversParentTypes>['CreateCaseCategorySetPayload'];
  CreateCasePayload: ResolversUnionTypes<ResolversParentTypes>['CreateCasePayload'];
  CreateClientPayload: ResolversUnionTypes<ResolversParentTypes>['CreateClientPayload'];
  CreateConsumablePayload: ResolversUnionTypes<ResolversParentTypes>['CreateConsumablePayload'];
  CreateDeleteQualificationAuditPayload: ResolversUnionTypes<ResolversParentTypes>['CreateDeleteQualificationAuditPayload'];
  CreateDrugPayload: ResolversUnionTypes<ResolversParentTypes>['CreateDrugPayload'];
  CreateEventKindPayload: ResolversUnionTypes<ResolversParentTypes>['CreateEventKindPayload'];
  CreateEventPayload: ResolversUnionTypes<ResolversParentTypes>['CreateEventPayload'];
  CreateFileNodePayload: ResolversUnionTypes<ResolversParentTypes>['CreateFileNodePayload'];
  CreateHospitalPayload: ResolversUnionTypes<ResolversParentTypes>['CreateHospitalPayload'];
  CreateMyTimeSheetPayload: ResolversUnionTypes<ResolversParentTypes>['CreateMyTimeSheetPayload'];
  CreateQualificationDocumentPayload: ResolversUnionTypes<ResolversParentTypes>['CreateQualificationDocumentPayload'];
  CreateQualificationPayload: ResolversUnionTypes<ResolversParentTypes>['CreateQualificationPayload'];
  CreateRegionPayload: ResolversUnionTypes<ResolversParentTypes>['CreateRegionPayload'];
  CreateResourceCategoryPayload: ResolversUnionTypes<ResolversParentTypes>['CreateResourceCategoryPayload'];
  CreateResourcePayload: ResolversUnionTypes<ResolversParentTypes>['CreateResourcePayload'];
  CreateSkillsetPayload: ResolversUnionTypes<ResolversParentTypes>['CreateSkillsetPayload'];
  CreateTextTemplatePayload: ResolversUnionTypes<ResolversParentTypes>['CreateTextTemplatePayload'];
  CreateTimeSheetPayload: ResolversUnionTypes<ResolversParentTypes>['CreateTimeSheetPayload'];
  CreateUserPayload: ResolversUnionTypes<ResolversParentTypes>['CreateUserPayload'];
  CreateWithdrawEoiRequestPayload: ResolversUnionTypes<ResolversParentTypes>['CreateWithdrawEoiRequestPayload'];
  DatasetsType: DatasetsType;
  DateTime: Scalars['DateTime']['output'];
  DatetimeDatetimeFilterLookup: DatetimeDatetimeFilterLookup;
  DatetimeFilterLookup: DatetimeFilterLookup;
  DatetimeRangeLookup: DatetimeRangeLookup;
  Decimal: Scalars['Decimal']['output'];
  DecimalComparisonFilterLookup: DecimalComparisonFilterLookup;
  DecimalRangeLookup: DecimalRangeLookup;
  DeleteAccreditationPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteAccreditationPayload'];
  DeleteAdviceTemplatePayload: ResolversUnionTypes<ResolversParentTypes>['DeleteAdviceTemplatePayload'];
  DeleteCaseCategoryPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteCaseCategoryPayload'];
  DeleteCaseCategorySetPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteCaseCategorySetPayload'];
  DeleteCasePayload: ResolversUnionTypes<ResolversParentTypes>['DeleteCasePayload'];
  DeleteClientPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteClientPayload'];
  DeleteConsumablePayload: ResolversUnionTypes<ResolversParentTypes>['DeleteConsumablePayload'];
  DeleteDrugPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteDrugPayload'];
  DeleteEventKindPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteEventKindPayload'];
  DeleteEventPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteEventPayload'];
  DeleteFileNodePayload: ResolversUnionTypes<ResolversParentTypes>['DeleteFileNodePayload'];
  DeleteHospitalPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteHospitalPayload'];
  DeleteQualificationDocumentPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteQualificationDocumentPayload'];
  DeleteQualificationPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteQualificationPayload'];
  DeleteRegionPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteRegionPayload'];
  DeleteResourceCategoryPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteResourceCategoryPayload'];
  DeleteResourcePayload: ResolversUnionTypes<ResolversParentTypes>['DeleteResourcePayload'];
  DeleteSkillsetPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteSkillsetPayload'];
  DeleteTextTemplatePayload: ResolversUnionTypes<ResolversParentTypes>['DeleteTextTemplatePayload'];
  DeleteTimeSheetPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteTimeSheetPayload'];
  DeleteUserPayload: ResolversUnionTypes<ResolversParentTypes>['DeleteUserPayload'];
  DjangoFileType: DjangoFileType;
  DrugBrandNamePartialInput: DrugBrandNamePartialInput;
  DrugBrandNameType: DrugBrandNameType;
  DrugContraindicationPartialInput: DrugContraindicationPartialInput;
  DrugContraindicationType: DrugContraindicationType;
  DrugFilter: DrugFilter;
  DrugIndicationPartialInput: DrugIndicationPartialInput;
  DrugIndicationType: DrugIndicationType;
  DrugInput: DrugInput;
  DrugOrder: DrugOrder;
  DrugPartialInput: DrugPartialInput;
  DrugPrecautionPartialInput: DrugPrecautionPartialInput;
  DrugPrecautionType: DrugPrecautionType;
  DrugSideEffectPartialInput: DrugSideEffectPartialInput;
  DrugSideEffectType: DrugSideEffectType;
  DrugType: DrugType;
  DrugTypeConnection: DrugTypeConnection;
  DrugTypeEdge: DrugTypeEdge;
  EventConsumableType: EventConsumableType;
  EventConsumablesType: EventConsumablesType;
  EventFilter: EventFilter;
  EventInput: EventInput;
  EventInterestStatusType: EventInterestStatusType;
  EventKindFilter: EventKindFilter;
  EventKindInput: EventKindInput;
  EventKindOrder: EventKindOrder;
  EventKindPartialInput: EventKindPartialInput;
  EventKindType: EventKindType;
  EventKindTypeConnection: EventKindTypeConnection;
  EventKindTypeEdge: EventKindTypeEdge;
  EventOrder: EventOrder;
  EventPartialInput: EventPartialInput;
  EventScheduleStatusType: EventScheduleStatusType;
  EventStatusFilterLookup: EventStatusFilterLookup;
  EventType: EventType;
  EventTypeConnection: EventTypeConnection;
  EventTypeEdge: EventTypeEdge;
  ExpectedError: Scalars['ExpectedError']['output'];
  FileNodeFilter: FileNodeFilter;
  FileNodeInput: FileNodeInput;
  FileNodeType: FileNodeType;
  FileNodeTypeConnection: FileNodeTypeConnection;
  FileNodeTypeEdge: FileNodeTypeEdge;
  Float: Scalars['Float']['output'];
  GlobalID: Scalars['GlobalID']['output'];
  GlobalIDBaseFilterLookup: GlobalIdBaseFilterLookup;
  GlobalIDFilterLookup: GlobalIdFilterLookup;
  GraphDataType: GraphDataType;
  HospitalFilter: HospitalFilter;
  HospitalInput: HospitalInput;
  HospitalOrder: HospitalOrder;
  HospitalPartialInput: HospitalPartialInput;
  HospitalType: HospitalType;
  HospitalTypeConnection: HospitalTypeConnection;
  HospitalTypeEdge: HospitalTypeEdge;
  ID: Scalars['ID']['output'];
  Int: Scalars['Int']['output'];
  IntComparisonFilterLookup: IntComparisonFilterLookup;
  IntRangeLookup: IntRangeLookup;
  JSON: Scalars['JSON']['output'];
  LocalityType: LocalityType;
  Mutation: {};
  MutationNormalOutput: MutationNormalOutput;
  MyTimeSheetInput: MyTimeSheetInput;
  MyTimeSheetPartialInput: MyTimeSheetPartialInput;
  Node: ResolversInterfaceTypes<ResolversParentTypes>['Node'];
  NodeInput: NodeInput;
  NodeInputPartial: NodeInputPartial;
  NodeInputPartialListInput: NodeInputPartialListInput;
  NotificationCreatedType: NotificationCreatedType;
  NotificationFilter: NotificationFilter;
  NotificationResponseType: NotificationResponseType;
  NotificationType: NotificationType;
  NotificationTypeConnection: NotificationTypeConnection;
  NotificationTypeEdge: NotificationTypeEdge;
  ObtainJSONWebTokenType: ObtainJsonWebTokenType;
  OffsetPaginationInput: OffsetPaginationInput;
  OperationInfo: OperationInfo;
  OperationMessage: OperationMessage;
  OutputInterface: ResolversInterfaceTypes<ResolversParentTypes>['OutputInterface'];
  PageInfo: PageInfo;
  PatientInput: PatientInput;
  PatientPartialInput: PatientPartialInput;
  PatientType: PatientType;
  Permission: Permission;
  PermissionGroup: PermissionGroup;
  QualificationDocumentAuditInput: QualificationDocumentAuditInput;
  QualificationDocumentAuditOrder: QualificationDocumentAuditOrder;
  QualificationDocumentAuditType: QualificationDocumentAuditType;
  QualificationDocumentFilter: QualificationDocumentFilter;
  QualificationDocumentInput: QualificationDocumentInput;
  QualificationDocumentOrder: QualificationDocumentOrder;
  QualificationDocumentPartialInput: QualificationDocumentPartialInput;
  QualificationDocumentStatusFilterLookup: QualificationDocumentStatusFilterLookup;
  QualificationDocumentType: QualificationDocumentType;
  QualificationDocumentTypeConnection: QualificationDocumentTypeConnection;
  QualificationDocumentTypeEdge: QualificationDocumentTypeEdge;
  QualificationFilter: QualificationFilter;
  QualificationInput: QualificationInput;
  QualificationOrder: QualificationOrder;
  QualificationPartialInput: QualificationPartialInput;
  QualificationRequirementFilter: QualificationRequirementFilter;
  QualificationRequirementInput: QualificationRequirementInput;
  QualificationRequirementType: QualificationRequirementType;
  QualificationRequirementTypeConnection: QualificationRequirementTypeConnection;
  QualificationRequirementTypeEdge: QualificationRequirementTypeEdge;
  QualificationType: QualificationType;
  QualificationTypeConnection: QualificationTypeConnection;
  QualificationTypeEdge: QualificationTypeEdge;
  Query: {};
  RefreshTokenType: RefreshTokenType;
  RegionFilter: RegionFilter;
  RegionInput: RegionInput;
  RegionOrder: RegionOrder;
  RegionPartialInput: RegionPartialInput;
  RegionType: RegionType;
  RegionTypeConnection: RegionTypeConnection;
  RegionTypeEdge: RegionTypeEdge;
  ResourceAllocationFilter: ResourceAllocationFilter;
  ResourceAllocationInput: ResourceAllocationInput;
  ResourceAllocationPartialInput: ResourceAllocationPartialInput;
  ResourceAllocationType: ResourceAllocationType;
  ResourceCategoryFilter: ResourceCategoryFilter;
  ResourceCategoryInput: ResourceCategoryInput;
  ResourceCategoryOrder: ResourceCategoryOrder;
  ResourceCategoryPartialInput: ResourceCategoryPartialInput;
  ResourceCategoryType: ResourceCategoryType;
  ResourceCategoryTypeConnection: ResourceCategoryTypeConnection;
  ResourceCategoryTypeEdge: ResourceCategoryTypeEdge;
  ResourceFilter: ResourceFilter;
  ResourceInput: ResourceInput;
  ResourceOrder: ResourceOrder;
  ResourcePartialInput: ResourcePartialInput;
  ResourceType: ResourceType;
  ResourceTypeConnection: ResourceTypeConnection;
  ResourceTypeEdge: ResourceTypeEdge;
  RevokeRefreshTokenType: RevokeRefreshTokenType;
  SMSEmailInput: SmsEmailInput;
  SendEMPConfig: SendEmpConfig;
  ShiftAssignmentFilter: ShiftAssignmentFilter;
  ShiftAssignmentOrder: ShiftAssignmentOrder;
  ShiftAssignmentType: ShiftAssignmentType;
  ShiftAssignmentTypeConnection: ShiftAssignmentTypeConnection;
  ShiftAssignmentTypeEdge: ShiftAssignmentTypeEdge;
  ShiftAssignmentWithdrawRequestInput: ShiftAssignmentWithdrawRequestInput;
  ShiftAssignmentWithdrawRequestPartialInput: ShiftAssignmentWithdrawRequestPartialInput;
  ShiftAssignmentWithdrawRequestType: ShiftAssignmentWithdrawRequestType;
  ShiftEOIResponseType: ShiftEoiResponseType;
  ShiftFilter: ShiftFilter;
  ShiftInput: ShiftInput;
  ShiftOrder: ShiftOrder;
  ShiftPartialInput: ShiftPartialInput;
  ShiftPositionFilter: ShiftPositionFilter;
  ShiftPositionInput: ShiftPositionInput;
  ShiftPositionOrder: ShiftPositionOrder;
  ShiftPositionPartialInput: ShiftPositionPartialInput;
  ShiftPositionType: ShiftPositionType;
  ShiftPositionTypeConnection: ShiftPositionTypeConnection;
  ShiftPositionTypeEdge: ShiftPositionTypeEdge;
  ShiftType: ShiftType;
  ShiftTypeConnection: ShiftTypeConnection;
  ShiftTypeEdge: ShiftTypeEdge;
  SkillsetInput: SkillsetInput;
  SkillsetOrder: SkillsetOrder;
  SkillsetPartialInput: SkillsetPartialInput;
  SkillsetType: SkillsetType;
  SkillsetTypeConnection: SkillsetTypeConnection;
  SkillsetTypeEdge: SkillsetTypeEdge;
  SlackChannelType: SlackChannelType;
  StateType: StateType;
  StrFilterLookup: StrFilterLookup;
  String: Scalars['String']['output'];
  Subscription: {};
  TenantPartialInput: TenantPartialInput;
  TenantType: TenantType;
  TextTemplateFilter: TextTemplateFilter;
  TextTemplateInput: TextTemplateInput;
  TextTemplateKindFilterLookup: TextTemplateKindFilterLookup;
  TextTemplateOrder: TextTemplateOrder;
  TextTemplatePartialInput: TextTemplatePartialInput;
  TextTemplateType: TextTemplateType;
  TextTemplateTypeConnection: TextTemplateTypeConnection;
  TextTemplateTypeEdge: TextTemplateTypeEdge;
  TimeSheetFilter: TimeSheetFilter;
  TimeSheetInput: TimeSheetInput;
  TimeSheetOrder: TimeSheetOrder;
  TimeSheetPartialInput: TimeSheetPartialInput;
  TimeSheetType: TimeSheetType;
  TimeSheetTypeConnection: TimeSheetTypeConnection;
  TimeSheetTypeEdge: TimeSheetTypeEdge;
  TokenPayloadType: TokenPayloadType;
  TokenType: TokenType;
  TopCaseCategoryType: TopCaseCategoryType;
  TwoFactorProvision: TwoFactorProvision;
  UUID: Scalars['UUID']['output'];
  UpdateAccountPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateAccountPayload'];
  UpdateAccreditationPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateAccreditationPayload'];
  UpdateAdviceTemplatePayload: ResolversUnionTypes<ResolversParentTypes>['UpdateAdviceTemplatePayload'];
  UpdateCaseCategoryPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateCaseCategoryPayload'];
  UpdateCaseCategorySetPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateCaseCategorySetPayload'];
  UpdateCasePayload: ResolversUnionTypes<ResolversParentTypes>['UpdateCasePayload'];
  UpdateClientPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateClientPayload'];
  UpdateConsumablePayload: ResolversUnionTypes<ResolversParentTypes>['UpdateConsumablePayload'];
  UpdateDrugPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateDrugPayload'];
  UpdateEventKindPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateEventKindPayload'];
  UpdateEventPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateEventPayload'];
  UpdateHospitalPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateHospitalPayload'];
  UpdateMyTimeSheetPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateMyTimeSheetPayload'];
  UpdateQualificationDocumentPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateQualificationDocumentPayload'];
  UpdateQualificationPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateQualificationPayload'];
  UpdateRegionPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateRegionPayload'];
  UpdateResourceCategoryPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateResourceCategoryPayload'];
  UpdateResourcePayload: ResolversUnionTypes<ResolversParentTypes>['UpdateResourcePayload'];
  UpdateSkillsetPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateSkillsetPayload'];
  UpdateTenantPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateTenantPayload'];
  UpdateTextTemplatePayload: ResolversUnionTypes<ResolversParentTypes>['UpdateTextTemplatePayload'];
  UpdateTimeSheetPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateTimeSheetPayload'];
  UpdateUserPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateUserPayload'];
  UpdateWithdrawEoiRequestPayload: ResolversUnionTypes<ResolversParentTypes>['UpdateWithdrawEoiRequestPayload'];
  Upload: Scalars['Upload']['output'];
  UserFilter: UserFilter;
  UserInput: UserInput;
  UserOrder: UserOrder;
  UserPartialInput: UserPartialInput;
  UserStatusFilter: UserStatusFilter;
  UserStatusType: UserStatusType;
  UserType: UserType;
  UserTypeConnection: UserTypeConnection;
  UserTypeEdge: UserTypeEdge;
  VerifyTokenType: VerifyTokenType;
};

export type AccreditationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccreditationType'] = ResolversParentTypes['AccreditationType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccreditationTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccreditationTypeConnection'] = ResolversParentTypes['AccreditationTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['AccreditationTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AccreditationTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AccreditationTypeEdge'] = ResolversParentTypes['AccreditationTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AccreditationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AddressType'] = ResolversParentTypes['AddressType']> = {
  formatted?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['LocalityType']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  raw?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  route?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  streetNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdviceTemplateTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdviceTemplateType'] = ResolversParentTypes['AdviceTemplateType']> = {
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdviceTemplateTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdviceTemplateTypeConnection'] = ResolversParentTypes['AdviceTemplateTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['AdviceTemplateTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AdviceTemplateTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['AdviceTemplateTypeEdge'] = ResolversParentTypes['AdviceTemplateTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['AdviceTemplateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppPermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppPermissions'] = ResolversParentTypes['AppPermissions']> = {
  app?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  groups?: Resolver<Array<ResolversTypes['PermissionGroup']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseInterface'] = ResolversParentTypes['BaseInterface']> = {
  __resolveType: TypeResolveFn<'AdviceTemplateType' | 'CaseAuditType' | 'CaseCategorySetType' | 'CaseCategoryType' | 'CaseSketchType' | 'CaseType' | 'ClientType' | 'ConsumableType' | 'ContactType' | 'DrugType' | 'EventKindType' | 'EventType' | 'HospitalType' | 'PatientType' | 'QualificationDocumentAuditType' | 'QualificationDocumentType' | 'QualificationType' | 'ResourceCategoryType' | 'ResourceType' | 'SkillsetType' | 'TextTemplateType', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
};

export type BaseReorderableInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseReorderableInterface'] = ResolversParentTypes['BaseReorderableInterface']> = {
  __resolveType: TypeResolveFn<'ResourceCategoryType' | 'ResourceType' | 'SkillsetType', ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
};

export type CaseAttachmentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseAttachmentType'] = ResolversParentTypes['CaseAttachmentType']> = {
  attachment?: Resolver<ResolversTypes['DjangoFileType'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseAttendantTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseAttendantType'] = ResolversParentTypes['CaseAttendantType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  role?: Resolver<ResolversTypes['CaseAttendantRole'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseAuditTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseAuditType'] = ResolversParentTypes['CaseAuditType']> = {
  case?: Resolver<ResolversTypes['CaseType'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['CaseAuditOutcome'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseAxType'] = ResolversParentTypes['CaseAxType']> = {
  attendant?: Resolver<ResolversTypes['Node'], ParentType, ContextType>;
  axCustom?: Resolver<Maybe<ResolversTypes['CaseCustomAxType']>, ParentType, ContextType>;
  axEcg?: Resolver<Maybe<ResolversTypes['CaseECGAxType']>, ParentType, ContextType>;
  axGcs?: Resolver<Maybe<ResolversTypes['CaseGCSAxType']>, ParentType, ContextType>;
  axMental?: Resolver<Maybe<ResolversTypes['CaseMentalAxType']>, ParentType, ContextType>;
  axNeurovascular?: Resolver<Maybe<ResolversTypes['CaseNeurovascularAxType']>, ParentType, ContextType>;
  axPain?: Resolver<Maybe<ResolversTypes['CasePainAxType']>, ParentType, ContextType>;
  axPrimary?: Resolver<Maybe<ResolversTypes['CasePrimaryAxType']>, ParentType, ContextType>;
  axRespiratory?: Resolver<Maybe<ResolversTypes['CaseRespiratoryAxType']>, ParentType, ContextType>;
  axRosier?: Resolver<Maybe<ResolversTypes['CaseROSIERAxType']>, ParentType, ContextType>;
  axSpinal?: Resolver<Maybe<ResolversTypes['CaseSpinalAxType']>, ParentType, ContextType>;
  axType?: Resolver<ResolversTypes['AxType'], ParentType, ContextType>;
  axVitals?: Resolver<Maybe<ResolversTypes['CaseVitalsAxType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCategorySetTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCategorySetType'] = ResolversParentTypes['CaseCategorySetType']> = {
  categories?: Resolver<Array<ResolversTypes['CaseCategoryType']>, ParentType, ContextType, Partial<CaseCategorySetTypeCategoriesArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  triageEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCategorySetTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCategorySetTypeConnection'] = ResolversParentTypes['CaseCategorySetTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['CaseCategorySetTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCategorySetTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCategorySetTypeEdge'] = ResolversParentTypes['CaseCategorySetTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CaseCategorySetType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCategoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCategoryType'] = ResolversParentTypes['CaseCategoryType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  priority?: Resolver<ResolversTypes['CasePriority'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCategoryTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCategoryTypeConnection'] = ResolversParentTypes['CaseCategoryTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['CaseCategoryTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCategoryTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCategoryTypeEdge'] = ResolversParentTypes['CaseCategoryTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CaseCategoryType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseConsumableTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseConsumableType'] = ResolversParentTypes['CaseConsumableType']> = {
  consumable?: Resolver<ResolversTypes['ConsumableType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resource?: Resolver<Maybe<ResolversTypes['ResourceType']>, ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseCustomAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseCustomAxType'] = ResolversParentTypes['CaseCustomAxType']> = {
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseEcgAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseECGAxType'] = ResolversParentTypes['CaseECGAxType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  photo?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  regularity?: Resolver<Maybe<ResolversTypes['PulseRegularity']>, ParentType, ContextType>;
  rhythm?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseGcsAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseGCSAxType'] = ResolversParentTypes['CaseGCSAxType']> = {
  eye?: Resolver<Maybe<ResolversTypes['GCSEyeResponse']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  motor?: Resolver<Maybe<ResolversTypes['GCSMotorResponse']>, ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  verbal?: Resolver<Maybe<ResolversTypes['GCSVerbalResponse']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseMentalAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseMentalAxType'] = ResolversParentTypes['CaseMentalAxType']> = {
  affect?: Resolver<ResolversTypes['MHAffect'], ParentType, ContextType>;
  appearance?: Resolver<ResolversTypes['MHAppearance'], ParentType, ContextType>;
  behaviour?: Resolver<ResolversTypes['MHBehaviour'], ParentType, ContextType>;
  delusions?: Resolver<ResolversTypes['MHDelusions'], ParentType, ContextType>;
  eyes?: Resolver<ResolversTypes['MHEyes'], ParentType, ContextType>;
  hallucinations?: Resolver<ResolversTypes['MHHallucinations'], ParentType, ContextType>;
  homicidality?: Resolver<ResolversTypes['MHHomicidality'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  memory?: Resolver<ResolversTypes['MHMemory'], ParentType, ContextType>;
  mood?: Resolver<ResolversTypes['MHMood'], ParentType, ContextType>;
  motorActivity?: Resolver<ResolversTypes['MHMotorActivity'], ParentType, ContextType>;
  speech?: Resolver<ResolversTypes['MHSpeech'], ParentType, ContextType>;
  suicidality?: Resolver<ResolversTypes['MHSuicidality'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseNeurovascularAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseNeurovascularAxType'] = ResolversParentTypes['CaseNeurovascularAxType']> = {
  capRefill?: Resolver<ResolversTypes['CapRefill'], ParentType, ContextType>;
  extremityTemp?: Resolver<ResolversTypes['ExtremityTemp'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  limb?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  movement?: Resolver<ResolversTypes['Movement'], ParentType, ContextType>;
  peripheralPulse?: Resolver<ResolversTypes['PeripheralPulse'], ParentType, ContextType>;
  sensation?: Resolver<ResolversTypes['Sensation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasePainAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasePainAxType'] = ResolversParentTypes['CasePainAxType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  onset?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  provocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  radiation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  severity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasePatientPmHxAllergyTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasePatientPMHxAllergyType'] = ResolversParentTypes['CasePatientPMHxAllergyType']> = {
  allergen?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  reaction?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasePatientPmHxHistoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasePatientPMHxHistoryType'] = ResolversParentTypes['CasePatientPMHxHistoryType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasePatientPmHxMedicationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasePatientPMHxMedicationType'] = ResolversParentTypes['CasePatientPMHxMedicationType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  linkedDrug?: Resolver<Maybe<ResolversTypes['DrugType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CasePrimaryAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CasePrimaryAxType'] = ResolversParentTypes['CasePrimaryAxType']> = {
  airway?: Resolver<ResolversTypes['Airway'], ParentType, ContextType>;
  breathing?: Resolver<ResolversTypes['Breathing'], ParentType, ContextType>;
  circulation?: Resolver<ResolversTypes['Circulation'], ParentType, ContextType>;
  dangers?: Resolver<ResolversTypes['Dangers'], ParentType, ContextType>;
  defibrillation?: Resolver<ResolversTypes['Defibrillation'], ParentType, ContextType>;
  haemorrhage?: Resolver<ResolversTypes['Haemorrhage'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  listedDangers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  response?: Resolver<ResolversTypes['Response'], ParentType, ContextType>;
  sendForHelp?: Resolver<ResolversTypes['SendForHelp'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRosierAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseROSIERAxType'] = ResolversParentTypes['CaseROSIERAxType']> = {
  asymmetricArmWeakness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  asymmetricFacialWeakness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  asymmetricLegWeakness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  lossOfConsciousness?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  score?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  seizureActivity?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  speechDisturbance?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  visualFieldDefect?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRespiratoryAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRespiratoryAxType'] = ResolversParentTypes['CaseRespiratoryAxType']> = {
  appearance?: Resolver<ResolversTypes['Appearance'], ParentType, ContextType>;
  auscultation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consciousState?: Resolver<ResolversTypes['Response'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  respiratoryRate?: Resolver<ResolversTypes['RespiratoryRate'], ParentType, ContextType>;
  rhythm?: Resolver<ResolversTypes['Rhythm'], ParentType, ContextType>;
  skinColour?: Resolver<ResolversTypes['SkinColour'], ParentType, ContextType>;
  speech?: Resolver<ResolversTypes['Speech'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxAirwayTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxAirwayType'] = ResolversParentTypes['CaseRxAirwayType']> = {
  airwayType?: Resolver<ResolversTypes['RxAirwayType'], ParentType, ContextType>;
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  endotrachealTubeSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  nasopharanyngealAirwaySize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  oropharanyngealAirwaySize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  supraglotticAirwaySize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxBreathingVentilationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxBreathingVentilationType'] = ResolversParentTypes['CaseRxBreathingVentilationType']> = {
  breathingVentilationType?: Resolver<ResolversTypes['RxBreathingVentilationType'], ParentType, ContextType>;
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  peakPressure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  peep?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tidalVolume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxCprTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxCPRType'] = ResolversParentTypes['CaseRxCPRType']> = {
  cprOperation?: Resolver<ResolversTypes['RxCPROperation'], ParentType, ContextType>;
  cprType?: Resolver<ResolversTypes['RxCPRType'], ParentType, ContextType>;
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxConsultTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxConsultType'] = ResolversParentTypes['CaseRxConsultType']> = {
  consultMethod?: Resolver<ResolversTypes['RxConsultMethod'], ParentType, ContextType>;
  consultType?: Resolver<ResolversTypes['RxConsultType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxDefibrillationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxDefibrillationType'] = ResolversParentTypes['CaseRxDefibrillationType']> = {
  defibrillationPads?: Resolver<ResolversTypes['RxDefibrillationPads'], ParentType, ContextType>;
  defibrillationType?: Resolver<ResolversTypes['RxDefibrillationType'], ParentType, ContextType>;
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  joules?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxFirstAidTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxFirstAidType'] = ResolversParentTypes['CaseRxFirstAidType']> = {
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  firstAidType?: Resolver<ResolversTypes['RxFirstAidType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  other?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxIvAccessTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxIVAccessType'] = ResolversParentTypes['CaseRxIVAccessType']> = {
  attempts?: Resolver<ResolversTypes['RxCannulaAttempts'], ParentType, ContextType>;
  cannulaSite?: Resolver<ResolversTypes['RxCannulaSite'], ParentType, ContextType>;
  cannulaSize?: Resolver<ResolversTypes['RxCannulaSize'], ParentType, ContextType>;
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxMajorTraumaTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxMajorTraumaType'] = ResolversParentTypes['CaseRxMajorTraumaType']> = {
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  majorTraumaType?: Resolver<ResolversTypes['RxMajorTraumaType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxMedicationsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxMedicationsType'] = ResolversParentTypes['CaseRxMedicationsType']> = {
  dose?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  linkedDrug?: Resolver<Maybe<ResolversTypes['DrugType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  route?: Resolver<ResolversTypes['RxMedicationRoute'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxOtherTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxOtherType'] = ResolversParentTypes['CaseRxOtherType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  notes?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseRxOxygenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseRxOxygenType'] = ResolversParentTypes['CaseRxOxygenType']> = {
  effectiveness?: Resolver<ResolversTypes['RxEffectiveness'], ParentType, ContextType>;
  flowRate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  oxygenType?: Resolver<ResolversTypes['RxOxygenType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseSketchTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseSketchType'] = ResolversParentTypes['CaseSketchType']> = {
  attendant?: Resolver<ResolversTypes['Node'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  imageData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseSpinalAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseSpinalAxType'] = ResolversParentTypes['CaseSpinalAxType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  movement?: Resolver<ResolversTypes['Movement'], ParentType, ContextType>;
  other?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pain?: Resolver<Array<ResolversTypes['SpinalLocation']>, ParentType, ContextType>;
  paraesthesia?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pupils?: Resolver<ResolversTypes['Pupils'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTreatmentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseTreatmentType'] = ResolversParentTypes['CaseTreatmentType']> = {
  attendant?: Resolver<ResolversTypes['Node'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  rxAirway?: Resolver<Maybe<ResolversTypes['CaseRxAirwayType']>, ParentType, ContextType>;
  rxBreathingVentilation?: Resolver<Maybe<ResolversTypes['CaseRxBreathingVentilationType']>, ParentType, ContextType>;
  rxConsult?: Resolver<Maybe<ResolversTypes['CaseRxConsultType']>, ParentType, ContextType>;
  rxCpr?: Resolver<Maybe<ResolversTypes['CaseRxCPRType']>, ParentType, ContextType>;
  rxDefibrillation?: Resolver<Maybe<ResolversTypes['CaseRxDefibrillationType']>, ParentType, ContextType>;
  rxFirstAid?: Resolver<Maybe<ResolversTypes['CaseRxFirstAidType']>, ParentType, ContextType>;
  rxIvAccess?: Resolver<Maybe<ResolversTypes['CaseRxIVAccessType']>, ParentType, ContextType>;
  rxMajorTrauma?: Resolver<Maybe<ResolversTypes['CaseRxMajorTraumaType']>, ParentType, ContextType>;
  rxMedications?: Resolver<Maybe<ResolversTypes['CaseRxMedicationsType']>, ParentType, ContextType>;
  rxOther?: Resolver<Maybe<ResolversTypes['CaseRxOtherType']>, ParentType, ContextType>;
  rxOxygen?: Resolver<Maybe<ResolversTypes['CaseRxOxygenType']>, ParentType, ContextType>;
  rxType?: Resolver<ResolversTypes['RxType'], ParentType, ContextType>;
  time?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseType'] = ResolversParentTypes['CaseType']> = {
  adviceGiven?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  attachments?: Resolver<Array<ResolversTypes['CaseAttachmentType']>, ParentType, ContextType>;
  attendant?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  audited?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  audits?: Resolver<Array<ResolversTypes['CaseAuditType']>, ParentType, ContextType, Partial<CaseTypeAuditsArgs>>;
  caseId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  caseNarrative?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  category?: Resolver<ResolversTypes['CaseCategoryType'], ParentType, ContextType>;
  chiefComplaint?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  coAttendants?: Resolver<Array<ResolversTypes['CaseAttendantType']>, ParentType, ContextType>;
  consumables?: Resolver<Array<ResolversTypes['CaseConsumableType']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateTimeCommenced?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateTimeConcluded?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dx?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['EventType']>, ParentType, ContextType>;
  fieldCase?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  linkedCase?: Resolver<Maybe<ResolversTypes['CaseType']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patient?: Resolver<ResolversTypes['PatientType'], ParentType, ContextType>;
  pcr?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  secondarySurveys?: Resolver<Array<ResolversTypes['CaseAxType']>, ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sketches?: Resolver<Array<ResolversTypes['CaseSketchType']>, ParentType, ContextType>;
  summary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalConsumableCost?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  treatments?: Resolver<Array<ResolversTypes['CaseTreatmentType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseTypeConnection'] = ResolversParentTypes['CaseTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['CaseTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseTypeEdge'] = ResolversParentTypes['CaseTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['CaseType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CaseVitalsAxTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CaseVitalsAxType'] = ResolversParentTypes['CaseVitalsAxType']> = {
  avpu?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType>;
  bloodPressure?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bsl?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  capRefill?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  etCo2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  ketones?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  pulseLocation?: Resolver<Maybe<ResolversTypes['PulseLocation']>, ParentType, ContextType>;
  pulseRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pulseRegularity?: Resolver<Maybe<ResolversTypes['PulseRegularity']>, ParentType, ContextType>;
  pupilsLeft?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pupilsRight?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  respiratoryRate?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  respiratoryRegularity?: Resolver<Maybe<ResolversTypes['RespiratoryRegularity']>, ParentType, ContextType>;
  spO2?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  temperature?: Resolver<Maybe<ResolversTypes['Decimal']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientType'] = ResolversParentTypes['ClientType']> = {
  address?: Resolver<Maybe<ResolversTypes['AddressType']>, ParentType, ContextType>;
  contacts?: Resolver<Array<ResolversTypes['ContactType']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTypeConnection'] = ResolversParentTypes['ClientTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ClientTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ClientTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ClientTypeEdge'] = ResolversParentTypes['ClientTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ClientType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumableTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsumableType'] = ResolversParentTypes['ConsumableType']> = {
  containedIn?: Resolver<Array<ResolversTypes['ResourceType']>, ParentType, ContextType, Partial<ConsumableTypeContainedInArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pricePerUnit?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumableTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsumableTypeConnection'] = ResolversParentTypes['ConsumableTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ConsumableTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsumableTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsumableTypeEdge'] = ResolversParentTypes['ConsumableTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ConsumableType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactType'] = ResolversParentTypes['ContactType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  position?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primary?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CountryType'] = ResolversParentTypes['CountryType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CreateAccreditationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAccreditationPayload'] = ResolversParentTypes['CreateAccreditationPayload']> = {
  __resolveType: TypeResolveFn<'AccreditationType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateAdviceTemplatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateAdviceTemplatePayload'] = ResolversParentTypes['CreateAdviceTemplatePayload']> = {
  __resolveType: TypeResolveFn<'AdviceTemplateType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateCaseAuditPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCaseAuditPayload'] = ResolversParentTypes['CreateCaseAuditPayload']> = {
  __resolveType: TypeResolveFn<'CaseAuditType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateCaseCategoryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCaseCategoryPayload'] = ResolversParentTypes['CreateCaseCategoryPayload']> = {
  __resolveType: TypeResolveFn<'CaseCategoryType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateCaseCategorySetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCaseCategorySetPayload'] = ResolversParentTypes['CreateCaseCategorySetPayload']> = {
  __resolveType: TypeResolveFn<'CaseCategorySetType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateCasePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateCasePayload'] = ResolversParentTypes['CreateCasePayload']> = {
  __resolveType: TypeResolveFn<'CaseType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateClientPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateClientPayload'] = ResolversParentTypes['CreateClientPayload']> = {
  __resolveType: TypeResolveFn<'ClientType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateConsumablePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateConsumablePayload'] = ResolversParentTypes['CreateConsumablePayload']> = {
  __resolveType: TypeResolveFn<'ConsumableType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateDeleteQualificationAuditPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateDeleteQualificationAuditPayload'] = ResolversParentTypes['CreateDeleteQualificationAuditPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationDocumentAuditType', ParentType, ContextType>;
};

export type CreateDrugPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateDrugPayload'] = ResolversParentTypes['CreateDrugPayload']> = {
  __resolveType: TypeResolveFn<'DrugType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateEventKindPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEventKindPayload'] = ResolversParentTypes['CreateEventKindPayload']> = {
  __resolveType: TypeResolveFn<'EventKindType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateEventPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateEventPayload'] = ResolversParentTypes['CreateEventPayload']> = {
  __resolveType: TypeResolveFn<'EventType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateFileNodePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateFileNodePayload'] = ResolversParentTypes['CreateFileNodePayload']> = {
  __resolveType: TypeResolveFn<'FileNodeType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateHospitalPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateHospitalPayload'] = ResolversParentTypes['CreateHospitalPayload']> = {
  __resolveType: TypeResolveFn<'HospitalType' | 'OperationInfo', ParentType, ContextType>;
};

export type CreateMyTimeSheetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateMyTimeSheetPayload'] = ResolversParentTypes['CreateMyTimeSheetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TimeSheetType', ParentType, ContextType>;
};

export type CreateQualificationDocumentPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateQualificationDocumentPayload'] = ResolversParentTypes['CreateQualificationDocumentPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationDocumentType', ParentType, ContextType>;
};

export type CreateQualificationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateQualificationPayload'] = ResolversParentTypes['CreateQualificationPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationType', ParentType, ContextType>;
};

export type CreateRegionPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateRegionPayload'] = ResolversParentTypes['CreateRegionPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'RegionType', ParentType, ContextType>;
};

export type CreateResourceCategoryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateResourceCategoryPayload'] = ResolversParentTypes['CreateResourceCategoryPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ResourceCategoryType', ParentType, ContextType>;
};

export type CreateResourcePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateResourcePayload'] = ResolversParentTypes['CreateResourcePayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ResourceType', ParentType, ContextType>;
};

export type CreateSkillsetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateSkillsetPayload'] = ResolversParentTypes['CreateSkillsetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'SkillsetType', ParentType, ContextType>;
};

export type CreateTextTemplatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTextTemplatePayload'] = ResolversParentTypes['CreateTextTemplatePayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TextTemplateType', ParentType, ContextType>;
};

export type CreateTimeSheetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateTimeSheetPayload'] = ResolversParentTypes['CreateTimeSheetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TimeSheetType', ParentType, ContextType>;
};

export type CreateUserPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateUserPayload'] = ResolversParentTypes['CreateUserPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'UserType', ParentType, ContextType>;
};

export type CreateWithdrawEoiRequestPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['CreateWithdrawEoiRequestPayload'] = ResolversParentTypes['CreateWithdrawEoiRequestPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ShiftAssignmentWithdrawRequestType', ParentType, ContextType>;
};

export type DatasetsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DatasetsType'] = ResolversParentTypes['DatasetsType']> = {
  datasets?: Resolver<Array<ResolversTypes['GraphDataType']>, ParentType, ContextType>;
  labels?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export interface DecimalScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Decimal'], any> {
  name: 'Decimal';
}

export type DeleteAccreditationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAccreditationPayload'] = ResolversParentTypes['DeleteAccreditationPayload']> = {
  __resolveType: TypeResolveFn<'AccreditationType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteAdviceTemplatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteAdviceTemplatePayload'] = ResolversParentTypes['DeleteAdviceTemplatePayload']> = {
  __resolveType: TypeResolveFn<'AdviceTemplateType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteCaseCategoryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCaseCategoryPayload'] = ResolversParentTypes['DeleteCaseCategoryPayload']> = {
  __resolveType: TypeResolveFn<'CaseCategoryType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteCaseCategorySetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCaseCategorySetPayload'] = ResolversParentTypes['DeleteCaseCategorySetPayload']> = {
  __resolveType: TypeResolveFn<'CaseCategorySetType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteCasePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteCasePayload'] = ResolversParentTypes['DeleteCasePayload']> = {
  __resolveType: TypeResolveFn<'CaseType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteClientPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteClientPayload'] = ResolversParentTypes['DeleteClientPayload']> = {
  __resolveType: TypeResolveFn<'ClientType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteConsumablePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteConsumablePayload'] = ResolversParentTypes['DeleteConsumablePayload']> = {
  __resolveType: TypeResolveFn<'ConsumableType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteDrugPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteDrugPayload'] = ResolversParentTypes['DeleteDrugPayload']> = {
  __resolveType: TypeResolveFn<'DrugType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteEventKindPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteEventKindPayload'] = ResolversParentTypes['DeleteEventKindPayload']> = {
  __resolveType: TypeResolveFn<'EventKindType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteEventPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteEventPayload'] = ResolversParentTypes['DeleteEventPayload']> = {
  __resolveType: TypeResolveFn<'EventType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteFileNodePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteFileNodePayload'] = ResolversParentTypes['DeleteFileNodePayload']> = {
  __resolveType: TypeResolveFn<'FileNodeType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteHospitalPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteHospitalPayload'] = ResolversParentTypes['DeleteHospitalPayload']> = {
  __resolveType: TypeResolveFn<'HospitalType' | 'OperationInfo', ParentType, ContextType>;
};

export type DeleteQualificationDocumentPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteQualificationDocumentPayload'] = ResolversParentTypes['DeleteQualificationDocumentPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationDocumentType', ParentType, ContextType>;
};

export type DeleteQualificationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteQualificationPayload'] = ResolversParentTypes['DeleteQualificationPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationType', ParentType, ContextType>;
};

export type DeleteRegionPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteRegionPayload'] = ResolversParentTypes['DeleteRegionPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'RegionType', ParentType, ContextType>;
};

export type DeleteResourceCategoryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteResourceCategoryPayload'] = ResolversParentTypes['DeleteResourceCategoryPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ResourceCategoryType', ParentType, ContextType>;
};

export type DeleteResourcePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteResourcePayload'] = ResolversParentTypes['DeleteResourcePayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ResourceType', ParentType, ContextType>;
};

export type DeleteSkillsetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteSkillsetPayload'] = ResolversParentTypes['DeleteSkillsetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'SkillsetType', ParentType, ContextType>;
};

export type DeleteTextTemplatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteTextTemplatePayload'] = ResolversParentTypes['DeleteTextTemplatePayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TextTemplateType', ParentType, ContextType>;
};

export type DeleteTimeSheetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteTimeSheetPayload'] = ResolversParentTypes['DeleteTimeSheetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TimeSheetType', ParentType, ContextType>;
};

export type DeleteUserPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeleteUserPayload'] = ResolversParentTypes['DeleteUserPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'UserType', ParentType, ContextType>;
};

export type DjangoFileTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DjangoFileType'] = ResolversParentTypes['DjangoFileType']> = {
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  path?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  url?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugBrandNameTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugBrandNameType'] = ResolversParentTypes['DrugBrandNameType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugContraindicationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugContraindicationType'] = ResolversParentTypes['DrugContraindicationType']> = {
  contraindication?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugIndicationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugIndicationType'] = ResolversParentTypes['DrugIndicationType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  indication?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugPrecautionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugPrecautionType'] = ResolversParentTypes['DrugPrecautionType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  precaution?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugSideEffectTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugSideEffectType'] = ResolversParentTypes['DrugSideEffectType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  sideEffect?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugType'] = ResolversParentTypes['DrugType']> = {
  brandNames?: Resolver<Array<ResolversTypes['DrugBrandNameType']>, ParentType, ContextType>;
  contraindications?: Resolver<Array<ResolversTypes['DrugContraindicationType']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  indications?: Resolver<Array<ResolversTypes['DrugIndicationType']>, ParentType, ContextType>;
  metabolism?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pharmacology?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  precautions?: Resolver<Array<ResolversTypes['DrugPrecautionType']>, ParentType, ContextType>;
  presentation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  routeOfAdministration?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  sideEffects?: Resolver<Array<ResolversTypes['DrugSideEffectType']>, ParentType, ContextType>;
  specialNotes?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugTypeConnection'] = ResolversParentTypes['DrugTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['DrugTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DrugTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['DrugTypeEdge'] = ResolversParentTypes['DrugTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['DrugType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventConsumableTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventConsumableType'] = ResolversParentTypes['EventConsumableType']> = {
  consumable?: Resolver<ResolversTypes['ConsumableType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventConsumablesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventConsumablesType'] = ResolversParentTypes['EventConsumablesType']> = {
  consumables?: Resolver<Array<ResolversTypes['EventConsumableType']>, ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Decimal'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventInterestStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventInterestStatusType'] = ResolversParentTypes['EventInterestStatusType']> = {
  status?: Resolver<ResolversTypes['EventInterestStatus'], ParentType, ContextType>;
  totalInterests?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventKindTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventKindType'] = ResolversParentTypes['EventKindType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventKindTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventKindTypeConnection'] = ResolversParentTypes['EventKindTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['EventKindTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventKindTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventKindTypeEdge'] = ResolversParentTypes['EventKindTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EventKindType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventScheduleStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventScheduleStatusType'] = ResolversParentTypes['EventScheduleStatusType']> = {
  approvedPositions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EventScheduleStatus'], ParentType, ContextType>;
  totalPositions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventType'] = ResolversParentTypes['EventType']> = {
  acuityDistribution?: Resolver<ResolversTypes['DatasetsType'], ParentType, ContextType>;
  address?: Resolver<ResolversTypes['AddressType'], ParentType, ContextType>;
  caseCategorySet?: Resolver<Maybe<ResolversTypes['CaseCategorySetType']>, ParentType, ContextType>;
  casesOverTime?: Resolver<ResolversTypes['DatasetsType'], ParentType, ContextType>;
  client?: Resolver<Maybe<ResolversTypes['ClientType']>, ParentType, ContextType>;
  clientConfirmationSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  clientRequests?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  contact?: Resolver<Maybe<ResolversTypes['ContactType']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  duties?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  empSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  equipmentInstructions?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  eventConsumables?: Resolver<ResolversTypes['EventConsumablesType'], ParentType, ContextType>;
  eventKind?: Resolver<Maybe<ResolversTypes['EventKindType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  interestStatus?: Resolver<ResolversTypes['EventInterestStatusType'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nearestHospital?: Resolver<Maybe<ResolversTypes['HospitalType']>, ParentType, ContextType>;
  otherEquipment?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  otherInformation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  patrons?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  postEventReportSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  resourceAllocations?: Resolver<Array<ResolversTypes['ResourceAllocationType']>, ParentType, ContextType, Partial<EventTypeResourceAllocationsArgs>>;
  scheduleStatus?: Resolver<ResolversTypes['EventScheduleStatusType'], ParentType, ContextType>;
  sempSent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  shifts?: Resolver<Array<ResolversTypes['ShiftType']>, ParentType, ContextType, Partial<EventTypeShiftsArgs>>;
  staffBriefing?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['EventStatus'], ParentType, ContextType>;
  topCaseCategories?: Resolver<Array<ResolversTypes['TopCaseCategoryType']>, ParentType, ContextType>;
  totalCases?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalUnauditedCases?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventTypeConnection'] = ResolversParentTypes['EventTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['EventTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventTypeEdge'] = ResolversParentTypes['EventTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ExpectedErrorScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ExpectedError'], any> {
  name: 'ExpectedError';
}

export type FileNodeTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileNodeType'] = ResolversParentTypes['FileNodeType']> = {
  children?: Resolver<Array<ResolversTypes['FileNodeType']>, ParentType, ContextType, Partial<FileNodeTypeChildrenArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  filePath?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fileType?: Resolver<ResolversTypes['FileNodeKind'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['FileNodeType']>, ParentType, ContextType>;
  parents?: Resolver<Array<ResolversTypes['FileNodeType']>, ParentType, ContextType>;
  slug?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileNodeTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileNodeTypeConnection'] = ResolversParentTypes['FileNodeTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['FileNodeTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FileNodeTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['FileNodeTypeEdge'] = ResolversParentTypes['FileNodeTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['FileNodeType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface GlobalIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['GlobalID'], any> {
  name: 'GlobalID';
}

export type GraphDataTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GraphDataType'] = ResolversParentTypes['GraphDataType']> = {
  backgroundColor?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  data?: Resolver<Array<ResolversTypes['Int']>, ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HospitalTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HospitalType'] = ResolversParentTypes['HospitalType']> = {
  address?: Resolver<Maybe<ResolversTypes['AddressType']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType, Partial<HospitalTypeDistanceArgs>>;
  facilities?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HospitalTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['HospitalTypeConnection'] = ResolversParentTypes['HospitalTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['HospitalTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type HospitalTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['HospitalTypeEdge'] = ResolversParentTypes['HospitalTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['HospitalType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export type LocalityTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocalityType'] = ResolversParentTypes['LocalityType']> = {
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['StateType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  approveEoi?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationApproveEoiArgs, 'shiftAssignmentIds' | 'shiftId'>>;
  approveTimeSheet?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationApproveTimeSheetArgs, 'pks'>>;
  assignShift?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationAssignShiftArgs, 'shiftId' | 'userId'>>;
  createAccreditation?: Resolver<ResolversTypes['CreateAccreditationPayload'], ParentType, ContextType, RequireFields<MutationCreateAccreditationArgs, 'data'>>;
  createAdviceTemplate?: Resolver<ResolversTypes['CreateAdviceTemplatePayload'], ParentType, ContextType, RequireFields<MutationCreateAdviceTemplateArgs, 'data'>>;
  createCase?: Resolver<ResolversTypes['CreateCasePayload'], ParentType, ContextType, RequireFields<MutationCreateCaseArgs, 'data'>>;
  createCaseAudit?: Resolver<ResolversTypes['CreateCaseAuditPayload'], ParentType, ContextType, RequireFields<MutationCreateCaseAuditArgs, 'data'>>;
  createCaseCategory?: Resolver<ResolversTypes['CreateCaseCategoryPayload'], ParentType, ContextType, RequireFields<MutationCreateCaseCategoryArgs, 'data'>>;
  createCaseCategorySet?: Resolver<ResolversTypes['CreateCaseCategorySetPayload'], ParentType, ContextType, RequireFields<MutationCreateCaseCategorySetArgs, 'data'>>;
  createClient?: Resolver<ResolversTypes['CreateClientPayload'], ParentType, ContextType, RequireFields<MutationCreateClientArgs, 'data'>>;
  createConsumable?: Resolver<ResolversTypes['CreateConsumablePayload'], ParentType, ContextType, RequireFields<MutationCreateConsumableArgs, 'data'>>;
  createDeleteQualificationAudit?: Resolver<ResolversTypes['CreateDeleteQualificationAuditPayload'], ParentType, ContextType, RequireFields<MutationCreateDeleteQualificationAuditArgs, 'data'>>;
  createDrug?: Resolver<ResolversTypes['CreateDrugPayload'], ParentType, ContextType, RequireFields<MutationCreateDrugArgs, 'data'>>;
  createEvent?: Resolver<ResolversTypes['CreateEventPayload'], ParentType, ContextType, RequireFields<MutationCreateEventArgs, 'data'>>;
  createEventKind?: Resolver<ResolversTypes['CreateEventKindPayload'], ParentType, ContextType, RequireFields<MutationCreateEventKindArgs, 'data'>>;
  createFileNode?: Resolver<ResolversTypes['CreateFileNodePayload'], ParentType, ContextType, RequireFields<MutationCreateFileNodeArgs, 'data'>>;
  createHospital?: Resolver<ResolversTypes['CreateHospitalPayload'], ParentType, ContextType, RequireFields<MutationCreateHospitalArgs, 'data'>>;
  createMyTimeSheet?: Resolver<ResolversTypes['CreateMyTimeSheetPayload'], ParentType, ContextType, RequireFields<MutationCreateMyTimeSheetArgs, 'data'>>;
  createQualification?: Resolver<ResolversTypes['CreateQualificationPayload'], ParentType, ContextType, RequireFields<MutationCreateQualificationArgs, 'data'>>;
  createQualificationDocument?: Resolver<ResolversTypes['CreateQualificationDocumentPayload'], ParentType, ContextType, RequireFields<MutationCreateQualificationDocumentArgs, 'data'>>;
  createRegion?: Resolver<ResolversTypes['CreateRegionPayload'], ParentType, ContextType, RequireFields<MutationCreateRegionArgs, 'data'>>;
  createResource?: Resolver<ResolversTypes['CreateResourcePayload'], ParentType, ContextType, RequireFields<MutationCreateResourceArgs, 'data'>>;
  createResourceCategory?: Resolver<ResolversTypes['CreateResourceCategoryPayload'], ParentType, ContextType, RequireFields<MutationCreateResourceCategoryArgs, 'data'>>;
  createSkillset?: Resolver<ResolversTypes['CreateSkillsetPayload'], ParentType, ContextType, RequireFields<MutationCreateSkillsetArgs, 'data'>>;
  createTextTemplate?: Resolver<ResolversTypes['CreateTextTemplatePayload'], ParentType, ContextType, RequireFields<MutationCreateTextTemplateArgs, 'data'>>;
  createTimeSheet?: Resolver<ResolversTypes['CreateTimeSheetPayload'], ParentType, ContextType, RequireFields<MutationCreateTimeSheetArgs, 'data'>>;
  createUser?: Resolver<ResolversTypes['CreateUserPayload'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'data'>>;
  createWithdrawEoiRequest?: Resolver<ResolversTypes['CreateWithdrawEoiRequestPayload'], ParentType, ContextType, RequireFields<MutationCreateWithdrawEoiRequestArgs, 'data'>>;
  deleteAccreditation?: Resolver<ResolversTypes['DeleteAccreditationPayload'], ParentType, ContextType, RequireFields<MutationDeleteAccreditationArgs, 'data'>>;
  deleteAdviceTemplate?: Resolver<ResolversTypes['DeleteAdviceTemplatePayload'], ParentType, ContextType, RequireFields<MutationDeleteAdviceTemplateArgs, 'data'>>;
  deleteCase?: Resolver<ResolversTypes['DeleteCasePayload'], ParentType, ContextType, RequireFields<MutationDeleteCaseArgs, 'data'>>;
  deleteCaseCategory?: Resolver<ResolversTypes['DeleteCaseCategoryPayload'], ParentType, ContextType, RequireFields<MutationDeleteCaseCategoryArgs, 'data'>>;
  deleteCaseCategorySet?: Resolver<ResolversTypes['DeleteCaseCategorySetPayload'], ParentType, ContextType, RequireFields<MutationDeleteCaseCategorySetArgs, 'data'>>;
  deleteClient?: Resolver<ResolversTypes['DeleteClientPayload'], ParentType, ContextType, RequireFields<MutationDeleteClientArgs, 'data'>>;
  deleteConsumable?: Resolver<ResolversTypes['DeleteConsumablePayload'], ParentType, ContextType, RequireFields<MutationDeleteConsumableArgs, 'data'>>;
  deleteDrug?: Resolver<ResolversTypes['DeleteDrugPayload'], ParentType, ContextType, RequireFields<MutationDeleteDrugArgs, 'data'>>;
  deleteEois?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationDeleteEoisArgs, 'shiftAssignmentIds'>>;
  deleteEvent?: Resolver<ResolversTypes['DeleteEventPayload'], ParentType, ContextType, RequireFields<MutationDeleteEventArgs, 'data'>>;
  deleteEventKind?: Resolver<ResolversTypes['DeleteEventKindPayload'], ParentType, ContextType, RequireFields<MutationDeleteEventKindArgs, 'data'>>;
  deleteFileNode?: Resolver<ResolversTypes['DeleteFileNodePayload'], ParentType, ContextType, RequireFields<MutationDeleteFileNodeArgs, 'data'>>;
  deleteHospital?: Resolver<ResolversTypes['DeleteHospitalPayload'], ParentType, ContextType, RequireFields<MutationDeleteHospitalArgs, 'data'>>;
  deleteQualification?: Resolver<ResolversTypes['DeleteQualificationPayload'], ParentType, ContextType, RequireFields<MutationDeleteQualificationArgs, 'data'>>;
  deleteQualificationDocument?: Resolver<ResolversTypes['DeleteQualificationDocumentPayload'], ParentType, ContextType, RequireFields<MutationDeleteQualificationDocumentArgs, 'data'>>;
  deleteRegion?: Resolver<ResolversTypes['DeleteRegionPayload'], ParentType, ContextType, RequireFields<MutationDeleteRegionArgs, 'data'>>;
  deleteResource?: Resolver<ResolversTypes['DeleteResourcePayload'], ParentType, ContextType, RequireFields<MutationDeleteResourceArgs, 'data'>>;
  deleteResourceCategory?: Resolver<ResolversTypes['DeleteResourceCategoryPayload'], ParentType, ContextType, RequireFields<MutationDeleteResourceCategoryArgs, 'data'>>;
  deleteSkillset?: Resolver<ResolversTypes['DeleteSkillsetPayload'], ParentType, ContextType, RequireFields<MutationDeleteSkillsetArgs, 'data'>>;
  deleteTextTemplate?: Resolver<ResolversTypes['DeleteTextTemplatePayload'], ParentType, ContextType, RequireFields<MutationDeleteTextTemplateArgs, 'data'>>;
  deleteTimeSheet?: Resolver<ResolversTypes['DeleteTimeSheetPayload'], ParentType, ContextType, RequireFields<MutationDeleteTimeSheetArgs, 'data'>>;
  deleteUser?: Resolver<ResolversTypes['DeleteUserPayload'], ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'data'>>;
  mergeEventKinds?: Resolver<ResolversTypes['EventKindType'], ParentType, ContextType, RequireFields<MutationMergeEventKindsArgs, 'eventKindIds' | 'name'>>;
  readNotifications?: Resolver<ResolversTypes['NotificationResponseType'], ParentType, ContextType, RequireFields<MutationReadNotificationsArgs, 'notificationIds'>>;
  refreshToken?: Resolver<ResolversTypes['ObtainJSONWebTokenType'], ParentType, ContextType, RequireFields<MutationRefreshTokenArgs, 'refreshToken'>>;
  registerEoi?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationRegisterEoiArgs, 'shiftIds'>>;
  rejectEoi?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationRejectEoiArgs, 'shiftAssignmentIds' | 'shiftId'>>;
  rejectPendingEois?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationRejectPendingEoisArgs, 'shiftId'>>;
  reorder?: Resolver<Array<ResolversTypes['BaseReorderableInterface']>, ParentType, ContextType, RequireFields<MutationReorderArgs, 'ids'>>;
  resetPassword?: Resolver<ResolversTypes['MutationNormalOutput'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'newPassword1' | 'newPassword2' | 'token'>>;
  revertEoi?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationRevertEoiArgs, 'shiftAssignmentIds' | 'shiftId'>>;
  revokeToken?: Resolver<ResolversTypes['RevokeRefreshTokenType'], ParentType, ContextType, RequireFields<MutationRevokeTokenArgs, 'refreshToken'>>;
  sendEventEmp?: Resolver<ResolversTypes['EventType'], ParentType, ContextType, RequireFields<MutationSendEventEmpArgs, 'empConfig' | 'eventId' | 'sempConfig' | 'sendEmp' | 'sendStaffEmp'>>;
  sendPostReport?: Resolver<ResolversTypes['EventType'], ParentType, ContextType, RequireFields<MutationSendPostReportArgs, 'emailContent' | 'eventId'>>;
  sendSmsEmail?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendSmsEmailArgs, 'data'>>;
  sendUserActivation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSendUserActivationArgs, 'pks'>>;
  sendUserPasswordReset?: Resolver<ResolversTypes['MutationNormalOutput'], ParentType, ContextType, RequireFields<MutationSendUserPasswordResetArgs, 'email'>>;
  setEventStatus?: Resolver<ResolversTypes['EventType'], ParentType, ContextType, RequireFields<MutationSetEventStatusArgs, 'eventId' | 'sendClientConfirmation' | 'sendNotifications' | 'status'>>;
  setPassword?: Resolver<ResolversTypes['MutationNormalOutput'], ParentType, ContextType, RequireFields<MutationSetPasswordArgs, 'newPassword1' | 'newPassword2' | 'token'>>;
  setShiftDutyManager?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationSetShiftDutyManagerArgs, 'assignmentId' | 'shiftId'>>;
  tokenAuth?: Resolver<ResolversTypes['ObtainJSONWebTokenType'], ParentType, ContextType, RequireFields<MutationTokenAuthArgs, 'email' | 'password'>>;
  twoFactorAuth?: Resolver<ResolversTypes['ObtainJSONWebTokenType'], ParentType, ContextType, RequireFields<MutationTwoFactorAuthArgs, 'oneTimePassword' | 'token'>>;
  twoFactorProvision?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationTwoFactorProvisionArgs, 'otp' | 'secret'>>;
  twoFactorReset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  updateAccount?: Resolver<ResolversTypes['UpdateAccountPayload'], ParentType, ContextType, RequireFields<MutationUpdateAccountArgs, 'data'>>;
  updateAccreditation?: Resolver<ResolversTypes['UpdateAccreditationPayload'], ParentType, ContextType, RequireFields<MutationUpdateAccreditationArgs, 'data'>>;
  updateAdviceTemplate?: Resolver<ResolversTypes['UpdateAdviceTemplatePayload'], ParentType, ContextType, RequireFields<MutationUpdateAdviceTemplateArgs, 'data'>>;
  updateCase?: Resolver<ResolversTypes['UpdateCasePayload'], ParentType, ContextType, RequireFields<MutationUpdateCaseArgs, 'data'>>;
  updateCaseCategory?: Resolver<ResolversTypes['UpdateCaseCategoryPayload'], ParentType, ContextType, RequireFields<MutationUpdateCaseCategoryArgs, 'data'>>;
  updateCaseCategorySet?: Resolver<ResolversTypes['UpdateCaseCategorySetPayload'], ParentType, ContextType, RequireFields<MutationUpdateCaseCategorySetArgs, 'data'>>;
  updateClient?: Resolver<ResolversTypes['UpdateClientPayload'], ParentType, ContextType, RequireFields<MutationUpdateClientArgs, 'data'>>;
  updateConsumable?: Resolver<ResolversTypes['UpdateConsumablePayload'], ParentType, ContextType, RequireFields<MutationUpdateConsumableArgs, 'data'>>;
  updateDrug?: Resolver<ResolversTypes['UpdateDrugPayload'], ParentType, ContextType, RequireFields<MutationUpdateDrugArgs, 'data'>>;
  updateEvent?: Resolver<ResolversTypes['UpdateEventPayload'], ParentType, ContextType, RequireFields<MutationUpdateEventArgs, 'data'>>;
  updateEventKind?: Resolver<ResolversTypes['UpdateEventKindPayload'], ParentType, ContextType, RequireFields<MutationUpdateEventKindArgs, 'data'>>;
  updateHospital?: Resolver<ResolversTypes['UpdateHospitalPayload'], ParentType, ContextType, RequireFields<MutationUpdateHospitalArgs, 'data'>>;
  updateMyTimeSheet?: Resolver<ResolversTypes['UpdateMyTimeSheetPayload'], ParentType, ContextType, RequireFields<MutationUpdateMyTimeSheetArgs, 'data'>>;
  updateQualification?: Resolver<ResolversTypes['UpdateQualificationPayload'], ParentType, ContextType, RequireFields<MutationUpdateQualificationArgs, 'data'>>;
  updateQualificationDocument?: Resolver<ResolversTypes['UpdateQualificationDocumentPayload'], ParentType, ContextType, RequireFields<MutationUpdateQualificationDocumentArgs, 'data'>>;
  updateRegion?: Resolver<ResolversTypes['UpdateRegionPayload'], ParentType, ContextType, RequireFields<MutationUpdateRegionArgs, 'data'>>;
  updateResource?: Resolver<ResolversTypes['UpdateResourcePayload'], ParentType, ContextType, RequireFields<MutationUpdateResourceArgs, 'data'>>;
  updateResourceCategory?: Resolver<ResolversTypes['UpdateResourceCategoryPayload'], ParentType, ContextType, RequireFields<MutationUpdateResourceCategoryArgs, 'data'>>;
  updateSkillset?: Resolver<ResolversTypes['UpdateSkillsetPayload'], ParentType, ContextType, RequireFields<MutationUpdateSkillsetArgs, 'data'>>;
  updateTenant?: Resolver<ResolversTypes['UpdateTenantPayload'], ParentType, ContextType, RequireFields<MutationUpdateTenantArgs, 'data'>>;
  updateTextTemplate?: Resolver<ResolversTypes['UpdateTextTemplatePayload'], ParentType, ContextType, RequireFields<MutationUpdateTextTemplateArgs, 'data'>>;
  updateTimeSheet?: Resolver<ResolversTypes['UpdateTimeSheetPayload'], ParentType, ContextType, RequireFields<MutationUpdateTimeSheetArgs, 'data'>>;
  updateUser?: Resolver<ResolversTypes['UpdateUserPayload'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'data'>>;
  updateWithdrawEoiRequest?: Resolver<ResolversTypes['UpdateWithdrawEoiRequestPayload'], ParentType, ContextType, RequireFields<MutationUpdateWithdrawEoiRequestArgs, 'data'>>;
  verifyToken?: Resolver<ResolversTypes['VerifyTokenType'], ParentType, ContextType, RequireFields<MutationVerifyTokenArgs, 'token'>>;
  withdrawEoi?: Resolver<ResolversTypes['ShiftEOIResponseType'], ParentType, ContextType, RequireFields<MutationWithdrawEoiArgs, 'shiftAssignmentIds'>>;
};

export type MutationNormalOutputResolvers<ContextType = any, ParentType extends ResolversParentTypes['MutationNormalOutput'] = ResolversParentTypes['MutationNormalOutput']> = {
  errors?: Resolver<Maybe<ResolversTypes['ExpectedError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Node'] = ResolversParentTypes['Node']> = {
  __resolveType: TypeResolveFn<'AccreditationType' | 'AdviceTemplateType' | 'CaseAttachmentType' | 'CaseAttendantType' | 'CaseAuditType' | 'CaseAxType' | 'CaseCategorySetType' | 'CaseCategoryType' | 'CaseConsumableType' | 'CaseCustomAxType' | 'CaseECGAxType' | 'CaseGCSAxType' | 'CaseMentalAxType' | 'CaseNeurovascularAxType' | 'CasePainAxType' | 'CasePatientPMHxAllergyType' | 'CasePatientPMHxHistoryType' | 'CasePatientPMHxMedicationType' | 'CasePrimaryAxType' | 'CaseROSIERAxType' | 'CaseRespiratoryAxType' | 'CaseRxAirwayType' | 'CaseRxBreathingVentilationType' | 'CaseRxCPRType' | 'CaseRxConsultType' | 'CaseRxDefibrillationType' | 'CaseRxFirstAidType' | 'CaseRxIVAccessType' | 'CaseRxMajorTraumaType' | 'CaseRxMedicationsType' | 'CaseRxOtherType' | 'CaseRxOxygenType' | 'CaseSketchType' | 'CaseSpinalAxType' | 'CaseTreatmentType' | 'CaseType' | 'CaseVitalsAxType' | 'ClientType' | 'ConsumableType' | 'ContactType' | 'DrugBrandNameType' | 'DrugContraindicationType' | 'DrugIndicationType' | 'DrugPrecautionType' | 'DrugSideEffectType' | 'DrugType' | 'EventKindType' | 'EventType' | 'FileNodeType' | 'HospitalType' | 'NotificationType' | 'PatientType' | 'QualificationDocumentAuditType' | 'QualificationDocumentType' | 'QualificationRequirementType' | 'QualificationType' | 'RegionType' | 'ResourceAllocationType' | 'ResourceCategoryType' | 'ResourceType' | 'ShiftAssignmentType' | 'ShiftAssignmentWithdrawRequestType' | 'ShiftPositionType' | 'ShiftType' | 'SkillsetType' | 'TextTemplateType' | 'TimeSheetType' | 'UserType', ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
};

export type NotificationCreatedTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationCreatedType'] = ResolversParentTypes['NotificationCreatedType']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  notification?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  totalUnreadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResponseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationResponseType'] = ResolversParentTypes['NotificationResponseType']> = {
  notifications?: Resolver<Array<ResolversTypes['NotificationType']>, ParentType, ContextType>;
  totalUnreadCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationType'] = ResolversParentTypes['NotificationType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  read?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationTypeConnection'] = ResolversParentTypes['NotificationTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['NotificationTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['NotificationTypeEdge'] = ResolversParentTypes['NotificationTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['NotificationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ObtainJsonWebTokenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ObtainJSONWebTokenType'] = ResolversParentTypes['ObtainJSONWebTokenType']> = {
  errors?: Resolver<Maybe<ResolversTypes['ExpectedError']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['RefreshTokenType']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['TokenType']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperationInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationInfo'] = ResolversParentTypes['OperationInfo']> = {
  messages?: Resolver<Array<ResolversTypes['OperationMessage']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OperationMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['OperationMessage'] = ResolversParentTypes['OperationMessage']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kind?: Resolver<ResolversTypes['OperationMessageKind'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type OutputInterfaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['OutputInterface'] = ResolversParentTypes['OutputInterface']> = {
  __resolveType: TypeResolveFn<'ObtainJSONWebTokenType' | 'VerifyTokenType', ParentType, ContextType>;
  errors?: Resolver<Maybe<ResolversTypes['ExpectedError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PatientTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PatientType'] = ResolversParentTypes['PatientType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  patientAddress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientAddressUto?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patientDob?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  patientDobAge?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientDobUseAge?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patientDobUto?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patientEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientGender?: Resolver<Maybe<ResolversTypes['PatientGender']>, ParentType, ContextType>;
  patientName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientNameUto?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  patientPhone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  patientPhoneUto?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  pmhxAllergies?: Resolver<Array<ResolversTypes['CasePatientPMHxAllergyType']>, ParentType, ContextType>;
  pmhxHistory?: Resolver<Array<ResolversTypes['CasePatientPMHxHistoryType']>, ParentType, ContextType>;
  pmhxMedications?: Resolver<Array<ResolversTypes['CasePatientPMHxMedicationType']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']> = {
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permission?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionGroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionGroup'] = ResolversParentTypes['PermissionGroup']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  label?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['Permission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationDocumentAuditTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationDocumentAuditType'] = ResolversParentTypes['QualificationDocumentAuditType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  document?: Resolver<ResolversTypes['QualificationDocumentType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outcome?: Resolver<ResolversTypes['QualificationDocumentAuditOutcome'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationDocumentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationDocumentType'] = ResolversParentTypes['QualificationDocumentType']> = {
  audits?: Resolver<Array<ResolversTypes['QualificationDocumentAuditType']>, ParentType, ContextType, Partial<QualificationDocumentTypeAuditsArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expiryStatus?: Resolver<ResolversTypes['QualificationDocumentExpiryStatus'], ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  issuedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  qualification?: Resolver<ResolversTypes['QualificationType'], ParentType, ContextType>;
  ref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['QualificationDocumentStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationDocumentTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationDocumentTypeConnection'] = ResolversParentTypes['QualificationDocumentTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['QualificationDocumentTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationDocumentTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationDocumentTypeEdge'] = ResolversParentTypes['QualificationDocumentTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['QualificationDocumentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationRequirementTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationRequirementType'] = ResolversParentTypes['QualificationRequirementType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  qualifications?: Resolver<Array<ResolversTypes['QualificationType']>, ParentType, ContextType, Partial<QualificationRequirementTypeQualificationsArgs>>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  validationType?: Resolver<ResolversTypes['ValidationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationRequirementTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationRequirementTypeConnection'] = ResolversParentTypes['QualificationRequirementTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['QualificationRequirementTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationRequirementTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationRequirementTypeEdge'] = ResolversParentTypes['QualificationRequirementTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['QualificationRequirementType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationType'] = ResolversParentTypes['QualificationType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expiryInterval?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  expiryType?: Resolver<ResolversTypes['QualificationExpiryType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isMissing?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPending?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isRequired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationTypeConnection'] = ResolversParentTypes['QualificationTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['QualificationTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QualificationTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QualificationTypeEdge'] = ResolversParentTypes['QualificationTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['QualificationType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  accreditation?: Resolver<ResolversTypes['AccreditationType'], ParentType, ContextType, RequireFields<QueryAccreditationArgs, 'pk'>>;
  accreditations?: Resolver<ResolversTypes['AccreditationTypeConnection'], ParentType, ContextType, RequireFields<QueryAccreditationsArgs, 'after' | 'before' | 'first' | 'last'>>;
  activeTimeSheet?: Resolver<Maybe<ResolversTypes['TimeSheetType']>, ParentType, ContextType>;
  adviceTemplate?: Resolver<ResolversTypes['AdviceTemplateType'], ParentType, ContextType, RequireFields<QueryAdviceTemplateArgs, 'pk'>>;
  adviceTemplates?: Resolver<ResolversTypes['AdviceTemplateTypeConnection'], ParentType, ContextType, RequireFields<QueryAdviceTemplatesArgs, 'after' | 'before' | 'first' | 'last'>>;
  case?: Resolver<ResolversTypes['CaseType'], ParentType, ContextType, RequireFields<QueryCaseArgs, 'pk'>>;
  caseCategories?: Resolver<ResolversTypes['CaseCategoryTypeConnection'], ParentType, ContextType, RequireFields<QueryCaseCategoriesArgs, 'after' | 'before' | 'first' | 'last'>>;
  caseCategory?: Resolver<ResolversTypes['CaseCategoryType'], ParentType, ContextType, RequireFields<QueryCaseCategoryArgs, 'pk'>>;
  caseCategorySet?: Resolver<ResolversTypes['CaseCategorySetType'], ParentType, ContextType, RequireFields<QueryCaseCategorySetArgs, 'pk'>>;
  caseCategorySets?: Resolver<ResolversTypes['CaseCategorySetTypeConnection'], ParentType, ContextType, RequireFields<QueryCaseCategorySetsArgs, 'after' | 'before' | 'first' | 'last'>>;
  cases?: Resolver<ResolversTypes['CaseTypeConnection'], ParentType, ContextType, RequireFields<QueryCasesArgs, 'after' | 'before' | 'first' | 'last'>>;
  client?: Resolver<ResolversTypes['ClientType'], ParentType, ContextType, RequireFields<QueryClientArgs, 'pk'>>;
  clients?: Resolver<ResolversTypes['ClientTypeConnection'], ParentType, ContextType, RequireFields<QueryClientsArgs, 'after' | 'before' | 'first' | 'last'>>;
  consumable?: Resolver<ResolversTypes['ConsumableType'], ParentType, ContextType, RequireFields<QueryConsumableArgs, 'pk'>>;
  consumables?: Resolver<ResolversTypes['ConsumableTypeConnection'], ParentType, ContextType, RequireFields<QueryConsumablesArgs, 'after' | 'before' | 'first' | 'last'>>;
  drug?: Resolver<ResolversTypes['DrugType'], ParentType, ContextType, RequireFields<QueryDrugArgs, 'pk'>>;
  drugs?: Resolver<ResolversTypes['DrugTypeConnection'], ParentType, ContextType, RequireFields<QueryDrugsArgs, 'after' | 'before' | 'first' | 'last'>>;
  event?: Resolver<ResolversTypes['EventType'], ParentType, ContextType, RequireFields<QueryEventArgs, 'pk'>>;
  eventKind?: Resolver<ResolversTypes['EventKindType'], ParentType, ContextType, RequireFields<QueryEventKindArgs, 'pk'>>;
  eventKinds?: Resolver<ResolversTypes['EventKindTypeConnection'], ParentType, ContextType, RequireFields<QueryEventKindsArgs, 'after' | 'before' | 'first' | 'last'>>;
  events?: Resolver<ResolversTypes['EventTypeConnection'], ParentType, ContextType, RequireFields<QueryEventsArgs, 'after' | 'before' | 'first' | 'last'>>;
  fileNode?: Resolver<ResolversTypes['FileNodeType'], ParentType, ContextType, RequireFields<QueryFileNodeArgs, 'pk'>>;
  fileNodes?: Resolver<ResolversTypes['FileNodeTypeConnection'], ParentType, ContextType, RequireFields<QueryFileNodesArgs, 'after' | 'before' | 'first' | 'last'>>;
  hospital?: Resolver<ResolversTypes['HospitalType'], ParentType, ContextType, RequireFields<QueryHospitalArgs, 'pk'>>;
  hospitals?: Resolver<ResolversTypes['HospitalTypeConnection'], ParentType, ContextType, RequireFields<QueryHospitalsArgs, 'after' | 'before' | 'first' | 'last'>>;
  me?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  myCases?: Resolver<ResolversTypes['CaseTypeConnection'], ParentType, ContextType, RequireFields<QueryMyCasesArgs, 'after' | 'before' | 'first' | 'last'>>;
  myShiftAssignments?: Resolver<ResolversTypes['ShiftAssignmentTypeConnection'], ParentType, ContextType, RequireFields<QueryMyShiftAssignmentsArgs, 'after' | 'before' | 'first' | 'last'>>;
  myTimeSheets?: Resolver<ResolversTypes['TimeSheetTypeConnection'], ParentType, ContextType, RequireFields<QueryMyTimeSheetsArgs, 'after' | 'before' | 'first' | 'last'>>;
  notifications?: Resolver<ResolversTypes['NotificationTypeConnection'], ParentType, ContextType, RequireFields<QueryNotificationsArgs, 'after' | 'before' | 'first' | 'last'>>;
  permissions?: Resolver<Array<ResolversTypes['AppPermissions']>, ParentType, ContextType>;
  qualification?: Resolver<ResolversTypes['QualificationType'], ParentType, ContextType, RequireFields<QueryQualificationArgs, 'pk'>>;
  qualificationDocument?: Resolver<ResolversTypes['QualificationDocumentType'], ParentType, ContextType, RequireFields<QueryQualificationDocumentArgs, 'pk'>>;
  qualificationDocuments?: Resolver<ResolversTypes['QualificationDocumentTypeConnection'], ParentType, ContextType, RequireFields<QueryQualificationDocumentsArgs, 'after' | 'before' | 'first' | 'last'>>;
  qualificationRequirements?: Resolver<ResolversTypes['QualificationRequirementTypeConnection'], ParentType, ContextType, RequireFields<QueryQualificationRequirementsArgs, 'after' | 'before' | 'first' | 'last'>>;
  qualifications?: Resolver<ResolversTypes['QualificationTypeConnection'], ParentType, ContextType, RequireFields<QueryQualificationsArgs, 'after' | 'before' | 'first' | 'last'>>;
  region?: Resolver<ResolversTypes['RegionType'], ParentType, ContextType, RequireFields<QueryRegionArgs, 'pk'>>;
  regions?: Resolver<ResolversTypes['RegionTypeConnection'], ParentType, ContextType, RequireFields<QueryRegionsArgs, 'after' | 'before' | 'first' | 'last'>>;
  resource?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType, RequireFields<QueryResourceArgs, 'pk'>>;
  resourceCategories?: Resolver<ResolversTypes['ResourceCategoryTypeConnection'], ParentType, ContextType, RequireFields<QueryResourceCategoriesArgs, 'after' | 'before' | 'first' | 'last'>>;
  resourceCategory?: Resolver<ResolversTypes['ResourceCategoryType'], ParentType, ContextType, RequireFields<QueryResourceCategoryArgs, 'pk'>>;
  resources?: Resolver<ResolversTypes['ResourceTypeConnection'], ParentType, ContextType, RequireFields<QueryResourcesArgs, 'after' | 'before' | 'first' | 'last'>>;
  searchForPatient?: Resolver<Maybe<ResolversTypes['PatientType']>, ParentType, ContextType, RequireFields<QuerySearchForPatientArgs, 'phone'>>;
  shift?: Resolver<ResolversTypes['ShiftType'], ParentType, ContextType, RequireFields<QueryShiftArgs, 'pk'>>;
  shiftAssignments?: Resolver<ResolversTypes['ShiftAssignmentTypeConnection'], ParentType, ContextType, RequireFields<QueryShiftAssignmentsArgs, 'after' | 'before' | 'first' | 'last'>>;
  shiftPosition?: Resolver<ResolversTypes['ShiftPositionType'], ParentType, ContextType, RequireFields<QueryShiftPositionArgs, 'pk'>>;
  shiftPositions?: Resolver<ResolversTypes['ShiftPositionTypeConnection'], ParentType, ContextType, RequireFields<QueryShiftPositionsArgs, 'after' | 'before' | 'first' | 'last'>>;
  shifts?: Resolver<ResolversTypes['ShiftTypeConnection'], ParentType, ContextType, RequireFields<QueryShiftsArgs, 'after' | 'before' | 'first' | 'last'>>;
  skillset?: Resolver<ResolversTypes['SkillsetType'], ParentType, ContextType, RequireFields<QuerySkillsetArgs, 'pk'>>;
  skillsets?: Resolver<ResolversTypes['SkillsetTypeConnection'], ParentType, ContextType, RequireFields<QuerySkillsetsArgs, 'after' | 'before' | 'first' | 'last'>>;
  slackChannels?: Resolver<Array<ResolversTypes['SlackChannelType']>, ParentType, ContextType>;
  tenant?: Resolver<Maybe<ResolversTypes['TenantType']>, ParentType, ContextType>;
  textTemplate?: Resolver<ResolversTypes['TextTemplateType'], ParentType, ContextType, RequireFields<QueryTextTemplateArgs, 'pk'>>;
  textTemplates?: Resolver<ResolversTypes['TextTemplateTypeConnection'], ParentType, ContextType, RequireFields<QueryTextTemplatesArgs, 'after' | 'before' | 'first' | 'last'>>;
  timeSheet?: Resolver<ResolversTypes['TimeSheetType'], ParentType, ContextType, RequireFields<QueryTimeSheetArgs, 'pk'>>;
  timeSheets?: Resolver<ResolversTypes['TimeSheetTypeConnection'], ParentType, ContextType, RequireFields<QueryTimeSheetsArgs, 'after' | 'before' | 'first' | 'last'>>;
  twoFactorProvision?: Resolver<ResolversTypes['TwoFactorProvision'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType, RequireFields<QueryUserArgs, 'pk'>>;
  users?: Resolver<ResolversTypes['UserTypeConnection'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'after' | 'before' | 'first' | 'last'>>;
};

export type RefreshTokenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RefreshTokenType'] = ResolversParentTypes['RefreshTokenType']> = {
  created?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  expiresAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  isExpired?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  revoked?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegionType'] = ResolversParentTypes['RegionType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegionTypeConnection'] = ResolversParentTypes['RegionTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['RegionTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RegionTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegionTypeEdge'] = ResolversParentTypes['RegionTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['RegionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceAllocationTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceAllocationType'] = ResolversParentTypes['ResourceAllocationType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  event?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resource?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceCategoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceCategoryType'] = ResolversParentTypes['ResourceCategoryType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resources?: Resolver<Array<ResolversTypes['ResourceType']>, ParentType, ContextType, Partial<ResourceCategoryTypeResourcesArgs>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceCategoryTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceCategoryTypeConnection'] = ResolversParentTypes['ResourceCategoryTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ResourceCategoryTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceCategoryTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceCategoryTypeEdge'] = ResolversParentTypes['ResourceCategoryTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ResourceCategoryType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceType'] = ResolversParentTypes['ResourceType']> = {
  category?: Resolver<ResolversTypes['ResourceCategoryType'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  isPersonal?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  resourceAllocations?: Resolver<Array<ResolversTypes['ResourceAllocationType']>, ParentType, ContextType, Partial<ResourceTypeResourceAllocationsArgs>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceTypeConnection'] = ResolversParentTypes['ResourceTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ResourceTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResourceTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ResourceTypeEdge'] = ResolversParentTypes['ResourceTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RevokeRefreshTokenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RevokeRefreshTokenType'] = ResolversParentTypes['RevokeRefreshTokenType']> = {
  errors?: Resolver<Maybe<ResolversTypes['ExpectedError']>, ParentType, ContextType>;
  refreshToken?: Resolver<Maybe<ResolversTypes['RefreshTokenType']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftAssignmentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftAssignmentType'] = ResolversParentTypes['ShiftAssignmentType']> = {
  conflicts?: Resolver<Array<ResolversTypes['ShiftAssignmentType']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  hasConflicts?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  shift?: Resolver<ResolversTypes['ShiftType'], ParentType, ContextType>;
  shiftPosition?: Resolver<ResolversTypes['ShiftPositionType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['AssignmentStatus'], ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  withdrawRequest?: Resolver<Maybe<ResolversTypes['ShiftAssignmentWithdrawRequestType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftAssignmentTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftAssignmentTypeConnection'] = ResolversParentTypes['ShiftAssignmentTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ShiftAssignmentTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftAssignmentTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftAssignmentTypeEdge'] = ResolversParentTypes['ShiftAssignmentTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ShiftAssignmentType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftAssignmentWithdrawRequestTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftAssignmentWithdrawRequestType'] = ResolversParentTypes['ShiftAssignmentWithdrawRequestType']> = {
  assignment?: Resolver<ResolversTypes['ShiftAssignmentType'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  message?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['WithdrawRequestStatus'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftEoiResponseTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftEOIResponseType'] = ResolversParentTypes['ShiftEOIResponseType']> = {
  operationInfo?: Resolver<Maybe<ResolversTypes['OperationInfo']>, ParentType, ContextType>;
  shift?: Resolver<Maybe<ResolversTypes['ShiftType']>, ParentType, ContextType>;
  shiftAssignments?: Resolver<Maybe<Array<ResolversTypes['ShiftAssignmentType']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftPositionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftPositionType'] = ResolversParentTypes['ShiftPositionType']> = {
  assignments?: Resolver<Array<ResolversTypes['ShiftAssignmentType']>, ParentType, ContextType, Partial<ShiftPositionTypeAssignmentsArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  interestStatus?: Resolver<ResolversTypes['EventInterestStatusType'], ParentType, ContextType>;
  positions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  requiredAccreditations?: Resolver<Array<ResolversTypes['AccreditationType']>, ParentType, ContextType, Partial<ShiftPositionTypeRequiredAccreditationsArgs>>;
  requiredSkillsets?: Resolver<Array<ResolversTypes['SkillsetType']>, ParentType, ContextType, Partial<ShiftPositionTypeRequiredSkillsetsArgs>>;
  scheduleStatus?: Resolver<ResolversTypes['EventScheduleStatusType'], ParentType, ContextType>;
  shift?: Resolver<ResolversTypes['ShiftType'], ParentType, ContextType>;
  skillsetType?: Resolver<ResolversTypes['ShiftSkillsetType'], ParentType, ContextType>;
  totalAcceptedPositions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftPositionTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftPositionTypeConnection'] = ResolversParentTypes['ShiftPositionTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ShiftPositionTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftPositionTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftPositionTypeEdge'] = ResolversParentTypes['ShiftPositionTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ShiftPositionType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftType'] = ResolversParentTypes['ShiftType']> = {
  assignments?: Resolver<Array<ResolversTypes['ShiftAssignmentType']>, ParentType, ContextType, Partial<ShiftTypeAssignmentsArgs>>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  dutyManager?: Resolver<Maybe<ResolversTypes['ShiftAssignmentType']>, ParentType, ContextType>;
  endTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  eoiStatus?: Resolver<Maybe<ResolversTypes['AssignmentStatus']>, ParentType, ContextType>;
  event?: Resolver<ResolversTypes['EventType'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  interestStatus?: Resolver<ResolversTypes['EventInterestStatusType'], ParentType, ContextType>;
  scheduleStatus?: Resolver<ResolversTypes['EventScheduleStatusType'], ParentType, ContextType>;
  shiftPositions?: Resolver<Array<ResolversTypes['ShiftPositionType']>, ParentType, ContextType, Partial<ShiftTypeShiftPositionsArgs>>;
  startTime?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  timeSheet?: Resolver<Maybe<ResolversTypes['TimeSheetType']>, ParentType, ContextType>;
  totalAcceptedPositions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalPositions?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftTypeConnection'] = ResolversParentTypes['ShiftTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ShiftTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftTypeEdge'] = ResolversParentTypes['ShiftTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['ShiftType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkillsetTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SkillsetType'] = ResolversParentTypes['SkillsetType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  order?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  qualificationRequirements?: Resolver<Array<ResolversTypes['QualificationRequirementType']>, ParentType, ContextType, Partial<SkillsetTypeQualificationRequirementsArgs>>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkillsetTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['SkillsetTypeConnection'] = ResolversParentTypes['SkillsetTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['SkillsetTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SkillsetTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SkillsetTypeEdge'] = ResolversParentTypes['SkillsetTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['SkillsetType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SlackChannelTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SlackChannelType'] = ResolversParentTypes['SlackChannelType']> = {
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type StateTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['StateType'] = ResolversParentTypes['StateType']> = {
  code?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['CountryType']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = {
  caseUpdated?: SubscriptionResolver<ResolversTypes['CaseType'], "caseUpdated", ParentType, ContextType, RequireFields<SubscriptionCaseUpdatedArgs, 'caseUuid'>>;
  notificationCreated?: SubscriptionResolver<ResolversTypes['NotificationCreatedType'], "notificationCreated", ParentType, ContextType>;
};

export type TenantTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TenantType'] = ResolversParentTypes['TenantType']> = {
  abn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['AddressType']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  emailEmp?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emailEoiAssigned?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emailEoiUnsuccessful?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emailEventConfirmation?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emailEventPostReport?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emailSemp?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  empCoverImage?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  empEquipment?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  empInsurance?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  empTerms?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  logoDark?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  logoDefault?: Resolver<Maybe<ResolversTypes['DjangoFileType']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postReportTemplate?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  slackChannel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slackIsConnected?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextTemplateTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextTemplateType'] = ResolversParentTypes['TextTemplateType']> = {
  content?: Resolver<ResolversTypes['JSON'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['TextTemplateKind'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextTemplateTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextTemplateTypeConnection'] = ResolversParentTypes['TextTemplateTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['TextTemplateTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TextTemplateTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TextTemplateTypeEdge'] = ResolversParentTypes['TextTemplateTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TextTemplateType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetType'] = ResolversParentTypes['TimeSheetType']> = {
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateTimeEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateTimeStart?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shift?: Resolver<ResolversTypes['ShiftType'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['TimeSheetStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetTypeConnection'] = ResolversParentTypes['TimeSheetTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['TimeSheetTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TimeSheetTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TimeSheetTypeEdge'] = ResolversParentTypes['TimeSheetTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['TimeSheetType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenPayloadTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenPayloadType'] = ResolversParentTypes['TokenPayloadType']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exp?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  is2faAuthenticated?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  origIat?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  verificationSid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TokenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TokenType'] = ResolversParentTypes['TokenType']> = {
  payload?: Resolver<ResolversTypes['TokenPayloadType'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TopCaseCategoryTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopCaseCategoryType'] = ResolversParentTypes['TopCaseCategoryType']> = {
  category?: Resolver<ResolversTypes['CaseCategoryType'], ParentType, ContextType>;
  numCases?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TwoFactorProvisionResolvers<ContextType = any, ParentType extends ResolversParentTypes['TwoFactorProvision'] = ResolversParentTypes['TwoFactorProvision']> = {
  qrCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  secret?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  uri?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export type UpdateAccountPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateAccountPayload'] = ResolversParentTypes['UpdateAccountPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'UserType', ParentType, ContextType>;
};

export type UpdateAccreditationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateAccreditationPayload'] = ResolversParentTypes['UpdateAccreditationPayload']> = {
  __resolveType: TypeResolveFn<'AccreditationType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateAdviceTemplatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateAdviceTemplatePayload'] = ResolversParentTypes['UpdateAdviceTemplatePayload']> = {
  __resolveType: TypeResolveFn<'AdviceTemplateType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateCaseCategoryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCaseCategoryPayload'] = ResolversParentTypes['UpdateCaseCategoryPayload']> = {
  __resolveType: TypeResolveFn<'CaseCategoryType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateCaseCategorySetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCaseCategorySetPayload'] = ResolversParentTypes['UpdateCaseCategorySetPayload']> = {
  __resolveType: TypeResolveFn<'CaseCategorySetType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateCasePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateCasePayload'] = ResolversParentTypes['UpdateCasePayload']> = {
  __resolveType: TypeResolveFn<'CaseType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateClientPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateClientPayload'] = ResolversParentTypes['UpdateClientPayload']> = {
  __resolveType: TypeResolveFn<'ClientType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateConsumablePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateConsumablePayload'] = ResolversParentTypes['UpdateConsumablePayload']> = {
  __resolveType: TypeResolveFn<'ConsumableType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateDrugPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateDrugPayload'] = ResolversParentTypes['UpdateDrugPayload']> = {
  __resolveType: TypeResolveFn<'DrugType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateEventKindPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateEventKindPayload'] = ResolversParentTypes['UpdateEventKindPayload']> = {
  __resolveType: TypeResolveFn<'EventKindType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateEventPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateEventPayload'] = ResolversParentTypes['UpdateEventPayload']> = {
  __resolveType: TypeResolveFn<'EventType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateHospitalPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateHospitalPayload'] = ResolversParentTypes['UpdateHospitalPayload']> = {
  __resolveType: TypeResolveFn<'HospitalType' | 'OperationInfo', ParentType, ContextType>;
};

export type UpdateMyTimeSheetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateMyTimeSheetPayload'] = ResolversParentTypes['UpdateMyTimeSheetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TimeSheetType', ParentType, ContextType>;
};

export type UpdateQualificationDocumentPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateQualificationDocumentPayload'] = ResolversParentTypes['UpdateQualificationDocumentPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationDocumentType', ParentType, ContextType>;
};

export type UpdateQualificationPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateQualificationPayload'] = ResolversParentTypes['UpdateQualificationPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'QualificationType', ParentType, ContextType>;
};

export type UpdateRegionPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateRegionPayload'] = ResolversParentTypes['UpdateRegionPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'RegionType', ParentType, ContextType>;
};

export type UpdateResourceCategoryPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateResourceCategoryPayload'] = ResolversParentTypes['UpdateResourceCategoryPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ResourceCategoryType', ParentType, ContextType>;
};

export type UpdateResourcePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateResourcePayload'] = ResolversParentTypes['UpdateResourcePayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ResourceType', ParentType, ContextType>;
};

export type UpdateSkillsetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateSkillsetPayload'] = ResolversParentTypes['UpdateSkillsetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'SkillsetType', ParentType, ContextType>;
};

export type UpdateTenantPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTenantPayload'] = ResolversParentTypes['UpdateTenantPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TenantType', ParentType, ContextType>;
};

export type UpdateTextTemplatePayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTextTemplatePayload'] = ResolversParentTypes['UpdateTextTemplatePayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TextTemplateType', ParentType, ContextType>;
};

export type UpdateTimeSheetPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateTimeSheetPayload'] = ResolversParentTypes['UpdateTimeSheetPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'TimeSheetType', ParentType, ContextType>;
};

export type UpdateUserPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateUserPayload'] = ResolversParentTypes['UpdateUserPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'UserType', ParentType, ContextType>;
};

export type UpdateWithdrawEoiRequestPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['UpdateWithdrawEoiRequestPayload'] = ResolversParentTypes['UpdateWithdrawEoiRequestPayload']> = {
  __resolveType: TypeResolveFn<'OperationInfo' | 'ShiftAssignmentWithdrawRequestType', ParentType, ContextType>;
};

export interface UploadScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Upload'], any> {
  name: 'Upload';
}

export type UserStatusTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserStatusType'] = ResolversParentTypes['UserStatusType']> = {
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserType'] = ResolversParentTypes['UserType']> = {
  accreditations?: Resolver<Array<ResolversTypes['AccreditationType']>, ParentType, ContextType, Partial<UserTypeAccreditationsArgs>>;
  address?: Resolver<Maybe<ResolversTypes['AddressType']>, ParentType, ContextType>;
  archived?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  bypassQualificationRequirements?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  canRegisterInterest?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  dateJoined?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasUsablePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['GlobalID'], ParentType, ContextType>;
  isActive?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isOnline?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isStaff?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSuperuser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastLogin?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logentrySet?: Resolver<Array<ResolversTypes['Node']>, ParentType, ContextType>;
  permissions?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  qualificationDocuments?: Resolver<Array<ResolversTypes['QualificationDocumentType']>, ParentType, ContextType, Partial<UserTypeQualificationDocumentsArgs>>;
  region?: Resolver<Maybe<ResolversTypes['RegionType']>, ParentType, ContextType>;
  shiftAssignments?: Resolver<Array<ResolversTypes['ShiftAssignmentType']>, ParentType, ContextType, Partial<UserTypeShiftAssignmentsArgs>>;
  shiftsL1m?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shiftsL3m?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  shiftsL6m?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  skillset?: Resolver<Maybe<ResolversTypes['SkillsetType']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatusType'], ParentType, ContextType>;
  twoFactorEnabled?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  verified?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTypeConnection'] = ResolversParentTypes['UserTypeConnection']> = {
  edges?: Resolver<Array<ResolversTypes['UserTypeEdge']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTypeEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTypeEdge'] = ResolversParentTypes['UserTypeEdge']> = {
  cursor?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['UserType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VerifyTokenTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['VerifyTokenType'] = ResolversParentTypes['VerifyTokenType']> = {
  errors?: Resolver<Maybe<ResolversTypes['ExpectedError']>, ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['TokenType']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['UserType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  AccreditationType?: AccreditationTypeResolvers<ContextType>;
  AccreditationTypeConnection?: AccreditationTypeConnectionResolvers<ContextType>;
  AccreditationTypeEdge?: AccreditationTypeEdgeResolvers<ContextType>;
  AddressType?: AddressTypeResolvers<ContextType>;
  AdviceTemplateType?: AdviceTemplateTypeResolvers<ContextType>;
  AdviceTemplateTypeConnection?: AdviceTemplateTypeConnectionResolvers<ContextType>;
  AdviceTemplateTypeEdge?: AdviceTemplateTypeEdgeResolvers<ContextType>;
  AppPermissions?: AppPermissionsResolvers<ContextType>;
  BaseInterface?: BaseInterfaceResolvers<ContextType>;
  BaseReorderableInterface?: BaseReorderableInterfaceResolvers<ContextType>;
  CaseAttachmentType?: CaseAttachmentTypeResolvers<ContextType>;
  CaseAttendantType?: CaseAttendantTypeResolvers<ContextType>;
  CaseAuditType?: CaseAuditTypeResolvers<ContextType>;
  CaseAxType?: CaseAxTypeResolvers<ContextType>;
  CaseCategorySetType?: CaseCategorySetTypeResolvers<ContextType>;
  CaseCategorySetTypeConnection?: CaseCategorySetTypeConnectionResolvers<ContextType>;
  CaseCategorySetTypeEdge?: CaseCategorySetTypeEdgeResolvers<ContextType>;
  CaseCategoryType?: CaseCategoryTypeResolvers<ContextType>;
  CaseCategoryTypeConnection?: CaseCategoryTypeConnectionResolvers<ContextType>;
  CaseCategoryTypeEdge?: CaseCategoryTypeEdgeResolvers<ContextType>;
  CaseConsumableType?: CaseConsumableTypeResolvers<ContextType>;
  CaseCustomAxType?: CaseCustomAxTypeResolvers<ContextType>;
  CaseECGAxType?: CaseEcgAxTypeResolvers<ContextType>;
  CaseGCSAxType?: CaseGcsAxTypeResolvers<ContextType>;
  CaseMentalAxType?: CaseMentalAxTypeResolvers<ContextType>;
  CaseNeurovascularAxType?: CaseNeurovascularAxTypeResolvers<ContextType>;
  CasePainAxType?: CasePainAxTypeResolvers<ContextType>;
  CasePatientPMHxAllergyType?: CasePatientPmHxAllergyTypeResolvers<ContextType>;
  CasePatientPMHxHistoryType?: CasePatientPmHxHistoryTypeResolvers<ContextType>;
  CasePatientPMHxMedicationType?: CasePatientPmHxMedicationTypeResolvers<ContextType>;
  CasePrimaryAxType?: CasePrimaryAxTypeResolvers<ContextType>;
  CaseROSIERAxType?: CaseRosierAxTypeResolvers<ContextType>;
  CaseRespiratoryAxType?: CaseRespiratoryAxTypeResolvers<ContextType>;
  CaseRxAirwayType?: CaseRxAirwayTypeResolvers<ContextType>;
  CaseRxBreathingVentilationType?: CaseRxBreathingVentilationTypeResolvers<ContextType>;
  CaseRxCPRType?: CaseRxCprTypeResolvers<ContextType>;
  CaseRxConsultType?: CaseRxConsultTypeResolvers<ContextType>;
  CaseRxDefibrillationType?: CaseRxDefibrillationTypeResolvers<ContextType>;
  CaseRxFirstAidType?: CaseRxFirstAidTypeResolvers<ContextType>;
  CaseRxIVAccessType?: CaseRxIvAccessTypeResolvers<ContextType>;
  CaseRxMajorTraumaType?: CaseRxMajorTraumaTypeResolvers<ContextType>;
  CaseRxMedicationsType?: CaseRxMedicationsTypeResolvers<ContextType>;
  CaseRxOtherType?: CaseRxOtherTypeResolvers<ContextType>;
  CaseRxOxygenType?: CaseRxOxygenTypeResolvers<ContextType>;
  CaseSketchType?: CaseSketchTypeResolvers<ContextType>;
  CaseSpinalAxType?: CaseSpinalAxTypeResolvers<ContextType>;
  CaseTreatmentType?: CaseTreatmentTypeResolvers<ContextType>;
  CaseType?: CaseTypeResolvers<ContextType>;
  CaseTypeConnection?: CaseTypeConnectionResolvers<ContextType>;
  CaseTypeEdge?: CaseTypeEdgeResolvers<ContextType>;
  CaseVitalsAxType?: CaseVitalsAxTypeResolvers<ContextType>;
  ClientType?: ClientTypeResolvers<ContextType>;
  ClientTypeConnection?: ClientTypeConnectionResolvers<ContextType>;
  ClientTypeEdge?: ClientTypeEdgeResolvers<ContextType>;
  ConsumableType?: ConsumableTypeResolvers<ContextType>;
  ConsumableTypeConnection?: ConsumableTypeConnectionResolvers<ContextType>;
  ConsumableTypeEdge?: ConsumableTypeEdgeResolvers<ContextType>;
  ContactType?: ContactTypeResolvers<ContextType>;
  CountryType?: CountryTypeResolvers<ContextType>;
  CreateAccreditationPayload?: CreateAccreditationPayloadResolvers<ContextType>;
  CreateAdviceTemplatePayload?: CreateAdviceTemplatePayloadResolvers<ContextType>;
  CreateCaseAuditPayload?: CreateCaseAuditPayloadResolvers<ContextType>;
  CreateCaseCategoryPayload?: CreateCaseCategoryPayloadResolvers<ContextType>;
  CreateCaseCategorySetPayload?: CreateCaseCategorySetPayloadResolvers<ContextType>;
  CreateCasePayload?: CreateCasePayloadResolvers<ContextType>;
  CreateClientPayload?: CreateClientPayloadResolvers<ContextType>;
  CreateConsumablePayload?: CreateConsumablePayloadResolvers<ContextType>;
  CreateDeleteQualificationAuditPayload?: CreateDeleteQualificationAuditPayloadResolvers<ContextType>;
  CreateDrugPayload?: CreateDrugPayloadResolvers<ContextType>;
  CreateEventKindPayload?: CreateEventKindPayloadResolvers<ContextType>;
  CreateEventPayload?: CreateEventPayloadResolvers<ContextType>;
  CreateFileNodePayload?: CreateFileNodePayloadResolvers<ContextType>;
  CreateHospitalPayload?: CreateHospitalPayloadResolvers<ContextType>;
  CreateMyTimeSheetPayload?: CreateMyTimeSheetPayloadResolvers<ContextType>;
  CreateQualificationDocumentPayload?: CreateQualificationDocumentPayloadResolvers<ContextType>;
  CreateQualificationPayload?: CreateQualificationPayloadResolvers<ContextType>;
  CreateRegionPayload?: CreateRegionPayloadResolvers<ContextType>;
  CreateResourceCategoryPayload?: CreateResourceCategoryPayloadResolvers<ContextType>;
  CreateResourcePayload?: CreateResourcePayloadResolvers<ContextType>;
  CreateSkillsetPayload?: CreateSkillsetPayloadResolvers<ContextType>;
  CreateTextTemplatePayload?: CreateTextTemplatePayloadResolvers<ContextType>;
  CreateTimeSheetPayload?: CreateTimeSheetPayloadResolvers<ContextType>;
  CreateUserPayload?: CreateUserPayloadResolvers<ContextType>;
  CreateWithdrawEoiRequestPayload?: CreateWithdrawEoiRequestPayloadResolvers<ContextType>;
  DatasetsType?: DatasetsTypeResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Decimal?: GraphQLScalarType;
  DeleteAccreditationPayload?: DeleteAccreditationPayloadResolvers<ContextType>;
  DeleteAdviceTemplatePayload?: DeleteAdviceTemplatePayloadResolvers<ContextType>;
  DeleteCaseCategoryPayload?: DeleteCaseCategoryPayloadResolvers<ContextType>;
  DeleteCaseCategorySetPayload?: DeleteCaseCategorySetPayloadResolvers<ContextType>;
  DeleteCasePayload?: DeleteCasePayloadResolvers<ContextType>;
  DeleteClientPayload?: DeleteClientPayloadResolvers<ContextType>;
  DeleteConsumablePayload?: DeleteConsumablePayloadResolvers<ContextType>;
  DeleteDrugPayload?: DeleteDrugPayloadResolvers<ContextType>;
  DeleteEventKindPayload?: DeleteEventKindPayloadResolvers<ContextType>;
  DeleteEventPayload?: DeleteEventPayloadResolvers<ContextType>;
  DeleteFileNodePayload?: DeleteFileNodePayloadResolvers<ContextType>;
  DeleteHospitalPayload?: DeleteHospitalPayloadResolvers<ContextType>;
  DeleteQualificationDocumentPayload?: DeleteQualificationDocumentPayloadResolvers<ContextType>;
  DeleteQualificationPayload?: DeleteQualificationPayloadResolvers<ContextType>;
  DeleteRegionPayload?: DeleteRegionPayloadResolvers<ContextType>;
  DeleteResourceCategoryPayload?: DeleteResourceCategoryPayloadResolvers<ContextType>;
  DeleteResourcePayload?: DeleteResourcePayloadResolvers<ContextType>;
  DeleteSkillsetPayload?: DeleteSkillsetPayloadResolvers<ContextType>;
  DeleteTextTemplatePayload?: DeleteTextTemplatePayloadResolvers<ContextType>;
  DeleteTimeSheetPayload?: DeleteTimeSheetPayloadResolvers<ContextType>;
  DeleteUserPayload?: DeleteUserPayloadResolvers<ContextType>;
  DjangoFileType?: DjangoFileTypeResolvers<ContextType>;
  DrugBrandNameType?: DrugBrandNameTypeResolvers<ContextType>;
  DrugContraindicationType?: DrugContraindicationTypeResolvers<ContextType>;
  DrugIndicationType?: DrugIndicationTypeResolvers<ContextType>;
  DrugPrecautionType?: DrugPrecautionTypeResolvers<ContextType>;
  DrugSideEffectType?: DrugSideEffectTypeResolvers<ContextType>;
  DrugType?: DrugTypeResolvers<ContextType>;
  DrugTypeConnection?: DrugTypeConnectionResolvers<ContextType>;
  DrugTypeEdge?: DrugTypeEdgeResolvers<ContextType>;
  EventConsumableType?: EventConsumableTypeResolvers<ContextType>;
  EventConsumablesType?: EventConsumablesTypeResolvers<ContextType>;
  EventInterestStatusType?: EventInterestStatusTypeResolvers<ContextType>;
  EventKindType?: EventKindTypeResolvers<ContextType>;
  EventKindTypeConnection?: EventKindTypeConnectionResolvers<ContextType>;
  EventKindTypeEdge?: EventKindTypeEdgeResolvers<ContextType>;
  EventScheduleStatusType?: EventScheduleStatusTypeResolvers<ContextType>;
  EventType?: EventTypeResolvers<ContextType>;
  EventTypeConnection?: EventTypeConnectionResolvers<ContextType>;
  EventTypeEdge?: EventTypeEdgeResolvers<ContextType>;
  ExpectedError?: GraphQLScalarType;
  FileNodeType?: FileNodeTypeResolvers<ContextType>;
  FileNodeTypeConnection?: FileNodeTypeConnectionResolvers<ContextType>;
  FileNodeTypeEdge?: FileNodeTypeEdgeResolvers<ContextType>;
  GlobalID?: GraphQLScalarType;
  GraphDataType?: GraphDataTypeResolvers<ContextType>;
  HospitalType?: HospitalTypeResolvers<ContextType>;
  HospitalTypeConnection?: HospitalTypeConnectionResolvers<ContextType>;
  HospitalTypeEdge?: HospitalTypeEdgeResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  LocalityType?: LocalityTypeResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  MutationNormalOutput?: MutationNormalOutputResolvers<ContextType>;
  Node?: NodeResolvers<ContextType>;
  NotificationCreatedType?: NotificationCreatedTypeResolvers<ContextType>;
  NotificationResponseType?: NotificationResponseTypeResolvers<ContextType>;
  NotificationType?: NotificationTypeResolvers<ContextType>;
  NotificationTypeConnection?: NotificationTypeConnectionResolvers<ContextType>;
  NotificationTypeEdge?: NotificationTypeEdgeResolvers<ContextType>;
  ObtainJSONWebTokenType?: ObtainJsonWebTokenTypeResolvers<ContextType>;
  OperationInfo?: OperationInfoResolvers<ContextType>;
  OperationMessage?: OperationMessageResolvers<ContextType>;
  OutputInterface?: OutputInterfaceResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  PatientType?: PatientTypeResolvers<ContextType>;
  Permission?: PermissionResolvers<ContextType>;
  PermissionGroup?: PermissionGroupResolvers<ContextType>;
  QualificationDocumentAuditType?: QualificationDocumentAuditTypeResolvers<ContextType>;
  QualificationDocumentType?: QualificationDocumentTypeResolvers<ContextType>;
  QualificationDocumentTypeConnection?: QualificationDocumentTypeConnectionResolvers<ContextType>;
  QualificationDocumentTypeEdge?: QualificationDocumentTypeEdgeResolvers<ContextType>;
  QualificationRequirementType?: QualificationRequirementTypeResolvers<ContextType>;
  QualificationRequirementTypeConnection?: QualificationRequirementTypeConnectionResolvers<ContextType>;
  QualificationRequirementTypeEdge?: QualificationRequirementTypeEdgeResolvers<ContextType>;
  QualificationType?: QualificationTypeResolvers<ContextType>;
  QualificationTypeConnection?: QualificationTypeConnectionResolvers<ContextType>;
  QualificationTypeEdge?: QualificationTypeEdgeResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  RefreshTokenType?: RefreshTokenTypeResolvers<ContextType>;
  RegionType?: RegionTypeResolvers<ContextType>;
  RegionTypeConnection?: RegionTypeConnectionResolvers<ContextType>;
  RegionTypeEdge?: RegionTypeEdgeResolvers<ContextType>;
  ResourceAllocationType?: ResourceAllocationTypeResolvers<ContextType>;
  ResourceCategoryType?: ResourceCategoryTypeResolvers<ContextType>;
  ResourceCategoryTypeConnection?: ResourceCategoryTypeConnectionResolvers<ContextType>;
  ResourceCategoryTypeEdge?: ResourceCategoryTypeEdgeResolvers<ContextType>;
  ResourceType?: ResourceTypeResolvers<ContextType>;
  ResourceTypeConnection?: ResourceTypeConnectionResolvers<ContextType>;
  ResourceTypeEdge?: ResourceTypeEdgeResolvers<ContextType>;
  RevokeRefreshTokenType?: RevokeRefreshTokenTypeResolvers<ContextType>;
  ShiftAssignmentType?: ShiftAssignmentTypeResolvers<ContextType>;
  ShiftAssignmentTypeConnection?: ShiftAssignmentTypeConnectionResolvers<ContextType>;
  ShiftAssignmentTypeEdge?: ShiftAssignmentTypeEdgeResolvers<ContextType>;
  ShiftAssignmentWithdrawRequestType?: ShiftAssignmentWithdrawRequestTypeResolvers<ContextType>;
  ShiftEOIResponseType?: ShiftEoiResponseTypeResolvers<ContextType>;
  ShiftPositionType?: ShiftPositionTypeResolvers<ContextType>;
  ShiftPositionTypeConnection?: ShiftPositionTypeConnectionResolvers<ContextType>;
  ShiftPositionTypeEdge?: ShiftPositionTypeEdgeResolvers<ContextType>;
  ShiftType?: ShiftTypeResolvers<ContextType>;
  ShiftTypeConnection?: ShiftTypeConnectionResolvers<ContextType>;
  ShiftTypeEdge?: ShiftTypeEdgeResolvers<ContextType>;
  SkillsetType?: SkillsetTypeResolvers<ContextType>;
  SkillsetTypeConnection?: SkillsetTypeConnectionResolvers<ContextType>;
  SkillsetTypeEdge?: SkillsetTypeEdgeResolvers<ContextType>;
  SlackChannelType?: SlackChannelTypeResolvers<ContextType>;
  StateType?: StateTypeResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  TenantType?: TenantTypeResolvers<ContextType>;
  TextTemplateType?: TextTemplateTypeResolvers<ContextType>;
  TextTemplateTypeConnection?: TextTemplateTypeConnectionResolvers<ContextType>;
  TextTemplateTypeEdge?: TextTemplateTypeEdgeResolvers<ContextType>;
  TimeSheetType?: TimeSheetTypeResolvers<ContextType>;
  TimeSheetTypeConnection?: TimeSheetTypeConnectionResolvers<ContextType>;
  TimeSheetTypeEdge?: TimeSheetTypeEdgeResolvers<ContextType>;
  TokenPayloadType?: TokenPayloadTypeResolvers<ContextType>;
  TokenType?: TokenTypeResolvers<ContextType>;
  TopCaseCategoryType?: TopCaseCategoryTypeResolvers<ContextType>;
  TwoFactorProvision?: TwoFactorProvisionResolvers<ContextType>;
  UUID?: GraphQLScalarType;
  UpdateAccountPayload?: UpdateAccountPayloadResolvers<ContextType>;
  UpdateAccreditationPayload?: UpdateAccreditationPayloadResolvers<ContextType>;
  UpdateAdviceTemplatePayload?: UpdateAdviceTemplatePayloadResolvers<ContextType>;
  UpdateCaseCategoryPayload?: UpdateCaseCategoryPayloadResolvers<ContextType>;
  UpdateCaseCategorySetPayload?: UpdateCaseCategorySetPayloadResolvers<ContextType>;
  UpdateCasePayload?: UpdateCasePayloadResolvers<ContextType>;
  UpdateClientPayload?: UpdateClientPayloadResolvers<ContextType>;
  UpdateConsumablePayload?: UpdateConsumablePayloadResolvers<ContextType>;
  UpdateDrugPayload?: UpdateDrugPayloadResolvers<ContextType>;
  UpdateEventKindPayload?: UpdateEventKindPayloadResolvers<ContextType>;
  UpdateEventPayload?: UpdateEventPayloadResolvers<ContextType>;
  UpdateHospitalPayload?: UpdateHospitalPayloadResolvers<ContextType>;
  UpdateMyTimeSheetPayload?: UpdateMyTimeSheetPayloadResolvers<ContextType>;
  UpdateQualificationDocumentPayload?: UpdateQualificationDocumentPayloadResolvers<ContextType>;
  UpdateQualificationPayload?: UpdateQualificationPayloadResolvers<ContextType>;
  UpdateRegionPayload?: UpdateRegionPayloadResolvers<ContextType>;
  UpdateResourceCategoryPayload?: UpdateResourceCategoryPayloadResolvers<ContextType>;
  UpdateResourcePayload?: UpdateResourcePayloadResolvers<ContextType>;
  UpdateSkillsetPayload?: UpdateSkillsetPayloadResolvers<ContextType>;
  UpdateTenantPayload?: UpdateTenantPayloadResolvers<ContextType>;
  UpdateTextTemplatePayload?: UpdateTextTemplatePayloadResolvers<ContextType>;
  UpdateTimeSheetPayload?: UpdateTimeSheetPayloadResolvers<ContextType>;
  UpdateUserPayload?: UpdateUserPayloadResolvers<ContextType>;
  UpdateWithdrawEoiRequestPayload?: UpdateWithdrawEoiRequestPayloadResolvers<ContextType>;
  Upload?: GraphQLScalarType;
  UserStatusType?: UserStatusTypeResolvers<ContextType>;
  UserType?: UserTypeResolvers<ContextType>;
  UserTypeConnection?: UserTypeConnectionResolvers<ContextType>;
  UserTypeEdge?: UserTypeEdgeResolvers<ContextType>;
  VerifyTokenType?: VerifyTokenTypeResolvers<ContextType>;
};

